// Custom helpers imports
import { toast } from "react-toastify";

interface Props {
  linkPay: string;
}

function CopyLinkPayment({ linkPay }: Props) {
  return (
    <div className="border-b text-gray-500 w-full px-4 py-3 flex flex-col">
      <span>Copia y comparte el enlace de pago</span>
      <div className="w-full mt-2 flex text-sm">
        <input
          disabled
          type="text"
          className="text-sm input-base bg-gray-100 rounded-r-none border-r-0"
          value={linkPay}
        />
        <button
          className="text-2xl button-primary rounded-lg rounded-l-none py-0 h-auto"
          type="button"
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(linkPay);
              toast.success("Enlace copiado");
            } catch (error) {
              toast.error("Algo salio mal al intentar copiar enlace");
            }
          }}
        >
          <i className="bi bi-link-45deg"></i>
        </button>
      </div>
    </div>
  );
}
export default CopyLinkPayment;
