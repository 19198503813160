// Custom hooks imports
import { useAppSelector } from "../app/hook";

// Custom types imports
import { Ticket } from "../features/payments/types";
import { PaymentReceipt } from "../features/payments/types";

// Custom queries imports
import { useCancelPaymentMutation } from "../features/payments/useCancelPaymentMutation";

// Custom helpers imports
import { addHours } from "../features/invoices/helpers";
import { toast } from "react-toastify";
import { isCorporateAdmin } from "../features/auth/helpers";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

interface Props {
  ticket: Ticket;
  payment: PaymentReceipt;
}

function PaymentCancellation({ ticket, payment }: Props) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const cancellationDeadline = addHours(payment.date, 2);
  const validDate = new Date(cancellationDeadline).toLocaleDateString("es-CO", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const canCancel = () => {
    let dateNow = new Date().getTime();
    let dateInvoice = payment.date;
    return addHours(dateInvoice, 2) > dateNow;
  };

  const cancelInvoiceByIdMutation = useCancelPaymentMutation(() => {
    if (currentUser.user) {
      navigate(`/${currentUser.user.id}/transacciones/t/${ticket.invoiceID}`);
    }
  });

  if (
    currentUser.user?.roles &&
    (!isCorporateAdmin(currentUser.user.roles) ||
      !isCorporateAdmin(currentUser.user.roles))
  )
    return null;

  if (ticket.invoiceStatus !== 2) return null;

  if (!!canCancel()) {
    return (
      <div className="w-full text-sm flex flex-wrap sm:justify-center items-center bg-red-primary text-white py-2 px-4">
        <i className="text-lg bi bi-info-circle-fill mr-4"></i>
        <span className="w-[calc(100%-134px)]">
          La anulación del pago estará habilitada hasta el <b>{validDate}</b>
        </span>
        <button
          tabIndex={-1}
          className="mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
          onClick={() => {
            let notificationID = toast.loading("Anulando pago...");
            cancelInvoiceByIdMutation.mutate({
              id: ticket.id,
              notificationID,
            });
          }}
        >
          Anular
        </button>
      </div>
    );
  }

  if (!canCancel()) {
    return (
      <div className="w-full text-sm flex flex-wrap sm:justify-center items-center bg-blue-500 text-white py-2 px-4">
        <i className="text-lg bi bi-info-circle-fill mr-4"></i>
        <span className="w-[calc(100%-34px)]">
          La fecha limite para anular el pago era hasta el <b>{validDate}</b>
        </span>
      </div>
    );
  }

  return null;
}
export default PaymentCancellation;
