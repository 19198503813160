interface Props {
  message: string;
}

function EndMessageInfiniteList({message}: Props) {
  return (
    <div className="bg-blue-500 font-bold  text-white py-2 flex items-center justify-center gap-x-4">
      {message}
    </div>
  );
}

export default EndMessageInfiniteList;
