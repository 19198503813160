// React imports
import { useEffect } from "react";

// Custom queries imports
import { useCardTypes } from "../../../../features/card_types/useCardTypes";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import CustomEmailField from "../../../shared/fields/CustomEmailField";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import {
  FormDataCreateInvoice,
  defaultValues,
} from "../../../../features/invoices/types";

// Custom queries imports
import { usePersonByIdCardMutation } from "../../../../features/persons/usePersonByIdCardMutation";

interface Props {
  existingPerson: boolean;
  setExistingPerson: React.Dispatch<React.SetStateAction<boolean>>;
}

function DataPersonForm({ existingPerson, setExistingPerson }: Props) {
  const cardTypes = useCardTypes();
  const methods = useFormContext<FormDataCreateInvoice>();

  const person = methods.watch("person");

  const personByIdCardMutation = usePersonByIdCardMutation(
    (data: any, _variables) => {
      methods.setValue("person", data);
      setExistingPerson(true);
    },
    (_error, _variables) => {
      if (existingPerson === true) {
        methods.setValue("person", {
          ...defaultValues.person,
          idCard: methods.watch("person.idCard"),
          idCardType: methods.watch("person.idCardType"),
          idCardValue: methods.watch("person.idCardValue"),
        });
        setExistingPerson(false);
      }
    }
  );

  useEffect(() => {
    if (methods.formState.isDirty) {
      const idCard = `00${person.idCardType}${person.idCardValue}`;
      methods.setValue("person.idCard", idCard);
      methods.setValue("invoice.idCard", idCard);

      personByIdCardMutation.mutate({
        idCard,
        notificationID: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.idCardType, person.idCardValue]);

  useEffect(() => {
    methods.setValue("person.name", `${person.firstName} ${person.lastName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.firstName, person.lastName]);

  useEffect(() => {
    methods.setValue(
      "person.phoneNumber",
      `${person.phoneCountry}${person.phoneNumberValue}`
    );
    methods.setValue("person.phoneCountry", 170);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.phoneNumberValue, person.phoneCountry]);

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
      <CustomLabel
        label="Tipo documento"
        id="tipoDocumento"
        required={true}
        className="text-sm sm:col-span-2"
        name="person.idCardType"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.person?.idCardType ? "input-error" : ""
            } ${
              !methods.formState.errors.person?.idCardType &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("person.idCardType", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            {cardTypes.data?.map((cardType) => (
              <option value={cardType} key={cardType}>
                {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                {CARD_TYPES_INFO[cardType]?.description}
              </option>
            ))}
          </select>
          <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="No. De documento"
        id="nroDocumento"
        required={true}
        className="text-sm sm:col-span-2"
        name="person.idCardValue"
      >
        <input
          type="number"
          maxLength={12}
          min={0}
          placeholder="No. De documento"
          className={`input-base ${
            methods.formState.errors.person?.idCardValue ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.idCardValue &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.idCardValue", {
            required: "Este campo es requerido",
            maxLength: {
              value: 12,
              message: "El número máximo de caracteres es 12",
            },
            minLength: {
              value: 6,
              message: "El número minimo de caracteres es 6",
            },
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombres"
        id="nombres"
        required={true}
        className="text-sm sm:col-span-2"
        name="person.firstName"
      >
        <input
          type="text"
          disabled={existingPerson}
          maxLength={20}
          placeholder="Nombres"
          className={`input-base ${
            methods.formState.errors.person?.firstName ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.firstName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.firstName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Apellidos"
        id="apellidos"
        required={true}
        className="text-sm sm:col-span-2"
        name="person.lastName"
      >
        <input
          type="text"
          disabled={existingPerson}
          maxLength={20}
          placeholder="Apellidos"
          className={`input-base ${
            methods.formState.errors.person?.lastName ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.lastName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.lastName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre completo"
        id="name"
        required={false}
        className="text-sm sm:col-span-4"
      >
        <input
          type="text"
          disabled
          placeholder="Nombre completo"
          className="input-base"
          value={`${methods.watch("person.firstName")} ${methods.watch(
            "person.lastName"
          )}`}
        />
      </CustomLabel>

      <CustomLabel
        label="Telefono"
        id="phoneNumberValue"
        required={false}
        className="text-sm sm:col-span-2"
        name="person.phoneNumberValue"
      >
        <input
          type="number"
          placeholder="Telefono"
          disabled={existingPerson}
          className={`input-base ${
            methods.formState.errors.person?.phoneNumberValue
              ? "input-error"
              : ""
          } ${
            !methods.formState.errors.person?.phoneNumberValue &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.phoneNumberValue", {
            maxLength: {
              value: 10,
              message: "El número máximo de caracteres es 10",
            },
            minLength: {
              value: 10,
              message: "El número minimo de caracteres es 10",
            },
          })}
        />
      </CustomLabel>

      <CustomEmailField
        placeholder="nombre@correo.com"
        id="person.email"
        required={true}
        label="Correo electrónico"
        name="person.email"
        notRegisted={existingPerson}
        classNameLabel="text-sm sm:col-span-2 !mb-0"
      />
    </div>
  );
}
export default DataPersonForm;
