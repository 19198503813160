// ** React query Imports
import { useInfiniteQuery } from "@tanstack/react-query";

// ** Config apisauce
import api from "../../app/api";

// ** Helpers imports
import { timeout } from "../../helpers";

// ** Types Imports
import { StoreModel, StoreList } from "./types";
import { STEP_LIMIT } from "./types";

type PageParamType = {
  page: number;
  size: number;
  currentFirstPosition: number;
};

const defaultPageParam = {
  page: 1,
  size: STEP_LIMIT,
  currentFirstPosition: 0,
};

export function useStoresInfinite(params: StoreModel) {
  async function getStores(
    pageParam: PageParamType,
    params: StoreModel
  ): Promise<StoreList> {
    await timeout(1000);
    const response = await api.post<StoreList>("admin/stores/paginated", {
      ...params,
      storesData: {
        ...params.storesData,
        currentFirstPosition: pageParam.currentFirstPosition,
        step: pageParam.size,
      },
    });

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useInfiniteQuery({
    queryKey: ["StoresInfinite", ...Object.values(params)],
    queryFn: ({ pageParam = defaultPageParam }) => getStores(pageParam, params),

    getNextPageParam: (lastPage, allPages) => {
      const totalItems = lastPage.storesData.totalItems;
      const totalPages = Math.ceil(totalItems / STEP_LIMIT);
      const penultimatePage = totalPages - 1;
      const items = allPages.flatMap((page) => page.stores) || [];
      const offset = totalItems % STEP_LIMIT || STEP_LIMIT;
      const isPenultimatePage = allPages.length === penultimatePage;
      if (items.length === totalItems) {
        return undefined;
      } else {
        return {
          page: allPages.length + 1,
          size: isPenultimatePage ? offset : STEP_LIMIT,
          currentFirstPosition: items.length + 1,
        };
      }
    },
  });
}
