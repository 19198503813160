// Components (react-router-dom) imports
import { NavLink } from "react-router-dom";

export type Tab = {
  label: string;
  url: string;
  desactive?: boolean;
};

interface Props {
  tabs: Tab[];
}

function Tabs({ tabs }: Props) {
  return (
    <div className="w-full mx-auto flex">
      {tabs.map((tab, index) => (
        <NavLink
          key={index}
          tabIndex={-1}
          to={tab.url}
          end
          className={({ isActive }) =>
            `${
              tab.desactive ? "opacity-50 pointer-events-none" : ""
            } w-full text-center py-2 border-b ${
              isActive
                ? "text-red-primary font-bold border-b-2 border-b-red-primary"
                : ""
            }`
          }
        >
          <p className="truncate">{tab.label}</p>
        </NavLink>
      ))}
    </div>
  );
}

export default Tabs;
