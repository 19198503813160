import { Store, TAX_REGIME_INFO } from "../../features/stores/types";
import { Link } from "react-router-dom";

interface Props {
  store: Store;
  link: string;
}

function StoreCard({ store, link }: Props) {
  return (
    <Link
      to={link}
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
    >
      <div className="col-span-1 flex flex-col">
        <b className="text-lg">{store.storeName}</b>
        <div className="mt-1 flex flex-col">
          <span className="text-sm">Nro. de identidad tributario</span>
          <b className="italic text-gray-500">
            {!!store.taxIDNumber
              ? new Intl.NumberFormat("es-CO").format(Number(store.taxIDNumber))
              : "No disponible"}
          </b>
        </div>
      </div>
      <div className="col-span-1 flex flex-col items-end">
        <div className="mt-1 flex flex-col">
          <b
            className={`text-lg ${
              store.status ? "text-green-500" : "text-red-500"
            }`}
          >
            {store.status ? "Activo" : "Inactivo"}
          </b>
        </div>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <span className="text-sm mr-auto">Regimen tributario</span>
        <span className="mr-auto italic text-red-500">
          {store.taxCondition
            ? TAX_REGIME_INFO[store.taxCondition].description
            : "No definido"}
        </span>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </Link>
  );
}

export default StoreCard;
