// Custom queries imports
import { useUpdateInvoiceMutation } from "../../../../features/invoices/useUpdateInvoiceMutation";

// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import EditInvoiceActions from "./EditInvoiceActions";
import SettlementTaxesAndCommissions from "../../../shared/SettlementTaxesAndCommissions";
import DataInvoiceForm from "./DataInvoiceForm";
import DataPersonDetails from "./DataPersonDetails";

// Custom types imports
import { FormDataEditInvoice } from "../../../../features/invoices/types";
import { Ticket } from "../../../../features/payments/types";

// Custom helpers imports
import { toast } from "react-toastify";
import {
  tikectToInvoice,
  invoiceToInvoiceRegistration,
} from "../../../../features/invoices/helpers";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Redux state
import { useAppSelector } from "../../../../app/hook";

interface Props {
  ticket: Ticket;
}

function EditInvoiceForm({ ticket }: Props) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);
  const invoice = tikectToInvoice(ticket);

  const methods = useForm<FormDataEditInvoice>({
    defaultValues: {
      invoice: invoice,
      person: ticket.customer,
    },
  });

  const updateInvoiceByIdMutation = useUpdateInvoiceMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Cobro actualizado`,
        autoClose: 5000,
      });
      navigate(`/${currentUser?.id}/cobros/c/${variables.invoice.invoiceID}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar actualizar el cobro",
        autoClose: 5000,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando cobro...");
    const invoiceRegistration = invoiceToInvoiceRegistration(data.invoice);
    updateInvoiceByIdMutation.mutate({
      invoice: invoiceRegistration,
      notificationID,
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ContentWithToggle
          title={`Cobro #${methods.watch("invoice.id")}`}
          actionButton={
             (
              <div className="mr-4">
                <button
                  className="button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                  onClick={() => {
                    navigate(
                      `/pagar/${ticket.idCard}/c/${ticket.invoiceID}/metodos?accessCode=${ticket.accessCode}`
                    );
                  }}
                >
                  Pagar
                </button>
              </div>
            )
          }
          onBackButton={() => navigate(`/${currentUser?.id}/cobros`)}
          isToogle={true}
        >
          <DataInvoiceForm ticket={ticket} />
        </ContentWithToggle>

        <ContentWithToggle
          title="Datos del cliente"
          isToogle={true}
          defaultOpenValue={false}
        >
          <DataPersonDetails />
        </ContentWithToggle>

        <SettlementTaxesAndCommissions />

        <EditInvoiceActions invoice={methods.watch("invoice")} />
      </form>
    </FormProvider>
  );
}

export default EditInvoiceForm;
