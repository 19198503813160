// Hooks (react-router-dom) imports
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Custom queries imports
import { useTicket } from "../../../features/tickets/useTicket";
import { usePersonByIdCard } from "../../../features/persons/usePersonByIdCard";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import CardMetodoPago from "./CardMetodoPago";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Mocks imports
import { METODOS } from "../../../features/payment_methods/mocks";

function ListPaymentMethods() {
  const navigate = useNavigate();
  const params = useParams();
  let [searchParams] = useSearchParams();
  const personByID: any = usePersonByIdCard(params.idCliente);
  const ticket = useTicket(
    Number(params.idCobro),
    searchParams.get("accessCode") || ""
  );

  if (ticket.isLoading || personByID.isLoading) {
    return <Loading text="Cargando cobro..." />;
  }

  if (personByID.isError) {
    navigate("/pagar");
    return null;
  }

  if (ticket.isError || !ticket.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar cobro" />
        </div>
      </div>
    );
  }

  if (ticket.data.invoiceStatus > 0) {
    navigate(
      `/pagar/${ticket.data.idCard}/t/${ticket.data.invoiceID}/resumen?accessCode=${ticket.data.accessCode}`
    );
    return null;
  }

  return (
    <ContentWithToggle
      title="Metodo de Pago"
      onBackButton={() =>
        navigate(
          `/pagar/${ticket.data?.idCard}/c/${ticket.data?.invoiceID}?accessCode=${ticket.data?.accessCode}`
        )
      }
    >
      <div className="w-full border-t">
        <p className="text-gray-primary my-4 px-4">
          Selecciona un metodo de pago para continuar con tu pago
        </p>
        <div className="w-full flex flex-col divide-y border-t">
          {METODOS.map((metodo, index) => (
            <CardMetodoPago key={index + 1} id={index + 1} {...metodo} />
          ))}
        </div>
      </div>
    </ContentWithToggle>
  );
}

export default ListPaymentMethods;
