// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom hooks Redux imports
import { useAppSelector } from "../../../app/hook";

// Third helpers imports
import {
  confirmChange,
  isStoreEmployee,
  isStoreSupervisor,
} from "../../../features/auth/helpers";

function EditCustomerActions() {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);

  if (
    !currentUser.user?.roles ||
    isStoreSupervisor(currentUser.user.roles) ||
    isStoreEmployee(currentUser.user.roles)
  )
    return null;

  return (
    <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
      <button
        className="button-secondary text-sm py-2 px-4"
        onClick={() => {
          confirmChange(
            "¿Estas seguro de cancelar estos cambios?",
            "Al cancelar, todos los cambios seran ignorados",
            () => {
              navigate(`/${currentUser.user?.id}/clientes`);
            }
          );
        }}
        type="button"
      >
        Cancelar
      </button>
      <button type="submit" className="button-primary text-sm py-2 px-4">
        Actualizar
      </button>
    </div>
  );
}

export default EditCustomerActions;
