interface Props {
  step: number;
}

interface Step {
  name: string;
  step: number;
}

function Steps({ step }: Props) {
  const STEPS: Step[] = [
    {
      name: "Inf. Comercio",
      step: 1,
    },
    {
      name: "Crear Supervisor",
      step: 2,
    },
    {
      name: "Seleccionar plan",
      step: 3,
    },
    {
      name: "Confirmar",
      step: 4,
    },
  ];
  return (
    <div className="px-4 border-b">
      <div className="transition-all relative w-full my-[37px] flex justify-center">
        <hr className="w-[calc(100%-25%)] h-[2px] border-0 bg-gray-fourth absolute mt-5 mb-0 z-10" />
        <div className="w-full flex justify-between z-20">
          {STEPS.map((stepItem, index) => (
            <div
              key={index}
              className="w-1/4 transition-all flex flex-col items-center"
            >
              <div className="mb-[7px] rounded-full">
                <i
                  className={`text-[26px] sm:text-[32px] before:rounded-full before:bg-white ${
                    stepItem.step > step ? "text-gray-fourth bi bi-circle" : ""
                  } ${
                    stepItem.step === step
                      ? "text-red-primary bi bi-circle"
                      : ""
                  } ${
                    stepItem.step < step
                      ? "text-red-primary bi bi-record-circle"
                      : ""
                  }`}
                ></i>
              </div>
              <p
                className={`text-center w-full text-[10px] sm:text-xs truncate ${
                  stepItem.step > step ? "text-gray-primary" : ""
                } ${stepItem.step === step ? "text-red-primary" : ""} ${
                  stepItem.step < step ? "text-red-primary font-bold" : ""
                } `}
              >
                {stepItem.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Steps;
