// State Redux imports
import { useEffect, useState } from "react";

// State Redux imports
import { useAppSelector, useAppDispatch } from "../../../app/hook";
import { logout } from "../../../features/auth/authSlice";

function toMinuteAndSecond(x: number) {
  if (x < 0) return "00:00";
  const hrs = Math.floor(x / 3600);
  const mins = Math.floor((x % 3600) / 60);
  const secs = Math.floor(x % 60);
  let f = hrs > 0 ? (hrs > 9 ? hrs : "0" + hrs) + "h " : "00h ";
  f += mins > 0 ? (mins > 9 ? mins : "0" + mins) + ":" : "00:";
  f += secs > 9 ? secs : "0" + secs;
  return f;
}

function TokenExpirationCountdown() {
  const [inLastMinutes, setInLastMinutes] = useState(false);
  const [progress, setProgress] = useState(0);
  const [time, setTime] = useState("00:00");
  const currentUser = useAppSelector((state) => state.auth);
  const expiredBy = currentUser.user?.token.expiredBy;
  const configurationTokenValidity = 1500;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (expiredBy) {
      const intervalID = setInterval(() => {
        const msExpiredBy = Number(new Date(expiredBy).getTime());
        const msDateNow = Number(new Date().getTime());
        const msOffset = Math.floor((msExpiredBy - msDateNow) / 1000);
        const progress = (msOffset * 100) / configurationTokenValidity;
        if (msOffset < 0) return dispatch(logout());
        if (msOffset < 120 && !inLastMinutes) setInLastMinutes(true);
        setProgress(100 - Math.floor(progress));
        setTime(toMinuteAndSecond(msOffset));
      }, 1000);
      return () => clearInterval(intervalID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${
        inLastMinutes ? "sticky z-40 top-0 sm:top-[65px]" : ""
      } mx-auto w-full flex flex-col items-center justify-center`}
    >
      <div className="h-8 bg-gray-400 w-full relative flex items-center">
        <div
          className={`${
            inLastMinutes ? "animate-pulse bg-red-600" : ""
          } h-8 bg-red-500`}
          style={{ width: `${progress}%` }}
        ></div>
        <p className="text-base text-white absolute w-full text-center">
          La sesión expira en: <b>{time}</b>
        </p>
      </div>
    </div>
  );
}

export default TokenExpirationCountdown;
