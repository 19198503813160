// Custom queries imports
import { useSendInvoiceMutation } from "./useSendInvoiceMutation";
import { useCreateInvoiceMutation } from "./useCreateInvoiceMutation";
import { useCreatePersonMutation } from "../persons/useCreatePersonMutation";
import { usePersonByEmailMutation } from "../persons/usePersonByEmailMutation";
import { usePersonByPhoneMutation } from "../persons/usePersonByPhoneMutation";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom types imports
import { InvoiceRegistration } from "./types";
import { PersonRegistration } from "../persons/types";

export function useCreateUserAndInvoice(
  person: PersonRegistration,
  invoice: InvoiceRegistration
) {
  const navigate = useNavigate();
  const sendInvoiceByEmailMutation = useSendInvoiceMutation();

  const createInvoiceMutation = useCreateInvoiceMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Cobro creado",
        autoClose: 5000,
      });
      sendInvoiceByEmailMutation.mutate({ invoice: data, notificationID: "" });
      navigate(
        `/${data.idCard}/cobros/c/${data.invoiceID}?accessCode=${data.accessCode}`
      );
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar crear el cobro",
        autoClose: 5000,
      });
    }
  );

  const createPersonMutation = useCreatePersonMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "3/3 Creando cobro...",
        autoClose: 5000,
      });
      createInvoiceMutation.mutate({
        invoice: {
          ...invoice,
          date: new Date().toISOString(),
        },
        notificationID: variables.notificationID,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar registrar la persona",
        autoClose: 5000,
      });
    }
  );

  const validPhoneMutation = usePersonByPhoneMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El número telefónico ya está en uso, intenta de nuevo con otro número telefónico",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "2/3 Creando persona...",
        autoClose: 5000,
      });
      createPersonMutation.mutate({
        person,
        notificationID: variables.notificationID,
      });
    }
  );

  const validEmailMutation = usePersonByEmailMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El correo electrónico ya está en uso, intenta de nuevo con otro correo electrónico",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "1/3 Validando telefono...",
        autoClose: 5000,
      });
      validPhoneMutation.mutate({
        phone: person.phoneNumber,
        notificationID: variables.notificationID,
      });
    }
  );

  return validEmailMutation;
}
