// Custom components imports
import ContentWithToggle from "../shared/ContentWithToggle";
import Loading from "../shared/Loading";
import ErrorLoading from "../shared/ErrorLoading";
import EmptyContent from "../shared/EmptyContent";
import PaymentCard from "../cards/PaymentCard";

// Hooks (react-router-dom) imports
import { useParams, useNavigate } from "react-router-dom";

// Custom types imports
import { TicketList } from "../../features/tickets/types";

// Types (@tanstack/react-query) imports
import { UseQueryResult } from "@tanstack/react-query";

interface Props {
  tickets: UseQueryResult<TicketList, unknown>;
}

function RecentPaymentsList({ tickets }: Props) {
  const params = useParams();
  const navigate = useNavigate();

  if (tickets.isLoading) {
    return (
      <div className="w-full bg-white rounded-lg overflow-hidden">
        <ContentWithToggle title="Transacciones recientes" isToogle={true}>
          <Loading text="Cargando transacciones..." />
        </ContentWithToggle>
      </div>
    );
  }

  if (tickets.isError || !tickets.data) {
    return (
      <div className="w-full bg-white rounded-lg overflow-hidden">
        <ContentWithToggle title="Transacciones recientes" isToogle={true}>
          <div className="w-full border-t">
            <div className="w-full">
              <ErrorLoading text="Hubo un error al cargar transacciones" />
            </div>
          </div>
        </ContentWithToggle>
      </div>
    );
  }

  if (tickets.data.invoices.length === 0) {
    return (
      <div className="w-full bg-white rounded-lg overflow-hidden">
        <ContentWithToggle title="Transacciones recientes" isToogle={true}>
          <div className="w-full border-t">
            <div className="w-full">
              <EmptyContent text="No hay transacciones" />
            </div>
          </div>
        </ContentWithToggle>
      </div>
    );
  }

  return (
    <div className="w-full bg-white rounded-lg overflow-hidden">
      <ContentWithToggle title="Transacciones recientes" isToogle={true}>
        <div className="w-full flex flex-col divide-y">
          {tickets.data.invoices.map((ticket, index) => (
            <PaymentCard
              ticket={ticket}
              key={index}
              link={`/${params.idCliente}/transacciones/t/${ticket.invoiceID}`}
            />
          ))}

          <button
            onClick={() => navigate(`/${params.idCliente}/transacciones`)}
            className="bg-red-primary hover:saturate-150 font-bold  text-white py-2 flex items-center justify-center gap-x-4"
          >
            Ver más
          </button>
        </div>
      </ContentWithToggle>
    </div>
  );
}
export default RecentPaymentsList;
