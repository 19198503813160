// Custom type imports
import { PlanStore } from "../../../features/plans/types";

interface Props {
  plan: PlanStore;
}

function CurrentPlan({ plan }: Props) {
  return (
    <div className="border-b w-full mx-auto bg-white overflow-hidden">
      <div className="text-sm w-full flex flex-col divide-y">
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Plan</span>
          <b className="text-gray-600">{plan.plan.serviceName}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Transacciones diarias</span>
          <b className="text-gray-600">{plan.plan.dailyTransactions}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Transacciones mensuales</span>
          <b className="text-gray-600">{plan.plan.monthlyTransactions}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Incio el</span>
          <b className="text-gray-600">
            {new Date(plan.initialDate).toLocaleDateString("es-CO", {
              year: "numeric",
              month: "long",
              day: "numeric",
              minute: "2-digit",
            })}
          </b>
        </div>
      </div>
    </div>
  );
}

export default CurrentPlan;
