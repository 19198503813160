// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";
import CurrencyInput from "react-currency-input-field";
import ContentWithToggle from "../../shared/ContentWithToggle";
import PaymentCancellation from "../../PaymentCancellation";
import SettlementTaxesAndCommissionsTicket from "../../shared/SettlementTaxesAndCommissionsTicket";

// Custom types imports
import { Ticket, PaymentReceipt } from "../../../features/payments/types";
import { CARD_TYPES_INFO } from "../../../features/card_types/types";
import { PAYMENT_ENTITY_INFO } from "../../../features/payment_methods/types";
import { TAX_REGIME_INFO } from "../../../features/stores/types";
import {
  StatusName,
  invoiceStatus,
  invoiceStatusData,
} from "../../../features/invoices/types";

// Custom queries imports
import { taxRegimeMock } from "../../../features/stores/mocks";

// Custom hooks imports
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hook";

// Custom helpers imports
import { toast } from "react-toastify";

interface Prop {
  ticket: Ticket;
  payment: PaymentReceipt;
  backURL: string;
}

function PaymentInformation({ ticket, payment, backURL }: Prop) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);

  const linkPay = `${window.location.origin}/pagar/${ticket.customer?.idCard}/t/${ticket.id}?accessCode=${ticket.accessCode}`;
  const statusName = invoiceStatus[ticket.invoiceStatus];
  const statusData = invoiceStatusData[statusName as StatusName];
  return (
    <>
      <ContentWithToggle
        title={`Detalle de transacción #${ticket.id}`}
        onBackButton={
          !!currentUser.isAuthenticated ? () => navigate(backURL) : undefined
        }
        isToogle={true}
      >
        <PaymentCancellation ticket={ticket} payment={payment} />

        <div className="border-b text-gray-500 w-full px-4 py-3 flex flex-col">
          <span>Copia y comparte el enlace de pago</span>
          <div className="w-full mt-2 flex text-sm">
            <input
              disabled
              type="text"
              className="text-sm input-base bg-gray-100 rounded-r-none border-r-0"
              value={linkPay}
            />
            <button
              className="text-2xl button-primary rounded-lg rounded-l-none py-0 h-auto"
              type="button"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(linkPay);
                  toast.success("Enlace copiado");
                } catch (error) {
                  toast.error("Algo salio mal al intentar copiar enlace");
                }
              }}
            >
              <i className="bi bi-link-45deg"></i>
            </button>
          </div>
        </div>

        <div
          className={`${statusData.color} text-white border-b w-full px-4 py-3 flex justify-between items-center`}
        >
          <span>Estado</span>
          <b>{statusData.description}</b>
        </div>

        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Metodo de pago</span>
          <div className="w-1/2 flex items-center justify-end">
            <img
              className="w-[30px] h-[30px] shadow-custom-md rounded-[5px]"
              src={PAYMENT_ENTITY_INFO[ticket.paymentMethod].logo}
              alt=""
            />
            <div className="flex flex-col ml-[14px]">
              <b className="truncate ...">
                {PAYMENT_ENTITY_INFO[ticket.paymentMethod].description}
              </b>
            </div>
          </div>
        </div>

        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Fecha de emisión</span>
          <span>
            {(ticket.date &&
              new Date(ticket.date).toLocaleDateString("es-CO", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })) ||
              "No disponible"}
          </span>
        </div>

        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Fecha de pago</span>
          <b>
            {new Date(payment.date).toLocaleDateString("es-CO", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </b>
        </div>

        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Nro. de comprobante</span>
          <b>{payment.receipt}</b>
        </div>

        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Codigo de autorización</span>
          <b>{payment.authorizationCode}</b>
        </div>

        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Código de acceso</span>
          <b>{ticket.accessCode}</b>
        </div>

        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="ID"
            id="id"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="ID"
              className="input-base"
              value={ticket.id}
            />
          </CustomLabel>

          <CustomLabel
            label="Nro. Factura"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Factura de compra"
              className="input-base"
              value={ticket.storeInvoice}
            />
          </CustomLabel>

          <CustomLabel
            label="Precio de venta"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <CurrencyInput
              decimalSeparator=","
              groupSeparator="."
              id="amount"
              placeholder="Precio de venta"
              disabled
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              prefix="$ "
              value={ticket.amount}
              className="input-base"
            />
          </CustomLabel>
          <CustomLabel
            label="IVA"
            id="iva"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <CurrencyInput
              decimalSeparator=","
              groupSeparator="."
              id="iva"
              placeholder="IVA"
              disabled
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              prefix="$ "
              value={ticket.iva}
              className="input-base"
            />
          </CustomLabel>

          <CustomLabel
            label="Descripcion"
            id="description"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <textarea
              autoCorrect="off"
              disabled
              defaultValue={ticket.description}
              className="w-full input-base h-[80px] p-5 text-gray-primary"
            ></textarea>
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del cliente"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={
                (!!ticket.customer?.idCardType &&
                  CARD_TYPES_INFO[ticket.customer?.idCardType]?.description) ||
                1
              }
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="idCardValue"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.customer?.idCardValue}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombres"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Nombres"
              className="input-base"
              value={ticket.customer?.firstName || ""}
            />
          </CustomLabel>

          <CustomLabel
            label="Apellidos"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Nombres"
              className="input-base"
              value={ticket.customer?.lastName || ""}
            />
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del comercio"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={CARD_TYPES_INFO[2]?.description}
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="taxIDNumber"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.store?.taxIDNumber}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombre de comercio"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Razon social"
              className="input-base"
              value={ticket.store?.storeName || ""}
            />
          </CustomLabel>

          {!!ticket.store?.taxCondition && (
            <CustomLabel
              label="Régimen tributario"
              id="taxCondition"
              required={false}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  className="input-base appearance-none"
                  disabled
                  value={ticket.store.taxCondition}
                >
                  {taxRegimeMock?.map((taxRegime) => (
                    <option value={taxRegime} key={taxRegime}>
                      {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                      {TAX_REGIME_INFO[taxRegime]?.description}
                    </option>
                  ))}
                </select>
              </div>
            </CustomLabel>
          )}
        </div>
      </ContentWithToggle>

      <SettlementTaxesAndCommissionsTicket ticket={ticket} />
    </>
  );
}

export default PaymentInformation;
