// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

export function useCardTypes() {
  async function getCardTypes(): Promise<number[]> {
    const response = await api.get<number[]>("admin/idcardtypes");

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data.slice(0, 4);
  }

  return useQuery({
    queryKey: ["CardTypes"],
    queryFn: () => getCardTypes(),
  });
}
