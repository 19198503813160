// Custom types imports
import { Invoice } from "../../../../features/invoices/types";

// (react-toastify) imports
import { toast } from "react-toastify";

// Custom queries imports
import { useDeleteInvoiceMutation } from "../../../../features/invoices/useDeleteInvoiceMutation";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

// Third helpers imports
import { confirmChange } from "../../../../features/auth/helpers";

interface Props {
  invoice: Invoice;
}
function EditInvoiceActions({ invoice }: Props) {
  const currentUser = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const deleteInvoiceByIdMutation = useDeleteInvoiceMutation(
    (_data, variables) => {
      variables.notificationID &&
        toast.update(variables.notificationID, {
          isLoading: false,
          type: "success",
          render: `Cobro #${invoice.id} eliminado satisfactoriamente`,
          autoClose: 5000,
        });
      navigate(`/${currentUser?.id}/cobros`);
    },
    (error, variables) => {
      variables.notificationID &&
        toast.update(variables.notificationID, {
          isLoading: false,
          type: "error",
          render:
            error.data?.description ||
            "Algo salio mal, al intentar eliminar el cobro",
          autoClose: 5000,
        });
    }
  );

  if (invoice.invoiceStatus !== 0) return null;

  return (
    <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
      <button
        className="button-secondary text-sm py-2 px-4"
        onClick={() => {
          confirmChange(
            "¿Estas seguro de eliminar este cobro?",
            "Al eliminar el cobro, sera un cambio permanente",
            () => {
              let notificationID = toast.loading("Anulando cobro...");
              deleteInvoiceByIdMutation.mutate({
                id: invoice.id,
                notificationID,
              });
            }
          );
        }}
        type="button"
      >
        Eliminar
      </button>
      <button type="submit" className="button-primary text-sm py-2 px-4">
        Actualizar
      </button>
    </div>
  );
}

export default EditInvoiceActions;
