// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import CustomLabel from "../../../shared/fields/CustomLabel";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { TAX_REGIME_INFO } from "../../../../features/stores/types";
import { Ticket } from "../../../../features/payments/types";

// Components (react-currency-input-field) imports
import CurrencyInput from "react-currency-input-field";

// Custom mocks imports
import { taxRegimeMock } from "../../../../features/stores/mocks";

interface Prop {
  ticket: Ticket;
}

function InvoiceInformation({ ticket }: Prop) {
  const minDate = ticket.minDate;
  const invoiceExpirationDate =
    minDate && new Date(minDate).toISOString().substring(0, 10);

  return (
    <>
      <ContentWithToggle
        title={`Detalle de cobro #${ticket.invoiceID}`}
        isToogle={true}
        defaultOpenValue={true}
      >
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Fecha de emisión</span>
          <b>
            {" "}
            {ticket.date
              ? new Date(ticket.date).toLocaleDateString("es-CO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : ""}
          </b>
        </div>
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="ID"
            id="id"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="ID"
              className="input-base"
              value={ticket.invoiceID}
            />
          </CustomLabel>

          <CustomLabel
            label="Nro. Factura"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Factura de compra"
              className="input-base"
              value={ticket.storeInvoice}
            />
          </CustomLabel>

          <CustomLabel
            label="Precio de venta"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <CurrencyInput
              decimalSeparator=","
              groupSeparator="."
              id="amount"
              name="amount"
              placeholder="Precio de venta"
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              disabled
              prefix="$ "
              value={ticket.amount}
              className="input-base"
            />
          </CustomLabel>

          <CustomLabel
            label="Vigencia"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="date"
              disabled
              placeholder="Vigencia"
              className="input-base"
              value={invoiceExpirationDate}
            />
          </CustomLabel>

          <CustomLabel
            label="Descripcion"
            id="description"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <textarea
              autoCorrect="off"
              disabled
              defaultValue={ticket.description}
              className="w-full input-base h-[80px] p-5 text-gray-primary"
            ></textarea>
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del cliente"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={
                (!!ticket.customer?.idCardType &&
                  CARD_TYPES_INFO[ticket.customer?.idCardType]?.description) ||
                1
              }
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="idCardValue"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.customer?.idCardValue}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombres"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Nombres"
              className="input-base"
              value={ticket.customer?.firstName || ""}
            />
          </CustomLabel>

          <CustomLabel
            label="Apellidos"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Nombres"
              className="input-base"
              value={ticket.customer?.lastName || ""}
            />
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del comercio"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={CARD_TYPES_INFO[2]?.description}
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="taxIDNumber"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.store?.taxIDNumber}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombre de comercio"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Razon social"
              className="input-base"
              value={ticket.store?.storeName || ""}
            />
          </CustomLabel>

          {!!ticket.store?.taxCondition && (
            <CustomLabel
              label="Régimen tributario"
              id="taxCondition"
              required={false}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  className="input-base appearance-none"
                  disabled
                  value={ticket.store.taxCondition}
                >
                  {taxRegimeMock?.map((taxRegime) => (
                    <option value={taxRegime} key={taxRegime}>
                      {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                      {TAX_REGIME_INFO[taxRegime]?.description}
                    </option>
                  ))}
                </select>
              </div>
            </CustomLabel>
          )}
        </div>
      </ContentWithToggle>
    </>
  );
}

export default InvoiceInformation;
