// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import ListPaymentMethods from "../../components/ListPaymentMethods";

// Custom queries imports
import { usePaymentMethods } from "../../features/payment_methods/usePaymentMethods";

// Hooks (react-router-dom") imports
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function PaymentMethods() {
  const params = useParams();
  const navigate = useNavigate();

  const paymentMethods = usePaymentMethods();

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        onBackButton={() => navigate(`/${params.idUser}/escritorio`)}
        title="Métodos de Pago"
      >
        <ListPaymentMethods paymentMethods={paymentMethods} />
      </ContentWithToggle>
    </div>
  );
}

export default PaymentMethods;
