// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Custom components imports
import EditRosterForm from "./EditRosterForm";
import DeletePerson from "../../../DeletePerson";
import UpdateStatusRoster from "./UpdateStatusRoster";
import UpdateRoleRoster from "./UpdateRoleRoster";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useUpdatePersonMutation } from "../../../../features/persons/useUpdatePersonMutation";
import { useRemovePersonMutation } from "../../../../features/persons/useRemovePersonMutation";

//Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { rosterToPersonUpdate } from "../../../../features/persons/helpers";
import {
  confirmChange,
  isCorporateAdmin,
  isStoreEmployee,
  isStoreSupervisor,
} from "../../../../features/auth/helpers";

// Custom types imports
import { RosterModel, Roster } from "../../../../features/roster/types";
import { PersonUpdate } from "../../../../features/persons/types";

// Hook & Components Redux imports
import { useAppSelector } from "../../../../app/hook";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paramsRostersInfinite: RosterModel;
  roster: Roster;
}

function DrawerEditRoster({
  open,
  setOpen,
  paramsRostersInfinite,
  roster,
}: Props) {
  const queryClient = useQueryClient();
  const personUpdate = rosterToPersonUpdate(roster);
  const currentUser = useAppSelector((state) => state.auth);

  const methods = useForm<PersonUpdate>({
    defaultValues: personUpdate,
  });

  // const closeMenuClickOuside = (event: any) => {
  //   let idElement = event.target.id;
  //   if (idElement === "containerDrawer") closeDrawer();
  // };

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const isStoreRoster =
    isStoreEmployee(roster.roles) || isStoreSupervisor(roster.roles);

  const removePersonMutation = useRemovePersonMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Usuario eliminado`,
        autoClose: 5000,
      });
      queryClient.invalidateQueries([
        "RosterInfinite",
        ...Object.values(paramsRostersInfinite),
      ]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar eliminar el usuario",
        autoClose: 5000,
      });
    }
  );

  const updatePersonMutation = useUpdatePersonMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Actualizando informacion del usuario`,
        autoClose: 5000,
      });
      queryClient.invalidateQueries([
        "RosterInfinite",
        ...Object.values(paramsRostersInfinite),
      ]);
      closeDrawer();
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar actualizar el usuario",
        autoClose: 5000,
      });
    }
  );

  const onSubmitUpdatePerson = methods.handleSubmit((data) => {
    let notificationID = toast.loading(
      "Actualizando información del usuario..."
    );
    updatePersonMutation.mutate({
      data: data,
      notificationID,
    });
  });

  if (!currentUser.user?.roles) return null;

  return (
    <div
      // onClick={closeMenuClickOuside}
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[580px] h-full sm:rounded-l-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmitUpdatePerson}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-2xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-lg">
                {roster.firstName} {roster.lastName}
              </b>
              <button
                className={`${
                  methods.formState.isDirty
                    ? ""
                    : "opacity-50 pointer-events-none"
                } ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm`}
                type="submit"
              >
                Actualizar
              </button>
            </div>
            <EditRosterForm roster={roster} />
          </form>
        </FormProvider>
        {isStoreRoster && (
          <>
            <UpdateStatusRoster
              roster={roster}
              paramsRostersInfinite={paramsRostersInfinite}
            />
            <UpdateRoleRoster
              roster={roster}
              paramsRostersInfinite={paramsRostersInfinite}
            />
          </>
        )}
        {isCorporateAdmin(currentUser.user.roles) && (
          <DeletePerson
            onDelete={() => {
              confirmChange(
                "¿Estas seguro de eliminar este usuario?",
                "Al eliminar este usuario, el cambio sera permanente",
                () => {
                  let notificationID = toast.loading(
                    "Eliminando el usuario..."
                  );
                  removePersonMutation.mutate({
                    email: roster.email,
                    notificationID,
                  });
                }
              );
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DrawerEditRoster;
