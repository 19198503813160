// Custom queries imports
import { useCreateUserMutation } from "../users/useCreateUserMutation";
import { useCreatePersonMutation } from "../persons/useCreatePersonMutation";
import { usePersonByEmailMutation } from "../persons/usePersonByEmailMutation";
import { usePersonByUsernameMutation } from "../persons/usePersonByUsernameMutation";
import { usePersonByIdCardMutation } from "../persons/usePersonByIdCardMutation";

// Third components & helpers imports
import { toast } from "react-toastify";

// Custom types imports
import { UserRegister, NewlyRegisteredUser } from "../users/types";
import { PersonRegistration } from "../persons/types";

interface paramsType {
  data: {
    person: PersonRegistration;
    user: UserRegister;
  };
  onSuccessMutation?: (user?: NewlyRegisteredUser) => void;
}

export function useSignUpMutation(params: paramsType) {
  const createUserMutation = useCreateUserMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Usuario creado",
        autoClose: 5000,
      });
      params.onSuccessMutation?.(data);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar registrar el usuario",
        autoClose: 5000,
      });
    }
  );

  const createPersonMutation = useCreatePersonMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "3/3 Creando usuario...",
        autoClose: 5000,
      });
      createUserMutation.mutate({
        user: {
          ...params.data.user,
          modifiedAfter: new Date().toISOString(),
          expiredBy: new Date().toISOString(),
          token: data.passwordToken,
        },
        notificationID: variables.notificationID,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar registrar la persona",
        autoClose: 5000,
      });
    }
  );

  const validIdCardMutation = usePersonByIdCardMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El documento de identidad ya está en uso, intenta de nuevo con otro documento de identidad",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "2/3 Creando persona...",
        autoClose: 5000,
      });
      createPersonMutation.mutate({
        person: params.data.person,
        notificationID: variables.notificationID,
      });
    }
  );

  const validUsernameMutation = usePersonByUsernameMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El nombre de usuario ya está en uso, intenta de nuevo con otro nombre de usuario",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "1/3 Validando número de documento...",
        autoClose: 5000,
      });
      validIdCardMutation.mutate({
        idCard: params.data.person.idCard,
        notificationID: variables.notificationID,
      });
    }
  );

  const validEmailMutation = usePersonByEmailMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El correo electrónico ya está en uso, intenta de nuevo con otro correo electrónico",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "1/3 Validando nombre de usuario...",
        autoClose: 5000,
      });
      validUsernameMutation.mutate({
        username: params.data.user.userName,
        notificationID: variables.notificationID,
      });
    }
  );

  return validEmailMutation;
}
