// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Invoice } from "./types";
import { InvoiceByModel } from "./types";

export function useInvoices(params: InvoiceByModel) {
  async function getInvoices(model: InvoiceByModel): Promise<Invoice[]> {
    const response = await api.post<Invoice[]>(
      "invoices/invoices/bymodel",
      model
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Invoices", ...Object.values(params)],
    queryFn: () => getInvoices(params),
  });
}
