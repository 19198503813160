// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import StoreCard from "../cards/StoreCard";
import EndMessageInfiniteList from "../shared/EndMessageInfiniteList";
import LoaderInfiniteList from "../shared/LoaderInfiniteList";

// Components (react-infinite-scroll-component) imports
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks (react-router-dom) imports
import { UseInfiniteQueryResult } from "@tanstack/react-query";

// Custom types imports
import { StoreList } from "../../features/stores/types";

interface Props {
  stores: UseInfiniteQueryResult<StoreList, unknown>;
}

function InfiniteListStores({ stores }: Props) {
  const data = stores.data?.pages?.flatMap((page) => page.stores) || [];

  if (stores.isLoading) {
    return <Loading text="Cargando comercios..." />;
  }

  if (stores.isError || !stores.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar comercios" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay comercios" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de comercios</span>
        <div className="w-1/2 text-end">
          <b>{stores.data.pages[0].storesData.totalItems}</b>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.length || 0}
        next={() => stores.fetchNextPage()}
        hasMore={!!stores.hasNextPage}
        loader={<LoaderInfiniteList />}
        endMessage={<EndMessageInfiniteList message="Carga completada" />}
      >
        <div className="divide-y">
          {data.map((store, index) => (
            <StoreCard store={store} key={index} link={`c/${store.id}`} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteListStores;
