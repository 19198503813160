// React Components
import { Dispatch, SetStateAction } from "react";

// Custom componentes imports
import LinkWithoutSubmenu from "../../../LinkWithoutSubmenu";

interface Props {
  inMenu?: boolean;
  setIsOpenMenu?: Dispatch<SetStateAction<boolean>>;
}

function LinksPublic({ inMenu, setIsOpenMenu }: Props) {
  return (
    <>
      <LinkWithoutSubmenu
        text="Pagar"
        to="/pagar"
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Ingresar"
        to="/ingresar"
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
    </>
  );
}
export default LinksPublic;
