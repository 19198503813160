// React imports
import { useState } from "react";

// Third components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Hooks & Components Redux imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { StoreModel } from "../../../features/stores/types";

// Custom queries imports
import { useProvicesByCountry } from "../../../features/provinces/useProvicesByCountry";
import { useCitiesByProvince } from "../../../features/cities/useCitiesByProvince";

// Custom types imports
import { TAX_REGIME_INFO } from "../../../features/stores/types";
import { taxRegimeMock } from "../../../features/stores/mocks";

function StoreFilterInvoice() {
  const methods = useFormContext<StoreModel>();
  const [provinceSelected, setProvinceSelected] = useState<number | undefined>(
    undefined
  );

  const provinces = useProvicesByCountry(170);
  const cities = useCitiesByProvince(provinceSelected);

  return (
    <form>
      <div className="border-b w-full flex flex-col divide-y">
        <div className="w-full px-4 py-3 flex flex-wrap justify-between items-center">
          <CustomLabel
            label="Departamento"
            id="departamento"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={provinces.isLoading || !provinces.data}
                className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                value={provinceSelected}
                onChange={(event) =>
                  setProvinceSelected(Number(event.currentTarget.value))
                }
              >
                <option value="">Todas</option>
                {provinces.data?.map((provice) => (
                  <option value={provice.id} key={provice.id}>
                    {provice?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Ciudad"
            id="storeCityID"
            required={false}
            className="text-sm w-full sm:w-[48%]"
            name="storeCityID"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  provinces.isLoading || cities.isLoading || !cities.data
                }
                className="input-base pr-[40px] overflow-hidden appearance-none"
                {...methods.register("cityID", {
                  valueAsNumber: true,
                })}
              >
                <option value="0">Todas</option>
                {cities.data?.map((city) => (
                  <option value={city.id} key={city.id}>
                    {city?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Régimen tributario"
            id="taxCondition"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <div className="w-full flex items-center relative">
              <select
                className="input-base appearance-none"
                {...methods.register("taxCondition", {
                  valueAsNumber: true,
                })}
              >
                <option value="0">Todos</option>
                {taxRegimeMock?.map((taxRegime) => (
                  <option value={taxRegime} key={taxRegime}>
                    {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                    {TAX_REGIME_INFO[taxRegime]?.description}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>
        </div>
      </div>
    </form>
  );
}

export default StoreFilterInvoice;
