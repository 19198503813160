// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { PaymentMethodN } from "../../../../features/payment_methods/types";

function DataPaymentMethodForm() {
  const methods = useFormContext<PaymentMethodN>();
  const applyRetention = methods.watch("retention");
  return (
    <>
      <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
        <CustomLabel
          label="Nombre"
          id="paymentMethodName"
          required={true}
          className="text-sm sm:col-span-2"
          name="paymentMethodName"
        >
          <input
            type="text"
            placeholder="Nombre"
            className={`input-base ${
              methods.formState.errors.paymentMethodName ? "input-error" : ""
            } ${
              !methods.formState.errors.paymentMethodName &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("paymentMethodName", {
              required: "Este campo es requerido",
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Comisión IVA (%)"
          id="ivaCommission"
          required={true}
          className="text-sm sm:col-span-1"
          name="ivaCommission"
        >
          <input
            type="number"
            placeholder="Comisión IVA"
            className={`input-base ${
              methods.formState.errors.ivaCommission ? "input-error" : ""
            } ${
              !methods.formState.errors.ivaCommission &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("ivaCommission", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Estado"
          id="status"
          required={true}
          className="text-sm sm:col-span-1"
          name="status"
        >
          <div className="w-full flex items-center relative">
            <select
              className={`border-none text-white ${
                methods.watch("status") ? "bg-green-500" : "bg-red-500"
              } input-base appearance-none ${
                methods.formState.errors.status ? "input-error" : ""
              } ${
                !methods.formState.errors.status &&
                methods.formState.isSubmitted
                  ? "input-valid"
                  : ""
              } `}
              {...methods.register("status", {
                required: "Este campo es requerido",
                valueAsNumber: true,
              })}
            >
              {[
                { description: "Activo", value: 1 },
                { description: "Inactivo", value: 0 },
              ].map((status) => (
                <option value={status.value} key={status.value}>
                  {status.description}
                </option>
              ))}
            </select>
            <i className="text-white text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
          </div>
        </CustomLabel>

        <CustomLabel
          label="Descripcion"
          id="description"
          required={true}
          className="text-sm sm:col-span-4"
          name="description"
        >
          <textarea
            autoCorrect="off"
            className={`w-full input-base h-[80px] p-5 text-gray-primary ${
              methods.formState.errors.description ? "input-error" : ""
            } ${
              !methods.formState.errors.description &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("description", {
              required: "Este campo es requerido",
            })}
          ></textarea>
        </CustomLabel>
      </div>
      <div onClick={()=>{
        methods.setValue("retention", !applyRetention);
      }} className="w-full px-4 py-3 flex justify-between items-center border-b cursor-pointer hover:bg-slate-50">
        <span>Aplica retenciones</span>
        {applyRetention ? (
          <i className="bi bi-check-circle-fill text-lg text-red-primary"></i>
        ) : (
          <i className="bi bi-circle text-lg"></i>
        )}
      </div>
    </>
  );
}
export default DataPaymentMethodForm;
