// Components )react-router-dom) imports
import { Link } from "react-router-dom";

// Hook (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom components imports
import CustomPasswordField from "../../../components/shared/fields/CustomPasswordField";
import CustomLabel from "../../../components/shared/fields/CustomLabel";

// Custom helpers imports
import { USER_KEY } from "../../../features/auth/helpers";
import {
  ALL_ROLES,
  getAllCombinationsOfARoles,
} from "../../../features/auth/helpers";

// Hooks & actions Redux imports
import { useAppDispatch } from "../../../app/hook";
import { loadUser } from "../../../features/auth/authSlice";

// Helpers (react-toastify) imports
import { toast } from "react-toastify";

// Custom queries imports
import { useLoginMutation } from "../../../features/auth/useLoginMutation";

// Custom types imports
import { UserCredentials } from "../../../features/auth/types";

function LoginForm() {
  const dispatch = useAppDispatch();
  const methods = useFormContext<UserCredentials>();
  const loginMutation = useLoginMutation(
    (data, variables) => {
      if (data.userStatus !== 1) {
        toast.update(variables.notificationID, {
          isLoading: false,
          type: "error",
          render: "Usuario inactivo",
          autoClose: 5000,
        });
      } else if (!getAllCombinationsOfARoles(ALL_ROLES).includes(data.roles)) {
        toast.update(variables.notificationID, {
          isLoading: false,
          type: "error",
          render: "Rol de usuario no valido",
          autoClose: 5000,
        });
      } else {
        localStorage.setItem(USER_KEY, JSON.stringify(data));
        dispatch(loadUser(data));
        toast.update(variables.notificationID, {
          isLoading: false,
          type: "success",
          render: "Inicio de sesión satisfactorio",
          autoClose: 2000,
        });
      }
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal al intentar iniciar de sesión",
        autoClose: 2000,
      });
    }
  );

  const onSubmit = methods.handleSubmit(async (data) => {
    const notificationID = toast.loading("Iniciando sesión...");
    loginMutation.mutate({
      credentials: data,
      notificationID,
    });
  });

  return (
    <div className="mx-auto sm:rounded-[18px] w-full max-w-[576px] px-5 sm:px-[60px] py-10 bg-white sm:mt-[65px]">
      <h2 className="text-[28px] text-center font-bold">Ingresa a tu cuenta</h2>
      <p className="text-base text-center text-gray-primary my-7">
        Ingresa tu usuario y contraseña de registro.
      </p>
      <form
        className="flex flex-col justify-center items-center flex-wrap"
        onSubmit={onSubmit}
      >
        <CustomLabel
          label="Email o nombre de usuario"
          id="userNameOrEmail"
          required={true}
          className="text-sm w-full"
          name="userNameOrEmail"
        >
          <input
            type="text"
            placeholder="Email o nombre de usuario"
            className={`input-base ${
              methods.formState.errors.userNameOrEmail ? "input-error" : ""
            }`}
            {...methods.register("userNameOrEmail", {
              required: "Este campo es requerido",
            })}
          />
        </CustomLabel>
        <CustomPasswordField
          placeholder="Contraseña"
          id="password"
          required={true}
          label="Contraseña"
          name="password"
        />
        <Link
          to="/account/requestResetPassword"
          className="text-gray-primary underline mb-[15px]"
        >
          ¿Olvidaste tu contraseña?
        </Link>
        <button className="w-[270px] button-primary" type="submit">
          Ingresar
        </button>
        <Link
          to="/inscribirse"
          className="text-gray-primary mt-[45px]"
        >
          ¿Aún no tienes una cuenta?{" "}
          <b className="text-red-primary">Regístrate ahora</b>
        </Link>
      </form>
    </div>
  );
}

export default LoginForm;
