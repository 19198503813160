// Hooks (React Router Dom) imports
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";

// Custom query imports
import { useTicket } from "../../../features/tickets/useTicket";
import { usePersonByIdCard } from "../../../features/persons/usePersonByIdCard";

// Custom component imports
import Loading from "../../shared/Loading";
import RenderStatus from "./status/RenderStatus";
import ErrorLoading from "../../shared/ErrorLoading";

function Resumen() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const personByID: any = usePersonByIdCard(params.idCliente);
  const ticket = useTicket(
    Number(params.idTransaccion),
    searchParams.get("accessCode") || ""
  );

  if (!!ticket.isLoading || !!personByID.isLoading) {
    return <Loading text="Cargando transaccion..." />;
  }

  if (!!personByID.isError) {
    navigate("/pagar");
    return null;
  }

  if (ticket.isError || !ticket.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar la transacción" />
        </div>
      </div>
    );
  }

  if (ticket.data.invoiceStatus === 0) {
    navigate(
      `/pagar/${ticket.data.idCard}/c/${ticket.data.invoiceID}?accessCode=${ticket.data.accessCode}`
    );
    return null;
  }

  return <RenderStatus ticket={ticket.data} />;
}

export default Resumen;
