// Components (react-router-dom) imports
import { Routes, Route } from "react-router-dom";

// Pages imports
import SignUp from "../../pages/SignUp";
import Login from "../../pages/Login";
import PayForm from "../../pages/PayFom";
import Invoices from "../../pages/Invoices";
import Rejected from "../../pages/Rejected";
import Removed from "../../pages/Removed/Index";
import Cancellations from "../../pages/Cancellations";
import Payments from "../../pages/Payments";
import Clients from "../../pages/Customers";
import Stores from "../../pages/Stores";
import ConfirmEmail from "../../pages/ConfirmEmail";
import RequestResetPassword from "../../pages/RequestResetPassword";
import ResetPassword from "../../pages/ResetPassword";
import Dashboard from "../../pages/Dashboard";
import Settings from "../../pages/Settings";
import { ROLES } from "../../features/users/types";
import NotFound from "../../pages/NotFound";
import NotAuthorized from "../../pages/NotAuthorized";
import Roster from "../../pages/Roster";
import Plans from "../../pages/Plans";

// Types Routes imports
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

// Custom components imports
import App from "../../App";
import ConsultClient from "../forms/payment_gateway/ConsultClient";
import InvoicesList from "../forms/payment_gateway/private/InvoicesList";
import RejectedList from "../forms/payment_gateway/private/RejectedList";
import RemovedList from "../forms/payment_gateway/private/RemovedList";
import PaymentsList from "../forms/payment_gateway/private/PaymentsList";
import CancellationsList from "../forms/payment_gateway/private/CancellationsList";
import PaymentDetails from "../forms/payment_gateway/PaymentDetails";
import InvoiceDetails from "../forms/payment_gateway/InvoiceDetails";
import ListPaymentMethods from "../forms/payment_gateway/ListPaymentMethods";
import ProcessPayment from "../forms/payment_gateway/processors/ProcessPayment";
import CreateInvoice from "../forms/invoice/Create/CreateInvoice";
import EditInvoice from "../forms/invoice/Edit/EditInvoice";
import Resumen from "../forms/payment_gateway/Resumen";
import PublicInvoicesList from "../forms/payment_gateway/public/PublicInvoicesList";
import EditCustomer from "../forms/customer/EditCustomer";
import EditStore from "../forms/store/Edit/EditStore";
import PlanStore from "../forms/store/Edit/PlanStore";
import RosterStore from "../forms/store/Edit/RosterStore";
import Plan from "../../pages/Settings/Plan";
import PaymentMethods from "../../pages/PaymentMethods";
import EditPaymentMethod from "../forms/paymentMethod/Edit/EditPaymentMethod";
import CreatePlan from "../forms/plan/Create/CreatePlan";
import EditPlan from "../forms/plan/Edit/EditPlan";
import CreateStore from "../forms/store/Create/CreateStore";
import PaymentForm from "../PaymentForm";
import ValidCodeAccess from "../ValidCodeAccess";
import CreateRoster from "../forms/roster/Create/CreateRoster";

// Custom helpers imports
import { getAllCombinationsOfARoles } from "../../features/auth/helpers";

function RootNavigation() {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/ingresar"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="/inscribirse"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />

        <Route path="pagar" element={<PayForm />}>
          <Route
            index
            element={
              <PublicRoute>
                <ConsultClient />
              </PublicRoute>
            }
          />
          <Route
            path=":idCliente/cobrosPublicos"
            element={<PublicInvoicesList />}
          />
          <Route path=":idCliente/c/:idCobro" element={<InvoiceDetails />} />
          <Route
            path=":idCliente/t/:idTransaccion"
            element={<PaymentDetails />}
          />
          <Route
            path=":idCliente/c/:idCobro/metodos"
            element={<ListPaymentMethods />}
          />
          <Route
            path=":idCliente/c/:idCobro/procesar"
            element={<ProcessPayment />}
          />
          <Route
            path=":idCliente/t/:idTransaccion/resumen"
            element={<Resumen />}
          />
        </Route>

        <Route
          path="/pagar/:idCliente/cobros"
          element={
            <PrivateRoute>
              <InvoicesList />
            </PrivateRoute>
          }
        />

        <Route
          path="/pagar/:idCliente/eliminados"
          element={
            <PrivateRoute>
              <RemovedList />
            </PrivateRoute>
          }
        />

        <Route
          path="/pagar/:idCliente/rechazados"
          element={
            <PrivateRoute>
              <RejectedList />
            </PrivateRoute>
          }
        />

        <Route
          path="/pagar/:idCliente/transacciones"
          element={
            <PrivateRoute>
              <PaymentsList />
            </PrivateRoute>
          }
        />

        <Route
          path="/pagar/:idCliente/anulaciones"
          element={
            <PrivateRoute>
              <CancellationsList />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/escritorio"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/usuarios"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreSupervisor,
                ROLES.CorporateAdmin,
              ])}
            >
              <Roster />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/usuarios/agregar"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreSupervisor,
                ROLES.CorporateAdmin,
              ])}
            >
              <CreateRoster />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/metodos"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([ROLES.CorporateAdmin])}
            >
              <PaymentMethods />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/metodos/m/:id"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([ROLES.CorporateAdmin])}
            >
              <EditPaymentMethod />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/planes"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([ROLES.CorporateAdmin])}
            >
              <Plans />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/planes/agregar"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <CreatePlan />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/planes/p/:idPlan"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <EditPlan />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/eliminados"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
              ])}
            >
              <Removed />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/rechazados"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
              ])}
            >
              <Rejected />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/cobros"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
              ])}
            >
              <Invoices />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/cobros/agregar"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
              ])}
            >
              <CreateInvoice />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/cobros/c/:idCobro"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
              ])}
            >
              <EditInvoice />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/transacciones"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <Payments />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/anulaciones"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <Cancellations />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/transacciones/t/:idTransaccion"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <PaymentForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/clientes"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <Clients />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/clientes/c/:idCard"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.StoreEmployee,
                ROLES.StoreSupervisor,
                ROLES.CorporateEmployee,
                ROLES.CorporateAdmin,
              ])}
            >
              <EditCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/comercios"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.CorporateAdmin,
                ROLES.CorporateEmployee,
              ])}
            >
              <Stores />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/comercios/c/:idStore"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.CorporateAdmin,
                ROLES.CorporateEmployee,
              ])}
            >
              <EditStore />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/comercios/c/:idStore/plan"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.CorporateAdmin,
                ROLES.CorporateEmployee,
              ])}
            >
              <PlanStore />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/comercios/c/:idStore/usuarios"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.CorporateAdmin,
                ROLES.CorporateEmployee,
              ])}
            >
              <RosterStore />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/comercios/agregar"
          element={
            <PrivateRoute
              roles={getAllCombinationsOfARoles([
                ROLES.CorporateAdmin,
                ROLES.CorporateEmployee,
              ])}
            >
              <CreateStore />
            </PrivateRoute>
          }
        />

        <Route
          path="/:idUser/configuraciones"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/:idUser/configuraciones/plan"
          element={
            <PrivateRoute>
              <Plan />
            </PrivateRoute>
          }
        />
        <Route
          path="/app/account/confirmEmail/:emailUser/"
          element={<ConfirmEmail />}
        />
        <Route
          path="/account/requestResetPassword/"
          element={<RequestResetPassword />}
        />
        <Route
          path="app/account/resetpassword/:emailUser/"
          element={<ResetPassword />}
        />
        <Route
          path="/account/confirmCodeAccess/"
          element={<ValidCodeAccess />}
        />
        <Route path="/status/notauthorized" element={<NotAuthorized />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default RootNavigation;
