// Custom components
import CreateRosterActions from "./CreateRosterActions";
import ContentWithToggle from "../../../shared/ContentWithToggle";
import CreateRosterForm from "./CreateRosterForm";

// Hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

// Custom types imports
import { CreateUserFormData } from "../../../../features/roster/types";
import { formDefaultValues } from "../../../../features/roster/types";

// Custom helpers import
import { isStoreSupervisor } from "../../../../features/auth/helpers";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks & components (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom Hooks imports
import { useCreateRosterInStore } from "../../../../features/stores/useCreateRosterInStore";

function CreateRoster() {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);

  const methods = useForm<CreateUserFormData>({
    defaultValues: {
      ...formDefaultValues,
      roleToAdd:
        currentUser?.roles && isStoreSupervisor(currentUser.roles) ? 2 : 8,
    },
  });

  const createRosterInStoreMutation = useCreateRosterInStore({
    data: {
      person: methods.watch("person"),
      user: methods.watch("user"),
      role: methods.watch("roleToAdd"),
      companyID:
        currentUser?.roles && isStoreSupervisor(currentUser.roles)
          ? currentUser?.companyID
          : -1,
    },
    onSuccessMutation: (user) => {
      navigate(`/${currentUser?.id}/usuarios`);
    },
  });

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("1/4 Validando email del usuario...");
    createRosterInStoreMutation.mutate({
      notificationID,
      email: data.person.email,
    });
  });

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} autoComplete="off" autoSave="off">
          <ContentWithToggle
            title="Crear Empleado"
            onBackButton={() => navigate(`/${currentUser?.id}/usuarios`)}
            isToogle={false}
          >
            <CreateRosterForm />
            <CreateRosterActions />
          </ContentWithToggle>
        </form>
      </FormProvider>
    </div>
  );
}
export default CreateRoster;
