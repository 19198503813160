// Custom components imports
import { Invoice } from "../../../../features/invoices/types";

// Custom helpers imports
import { formatAmount } from "../../../../helpers";

// Components (react-router-dom) imports
import { Link } from "react-router-dom";

interface Prop {
  invoice: Invoice;
  link: string;
}

function PublicInvoiceCard({ invoice, link }: Prop) {
  const total = invoice.amount;
  return (
    <Link to={link} className="w-full p-4 grid grid-cols-2">
      <div className="col-span-1 flex flex-col">
        <div className="mt-1 flex flex-col">
          <span className="text-sm">Nro. de factura</span>
          <b className="text-lg text-black">#{invoice.storeInvoice}</b>
        </div>
      </div>
      <div className="col-span-1 flex flex-col items-end">
        <div className="mt-1 flex flex-col">
          <span className="text-sm">Total</span>
          <b className="text-lg">{formatAmount(total)}</b>
        </div>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <span className="text-sm mr-auto">Fecha de emisión</span>
        <span className="mr-auto italic text-red-500">
          {invoice.date
            ? new Date(invoice.date).toLocaleDateString("es-CO", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : ""}
        </span>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </Link>
  );
}

export default PublicInvoiceCard;
