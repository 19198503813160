// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PaymentReceipt } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  id: number;
  notificationID: string | number;
}

export function useCancelPaymentMutation(
  onSuccessMutation?: (data: PaymentReceipt, variables: paramsType) => void,
  onErrorMutation?: (
    error: ApiErrorResponse<any>,
    variables: paramsType
  ) => void
) {
  const queryClient = useQueryClient();

  async function cancelPayment(params: paramsType): Promise<PaymentReceipt> {
    const response = await api.post<PaymentReceipt>(
      `invoices/payments/${params.id}/cancel`
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: cancelPayment,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Ticket", data.id]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
