import { PersonRegistration } from "../persons/types";
import { UserRegister } from "../users/types";

export type SignUpFormData = {
  person: PersonRegistration;
  user: UserRegister;
};

export const formDefaultValues: SignUpFormData = {
  person: {
    maxItems: 0,
    modifiedAfter: new Date().toISOString(),
    expiredBy: new Date().toISOString(),
    vStatus: 0,
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    idCard: "",
    idCardType: 1,
    idCardValue: "",
    phoneNumber: "",
    phoneCountry: 170,
    phoneNumberValue: "",
    birthCountryID: 170,
    birthDate: new Date().toISOString(),
    name: "",
    passwordToken: true,
  },
  user: {
    maxItems: 0,
    modifiedAfter: new Date().toISOString(),
    expiredBy: new Date().toISOString(),
    vStatus: 0,
    userName: "",
    idCard: "",
    token: "",
    password: "",
    confirmPassword: "",
    login: true,
  },
};

export interface PasswordToken {
  idCard: string;
  code: string;
}
