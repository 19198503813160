// Custom queries imports
import { usePlan } from "../../../../features/plans/usePlan";
import { usePaymentMethods } from "../../../../features/payment_methods/usePaymentMethods";

// Custom components imports
import Loading from "../../../shared/Loading";
import ErrorLoading from "../../../shared/ErrorLoading";
import EditPlanForm from "./EditPlanForm";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditPlan() {
  const params = useParams();
  const plan = usePlan(Number(params.idPlan));
  const paymentMethods = usePaymentMethods();

  if (plan.isLoading || paymentMethods.isLoading) {
    return <Loading text="Cargando plan..." />;
  }

  if (
    plan.isError ||
    !plan.data ||
    paymentMethods.isError ||
    !paymentMethods.data
  ) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar plan" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditPlanForm plan={plan.data} paymentMethods={paymentMethods.data} />
    </div>
  );
}
export default EditPlan;
