// Custom queries imports
import { usePaymentMethod } from "../../../../features/payment_methods/usePaymentMethod";

// Custom components imports
import Loading from "../../../shared/Loading";
import ErrorLoading from "../../../shared/ErrorLoading";
import EditPaymentMethodForm from "./EditPaymentMethodForm";


// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditPaymentMethod() {
  const params = useParams();
  const paymentMethod = usePaymentMethod(Number(params.id));

  if (paymentMethod.isLoading) {
    return <Loading text="Cargando metodo de pago..." />;
  }

  if (paymentMethod.isError || !paymentMethod.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar metodo de pago" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditPaymentMethodForm paymentMethod={paymentMethod.data} />
    </div>
  );
}
export default EditPaymentMethod;
