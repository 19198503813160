// Hooks (react-router-dom) imports
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

// Custom components imports
import Loading from "../../../shared/Loading";
import ErrorLoading from "../../../shared/ErrorLoading";
import CompensarForm from "./CompensarForm";
import InvalidPaymentMethod from "./InvalidPaymentMethod";

// Custom queries imports
import { useTicket } from "../../../../features/tickets/useTicket";
import { usePersonByIdCard } from "../../../../features/persons/usePersonByIdCard";

function ProcessPayment() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const personByID: any = usePersonByIdCard(params.idCliente);
  const pm = Number(searchParams.get("pm"));
  const accessCode = searchParams.get("accessCode");
  const ticket = useTicket( Number(params.idCobro), accessCode || "");

  if (ticket.isLoading || personByID.isLoading) {
    return <Loading text="Cargando cobro..." />;
  }

  if (personByID.isError) {
    navigate("/pagar");
    return null;
  }

  if (ticket.isError || !ticket.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar cobro" />
        </div>
      </div>
    );
  }

  if (ticket.data.invoiceStatus > 0) {
    navigate(
      `/pagar/${ticket.data.idCard}/t/${ticket.data.invoiceID}/resumen?accessCode=${ticket.data.accessCode}`
    );
    return null;
  }

  if (!pm || pm !== 1) {
    return <InvalidPaymentMethod ticket={ticket.data} />;
  }

  if (pm === 1) {
    return <CompensarForm ticket={ticket.data} />;
  }

  return null;
}

export default ProcessPayment;
