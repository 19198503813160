interface Props {
  text: string;
}

function EmptyContent({ text }: Props) {
  return (
    <div className="w-full flex justify-center items-center h-[200px]">
      <h3 className="text-lg font-normal">{text}</h3>
    </div>
  );
}

export default EmptyContent;
