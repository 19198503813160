// Custom queries imports
import { useCardTypes } from "../../../../features/card_types/useCardTypes";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { FormDataCreateInvoice } from "../../../../features/invoices/types";

function DataPersonDetails() {
  const cardTypes = useCardTypes();
  const methods = useFormContext<FormDataCreateInvoice>();
  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
      <CustomLabel
        label="Tipo documento"
        id="tipoDocumento"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled
            className="input-base pr-[40px] overflow-hidden appearance-none"
            value={methods.watch("person.idCardType")}
          >
            {cardTypes.data?.map((cardType) => (
              <option value={cardType} key={cardType}>
                {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                {CARD_TYPES_INFO[cardType]?.description}
              </option>
            ))}
          </select>
        </div>
      </CustomLabel>

      <CustomLabel
        label="No. De documento"
        id="nroDocumento"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <input
          type="number"
          disabled
          placeholder="No. De documento"
          className="input-base"
          value={methods.watch("person.idCardValue")}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombres"
        id="nombres"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <input
          type="text"
          disabled
          maxLength={20}
          placeholder="Nombres"
          className="input-base"
          value={methods.watch("person.firstName")}
        />
      </CustomLabel>

      <CustomLabel
        label="Apellidos"
        id="apellidos"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <input
          type="text"
          disabled
          maxLength={20}
          placeholder="Apellidos"
          className="input-base"
          value={methods.watch("person.lastName")}
        />
      </CustomLabel>

      <CustomLabel
        label="Email"
        id="email"
        required={false}
        className="text-sm sm:col-span-4"
      >
        <input
          type="text"
          disabled
          placeholder="nombre@correo.com"
          className="input-base"
          value={methods.watch("person.email")}
        />
      </CustomLabel>
    </div>
  );
}
export default DataPersonDetails;
