// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PlanStore } from "./types";

export function usePlansByStore(id?: number) {
  async function plansByStore(id?: number): Promise<PlanStore[]> {
    const response = await api.get<PlanStore[]>(`admin/stores/${id}/plans`, {
      init: new Date().toISOString(),
      final: new Date().toISOString(),
      includePlanInfo: true,
    });

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["PlansByStore", id],
    queryFn: () => plansByStore(id),
    enabled: !!id,
  });
}
