// React imports
import { useEffect, useState } from "react";

// Hooks (react-router-dom) imports
import { useParams, useLocation } from "react-router-dom";

function Steps() {
  const params = useParams();
  const location = useLocation();
  const [isSmallBreakpoint, setIsSmallBreakpoint] = useState(
    window.innerWidth <= 640
  );

  useEffect(() => {
    let toggleIsSmallBreakpoint = () => {
      setIsSmallBreakpoint(window.innerWidth <= 640);
    };
    window.addEventListener("resize", toggleIsSmallBreakpoint);
    return () => window.removeEventListener("resize", toggleIsSmallBreakpoint);
  }, []);

  const STEPS = [
    {
      name: "Consultar",
      isCurrent: !params.idCliente,
      isCompleted: params.idCliente,
    },
    {
      name: !isSmallBreakpoint ? "Información Personal" : "Inf. Personal",
      isCurrent:
        params.idCliente &&
        !location.pathname.includes("metodos") &&
        !location.pathname.includes("resumen"),
      isCompleted:
        (params.idCliente && location.pathname.includes("metodos")) ||
        location.pathname.includes("procesar") ||
        location.pathname.includes("resumen"),
    },
    {
      name: !isSmallBreakpoint ? "Método de Pago" : "Métodos",
      isCurrent: params.idCliente && location.pathname.includes("metodos"),
      isCompleted:
        location.pathname.includes("procesar") ||
        location.pathname.includes("resumen"),
    },
    {
      name: !isSmallBreakpoint ? "Procesar Pago" : "Procesar",
      isCurrent: params.idCliente && location.pathname.includes("procesar"),
      isCompleted: params.idCliente && location.pathname.includes("resumen"),
    },
    {
      name: "Resumen",
      isCurrent: false,
      isCompleted: params.idCliente && location.pathname.includes("resumen"),
    },
  ];
  return (
    <div className="px-4">
      <div className="transition-all relative w-full my-[37px] flex justify-center">
        <hr className="w-[calc(100%-32px)] h-[2px] border-0 bg-gray-fourth absolute mt-5 mb-0 z-10" />
        <div className="w-full flex justify-between z-20">
          {STEPS.map((step, index) => (
            <div
              key={index}
              className="transition-all flex flex-col items-center"
            >
              <div className="mb-[7px] rounded-full">
                <i
                  className={`text-[26px] sm:text-[32px] before:rounded-full before:bg-white ${
                    !step.isCompleted && !step.isCurrent
                      ? "text-gray-fourth bi bi-circle"
                      : ""
                  } ${step.isCurrent ? "text-red-primary bi bi-circle" : ""} ${
                    step.isCompleted
                      ? "text-red-primary bi bi-record-circle"
                      : ""
                  }`}
                ></i>
              </div>
              <span
                className={`w-full text-[10px] sm:text-xs truncate ... ${
                  !step.isCompleted && !step.isCurrent
                    ? "text-gray-primary"
                    : ""
                } ${step.isCurrent ? "text-red-primary" : ""} ${
                  step.isCompleted ? "text-red-primary font-bold" : ""
                } `}
              >
                {step.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Steps;
