export interface PaymentCommissions {
  id: number;
  servicePlanID: number;
  paymentMethodID: number;
  observations: string;
  commission: number;
  initDate: string;
}

export interface Plan {
  id: number;
  serviceName: string;
  description: string;
  status: number;
  dailyTransactions: number;
  monthlyTransactions: number;
  activeUsers: number;
  paymentCommissions: PaymentCommissions[];
  dimension: number;
  commission: number;
}

export interface PlanStore {
  id: number;
  companyID: number;
  servicePlanID: number;
  initialDate: string;
  finalDate: string;
  plan: Plan;
}

export const defaultValues = {
  id: 0,
  serviceName: "",
  description: "",
  status: 1,
  dailyTransactions: 0,
  monthlyTransactions: 0,
  activeUsers: 10,
  paymentCommissions: [],
  dimension: 0,
  commission: 0,
};
