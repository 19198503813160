// Hooks (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PaymentReceipt } from "./types";

export function usePaymentsById(id: number | undefined, accessCode?: string) {
  async function getPaymentById(
    id?: number,
    accessCode?: string
  ): Promise<PaymentReceipt> {
    const response = await api.get<PaymentReceipt[]>(
      `invoices/payments/${id}`,
      { accessCode, actionType: "0" }
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data[0];
  }

  return useQuery({
    queryKey: ["Payment", id],
    queryFn: () => getPaymentById(id, accessCode),
    enabled: !!id,
  });
}
