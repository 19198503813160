// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PasswordToken } from "./type";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  user: string;
  accessCode: string;
  notificationID: string | number;
}

export function usePasswordTokenByAccessCodeMutation(
  onSuccessMutation?: (data: PasswordToken, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function getPasswordTokenByAccessCode(
    variables: paramsType
  ): Promise<PasswordToken> {
    const response = await api.get<PasswordToken>(
      `admin/passwordtoken/${variables.user}/invoice/${variables.accessCode}`
    );

    if (!response.ok || !response.data) {
      throw response;
    }


    return response.data;
  }

  return useMutation({
    mutationFn: getPasswordTokenByAccessCode,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
