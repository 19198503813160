// React imports
import { useState, useEffect } from "react";

// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import InfiniteListRoster from "../../../infinite_lists/InfiniteListRoster";
import DrawerCreateRoster from "./DrawerCreateRoster";
import DrawerEditRoster from "./DrawerEditRoster";
import Tabs from "../../../shared/Tabs";

// Custom queries imports
import { useRostersInfinite } from "../../../../features/roster/useRosterInfinite";

// Custom types imports
import {
  fetchingModelDefaults,
  RosterModel,
} from "../../../../features/roster/types";
import { Tab } from "../../../shared/Tabs";
import { Roster } from "../../../../features/roster/types";

// Hooks (react-router-dom") imports
import { useParams } from "react-router-dom";

function RosterStore() {
  const params = useParams();
  const paramsRostersInfinite: RosterModel = {
    ...fetchingModelDefaults,
    storeID: Number(params.idStore),
  };
  const [openDrawerCreateRoster, setOpenDrawerCreateRoster] =
    useState<boolean>(false);

  const [openDrawerEditRoster, setOpenDrawerEditRoster] =
    useState<boolean>(false);

  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>(
    undefined
  );

  useEffect(() => {
    if (openDrawerEditRoster === false) {
      setSelectedRoster(undefined);
    }
  }, [openDrawerEditRoster, setSelectedRoster]);

  const roster = useRostersInfinite(paramsRostersInfinite);

  const tabs: Tab[] = [
    {
      label: "General",
      url: `/${params.idUser}/comercios/c/${params.idStore}/`,
    },
    {
      label: "Usuarios",
      url: `/${params.idUser}/comercios/c/${params.idStore}/usuarios/`,
    },
    {
      label: "Plan",
      url: `/${params.idUser}/comercios/c/${params.idStore}/plan/`,
    },
  ];

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Usuarios"
        actionButton={
          <div>
            <button
              onClick={() => setOpenDrawerCreateRoster(true)}
              className="button-primary inline-block w-[90px] sm:w-[108px] text-sm"
            >
              Agregar
            </button>
          </div>
        }
      >
        <Tabs tabs={tabs} />
        <InfiniteListRoster
          roster={roster}
          onSelect={(roster) => {
            setOpenDrawerEditRoster(true);
            setSelectedRoster(roster);
          }}
        />
        <DrawerCreateRoster
          paramsRostersInfinite={paramsRostersInfinite}
          open={openDrawerCreateRoster}
          setOpen={setOpenDrawerCreateRoster}
        />
        {!!selectedRoster && (
          <DrawerEditRoster
            paramsRostersInfinite={paramsRostersInfinite}
            open={openDrawerEditRoster}
            setOpen={setOpenDrawerEditRoster}
            roster={selectedRoster}
          />
        )}
      </ContentWithToggle>
    </div>
  );
}

export default RosterStore;
