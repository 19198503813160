// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Plan } from "./types";

export function usePlans() {
  async function getPlans(): Promise<Plan[]> {
    const response = await api.get<Plan[]>("admin/plans");

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryFn: () => getPlans(),
    queryKey: ["Plans"],
  });
}
