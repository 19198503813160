//Components & Hooks (react-hook-form") imports
import { useForm, FormProvider } from "react-hook-form";

// Custom components imports
import RequestResetPasswordForm from "../../components/forms/request_reset_password/RequestResetPasswordForm";

type FormData = {
  email: string;
};

function RequestResetPassword() {
  const methods = useForm<FormData>();

  return (
    <FormProvider {...methods}>
      <RequestResetPasswordForm />
    </FormProvider>
  );
}

export default RequestResetPassword;
