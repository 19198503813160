// React imports
import { useState } from "react";

// Custom components imports
import InvoiceFilterForm from "../../components/forms/invoice/InvoiceFilterForm";
import InfiniteListPayments from "../../components/infinite_lists/InfiniteListPayments";
import ContentWithToggle from "../../components/shared/ContentWithToggle";

// Hooks (react-router-dom") imports
import { useNavigate, useParams } from "react-router-dom";

// Custom types imports
import { fetchingModelDefaults } from "../../features/tickets/types";

// Custom queries imports
import { useTicketsInfinite } from "../../features/tickets/useTicketsInfinite";

// Hooks & actions imports
import { useAppSelector } from "../../app/hook";

// Hooks & Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Custom types imports
import { PaginatedModelTicket } from "../../features/tickets/types";

// Custom helpers imports
import { formatDateShort } from "../../helpers";

function Cancellations() {
  const [cardTypeSelected, setCardTypeSelected] = useState<number | undefined>(
    undefined
  );
  const currentUser = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const params = useParams();
  const methods = useForm<PaginatedModelTicket>({
    defaultValues: {
      ...fetchingModelDefaults,
      storeID: currentUser.user?.companyID !== -1 ? currentUser.user?.companyID : 0,
      invoiceStatus: 5,
    },
  });

  const tickets = useTicketsInfinite({
    ...methods.watch(),
    customerIdCard:
      cardTypeSelected && methods.watch("customerIdCard")
        ? `00${cardTypeSelected}${methods.watch("customerIdCard")}`
        : "",
  });

  const data = tickets.data?.pages?.flatMap((page) => page.invoices) || [];

  const sinceDate = methods.watch("sinceDate");
  const toDate = methods.watch("toDate");

  return (
    <div className="border-t w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md">
      <ContentWithToggle
        title="Filtros"
        isToogle={true}
        defaultOpenValue={false}
        actionButton={(
          <div className="bg-gray-200 text-gray-800 text-xs px-3 py-1 rounded-full mr-1">
          {sinceDate && formatDateShort(sinceDate)}{" - "}
          {toDate && formatDateShort(toDate)}
        </div>
        )}
      >
        <FormProvider {...methods}>
          <InvoiceFilterForm
            cardTypeSelected={cardTypeSelected}
            setCardTypeSelected={setCardTypeSelected}
            disabledReportMutation={data.length === 0}
          />
        </FormProvider>
      </ContentWithToggle>

      <ContentWithToggle
        onBackButton={() => navigate(`/${params.idUser}/escritorio`)}
        title="Anulaciones"
        isToogle={false}
      >
        <InfiniteListPayments
          tickets={tickets}
          link={(ticket) =>
            `/${ticket.idCard}/transacciones/t/${ticket.invoiceID}?accessCode=${ticket.accessCode}`
          }
        />
      </ContentWithToggle>
    </div>
  );
}

export default Cancellations;
