// Custom components imports
import ResetPasswordForm from "../../components/forms/reset_password/ResetPasswordForm";
import ErrorBoundary from "../../components/ErrorBoundary";

// Hooks & components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Hooks & components (react-router-dom) imports
import { useSearchParams, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Custom types imports
import { PasswordResetRequest } from "../../features/auth/types";

// Custom consts imports
import { REGEX_VALIDATE_MAIL } from "../../app/consts";

function ResetPassword() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const methods = useForm<PasswordResetRequest>({
    defaultValues: {
      maxItems: 0,
      modifiedAfter: new Date().toISOString(),
      expiredBy: new Date().toISOString(),
      vStatus: 0,
      userOrEmail: params.emailUser || "",
      code: token?.replaceAll(" ", "+"),
      returnUrl: "",
      password: "",
      confirmPassword: "",
    },
  });

  if (!REGEX_VALIDATE_MAIL.test(params.emailUser || "")) {
    return (
      <ErrorBoundary
        title="Correo electrónico con formato inválido"
        description="El correo electrónico no tiene un formato inválido, reintente la solicitud."
        buttonAction={
          <Link
            to="/account/requestResetPassword"
            className="w-[270px] button-secondary mx-auto block mt-4"
          >
            Reintentar solicitud
          </Link>
        }
      />
    );
  }

  if (!token) {
    return (
      <ErrorBoundary
        title="Token Requerido"
        description="No se encontró un token en la consulta, el token es requerido para continuar con el procedimiento"
        buttonAction={
          <Link
            to="/account/requestResetPassword"
            className="w-[270px] button-secondary mx-auto block mt-4"
          >
            Reintentar solicitud
          </Link>
        }
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <ResetPasswordForm />
    </FormProvider>
  );
}

export default ResetPassword;
