// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import UpdateUsernameForm from "../../components/forms/settings/UpdateUsernameForm";
import UpdateEmailForm from "../../components/forms/settings/UpdateEmailForm";
import RequestConfirmEmail from "../../components/forms/settings/RequestConfirmEmail";
import ForgotPassword from "../../components/forms/settings/ForgotPassword";
import Tabs from "../../components/shared/Tabs";

// Custom types imports
import { Tab } from "../../components/shared/Tabs";

// Custom helpers imports
import { isStoreSupervisor } from "../../features/auth/helpers";

// Hook (react-router-dom) imports
import { useNavigate, useParams } from "react-router-dom";

// Hooks & actions imports
import { useAppSelector } from "../../app/hook";

function Settings() {
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = useAppSelector((state) => state.auth);

  const tabsStoreSupervisor: Tab[] = [
    { label: "General", url: `/${params.idUser}/configuraciones` },
    {
      label: "Seguridad",
      url: `/${params.idUser}/configuraciones/seguridad`,
      desactive: true,
    },
    {
      label: "Plan",
      url: `/${params.idUser}/configuraciones/plan`,
    },
  ];

  const tabsNormal: Tab[] = [
    { label: "General", url: `/${params.idUser}/configuraciones` },
    {
      label: "Seguridad",
      url: `/${params.idUser}/configuraciones/seguridad`,
      desactive: true,
    },
  ];

  if (!currentUser.user?.roles) return null;

  return (
    <div className="border-t w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        onBackButton={() => navigate(`/${params.idUser}/escritorio`)}
        title="Cofiguraciones"
        isToogle={false}
      >
        <Tabs
          tabs={
            isStoreSupervisor(currentUser.user.roles)
              ? tabsStoreSupervisor
              : tabsNormal
          }
        />
        <div className="w-full flex flex-col divide-y">
          <div className="border-t w-full px-4 py-3 flex justify-between items-center">
            <span className="text-gray-600">Nombre completo</span>
            <span>
              {currentUser.user?.firstName || ""}{" "}
              {currentUser.user?.lastName || ""}
            </span>
          </div>

          <UpdateEmailForm />

          <UpdateUsernameForm />

          <RequestConfirmEmail />

          <ForgotPassword />
        </div>
      </ContentWithToggle>
    </div>
  );
}

export default Settings;
