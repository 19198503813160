// Custom queries imports
import { useConfirmEmail } from "../features/auth/useConfirmEmail";

// Custom components imports
import Loading from "./shared/Loading";
import ErrorBoundary from "./ErrorBoundary";

// Hook (react-router-dom) imports
import { useNavigate } from "react-router-dom";

interface Props {
  email: string;
  token: string;
}

export default function ValidateEmailConfirmationToken({
  email,
  token,
}: Props) {
  const navigate = useNavigate();
  const confirmEmail = useConfirmEmail(email, token);

  if (confirmEmail.isLoading) {
    return (
      <div className="flex items-center flex-col sm:mt-10 w-full mx-auto bg-white max-w-[481px] p-4 rounded-lg">
        <Loading text="Validando token..." />
      </div>
    );
  }

  if (confirmEmail.isError) {
    return (
      <ErrorBoundary
        title="Algo salio mal"
        description={
          <>
            Algo salio mal al intentar confirmar el correo electrónico{" "}
            <b className="text-red-500">{email}</b>
          </>
        }
        buttonAction={
          <button
            onClick={() => navigate(0)}
            className="w-[270px] button-secondary mx-auto block mt-4"
          >
            Reintentar
          </button>
        }
      />
    );
  }

  return (
    <div className="bg-white mx-auto max-w-[481px] rounded-b-lg overflow-hidden">
      <div className="bg-green-500 border-b flex flex-col items-center w-full py-4 rounded-b-lg">
        <i className="text-white drop-shadow-lg text-[50px] text-whitebi bi-check-circle-fill"></i>
        <h2 className="drop-shadow-lg text-white mb-4 text-lg font-bold">
          Correo Electrónico Confirmado
        </h2>
      </div>
      <div className="w-full p-4 bg-white">
        <p className="text-gray-600 text-center">
          El correo electrónico <b className="text-red-500">{email}</b> fue
          confirmado satisfactoriamente
        </p>
        <button
          onClick={() => navigate("/")}
          className="w-[270px] button-secondary mx-auto block mt-4"
        >
          Volver al inicio
        </button>
      </div>
    </div>
  );
}
