import { Ticket } from "../payments/types";
import { StatusIndex } from "../invoices/types";
export const STEP_LIMIT = 5;

export interface TicketModel {
  invoiceID?: number;
  sinceDate?: string;
  toDate?: string;
  customerIdCard?: string;
  storeCityID?: number;
  storeID?: number;
  paymentMethod?: number;
  maximumAmount?: number;
  minimumAmount?: number;
  invoiceStatus: StatusIndex;
}

export interface PaginatedModelTicket extends TicketModel {
  invoicesData: {
    scope: number;
    listReference: string;
    orderBy: number;
    totalAmount: number;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
  };
}

export interface TicketList {
  invoices: Ticket[];
  invoicesData: {
    scope?: number;
    listReference?: string;
    orderBy?: number;
    currentItemsTotalAmount: number;
    totalAmount: number;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
  };
}

const dateOffset = 24 * 60 * 60 * 1000 * 3;
const date30DaysAgo = new Date();
date30DaysAgo.setTime(date30DaysAgo.getTime() - dateOffset);

export const fetchingModelDefaults: PaginatedModelTicket = {
  invoiceID: 0,
  customerIdCard: "",
  storeCityID: 0,
  storeID: 0,
  sinceDate: new Date(date30DaysAgo).toISOString(),
  toDate: new Date().toISOString(),
  paymentMethod: 0,
  maximumAmount: 0,
  minimumAmount: 0,
  invoiceStatus: 0,
  invoicesData: {
    scope: 0,
    listReference: "",
    orderBy: 1,
    totalAmount: 0,
    currentFirstPosition: 0,
    totalItems: 0,
    step: STEP_LIMIT,
  },
};
