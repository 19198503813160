// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import CustomEmailField from "../../../shared/fields/CustomEmailField";
import ContentWithToggle from "../../../shared/ContentWithToggle";

// Custom types imports
import {
  CARD_TYPES_INFO,
  CardTypes as CardTypesEnum,
} from "../../../../features/card_types/types";
import { TAX_REGIME_INFO } from "../../../../features/stores/types";
import { taxRegimeMock } from "../../../../features/stores/mocks";
import { FormDataCreateStore } from "../../../../features/stores/types";

// Custom queries imports
import { useCardTypes } from "../../../../features/card_types/useCardTypes";
import { useProvicesByCountry } from "../../../../features/provinces/useProvicesByCountry";
import { useCitiesByProvince } from "../../../../features/cities/useCitiesByProvince";

// Third helpers imports
import { confirmChange } from "../../../../features/auth/helpers";

// (react-hook-form) imports
import { FormProvider, UseFormReturn } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

interface Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  methods: UseFormReturn<FormDataCreateStore, any>;
  provinceSelected: number | undefined;
  setProvinceSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function DataStoreForm({ step, setStep, methods, provinceSelected, setProvinceSelected }: Props) {
  const cardTypes = useCardTypes();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const provinces = useProvicesByCountry(170);
  const cities = useCitiesByProvince(provinceSelected);

  const onSubmit = methods.handleSubmit(() => {
    setStep(step + 1);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ContentWithToggle title="Información del comercio" isToogle={false}>
          <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
            <CustomLabel
              label="Tipo documento"
              id="tipoDocumento"
              required={false}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  value={CardTypesEnum.numeroIdentificacionTributaria}
                  disabled
                  className="input-base appearance-none"
                >
                  {cardTypes.data?.map((cardType) => (
                    <option value={cardType} key={cardType}>
                      {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                      {CARD_TYPES_INFO[cardType]?.description}
                    </option>
                  ))}
                </select>
              </div>
            </CustomLabel>

            <CustomLabel
              label="No. de documento"
              id="taxIDNumber"
              required={true}
              className="text-sm sm:col-span-2"
              name="taxIDNumber"
            >
              <input
                type="number"
                min={0}
                placeholder="No. De documento"
                className={`input-base ${
                  methods.formState.errors.taxIDNumber ? "input-error" : ""
                } ${
                  !methods.formState.errors.taxIDNumber &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("taxIDNumber", {
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 12,
                    message: "El número máximo de caracteres es 12",
                  },
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Razon social"
              id="storeName"
              required={true}
              className="text-sm sm:col-span-4"
              name="storeName"
            >
              <input
                type="text"
                placeholder="Razon social"
                maxLength={60}
                className={`input-base ${
                  methods.formState.errors.storeName ? "input-error" : ""
                } ${
                  !methods.formState.errors.storeName &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("storeName", {
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 60,
                    message: "El número máximo de caracteres es 60",
                  },
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Régimen tributario"
              id="taxCondition"
              required={true}
              className="text-sm sm:col-span-2"
              name="taxCondition"
            >
              <div className="w-full flex items-center relative">
                <select
                  className={`input-base appearance-none ${
                    methods.formState.errors.taxCondition ? "input-error" : ""
                  } ${
                    !methods.formState.errors.taxCondition &&
                    methods.formState.isSubmitted
                      ? "input-valid"
                      : ""
                  } `}
                  {...methods.register("taxCondition", {
                    required: "Este campo es requerido",
                    valueAsNumber: true,
                  })}
                >
                  <option value="">Seleccionar</option>
                  {taxRegimeMock?.map((taxRegime) => (
                    <option value={taxRegime} key={taxRegime}>
                      {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                      {TAX_REGIME_INFO[taxRegime]?.description}
                    </option>
                  ))}
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>

            <CustomLabel
              label="Departamento"
              id="province"
              required={false}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  disabled={provinces.isLoading || !provinces.data}
                  className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                  value={provinceSelected}
                  onChange={(event) =>
                    setProvinceSelected(Number(event.currentTarget.value))
                  }
                >
                  <option value="0">Todas</option>
                  {provinces.data?.map((provice) => (
                    <option value={provice.id} key={provice.id}>
                      {provice?.value}
                    </option>
                  ))}
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>

            <CustomLabel
              label="Ciudad"
              id="cityID"
              required={true}
              className="text-sm sm:col-span-2"
              name="cityID"
            >
              <div className="w-full flex items-center relative">
                <select
                  disabled={
                    provinces.isLoading || cities.isLoading || !cities.data
                  }
                  className="input-base pr-[40px] overflow-hidden appearance-none"
                  {...methods.register("cityID", {
                    required: "Este campo es requerido",
                    valueAsNumber: true,
                  })}
                >
                  <option value="">Todas</option>
                  {cities.data?.map((city) => (
                    <option value={city.id} key={city.id}>
                      {city?.value}
                    </option>
                  ))}
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>

            <CustomLabel
              label="Teléfono"
              id="phone"
              required={true}
              className="text-sm sm:col-span-2"
              name="phone"
            >
              <input
                type="number"
                placeholder="Télefono"
                className={`input-base ${
                  methods.formState.errors.phone ? "input-error" : ""
                } ${
                  !methods.formState.errors.phone &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("phone", {
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 10,
                    message: "El número máximo de caracteres es 10",
                  },
                  minLength: {
                    value: 10,
                    message: "El número minimo de caracteres es 10",
                  },
                })}
              />
            </CustomLabel>

            <CustomEmailField
              placeholder="nombre@correo.com"
              id="email"
              required={true}
              label="Correo electrónico"
              name="email"
              classNameLabel="text-sm sm:col-span-4"
              onChange={(e: any) => {
                methods.setValue("email", e.target.value);
              }}
            />

            <CustomLabel
              label="Dirección"
              id="address"
              required={true}
              className="text-sm sm:col-span-4"
              name="address"
            >
              <input
                type="text"
                placeholder="Dirección"
                className={`input-base ${
                  methods.formState.errors.address ? "input-error" : ""
                } ${
                  !methods.formState.errors.address &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("address", {
                  required: "Este campo es requerido",
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Segunda dirección"
              id="address2"
              required={false}
              className="text-sm sm:col-span-4"
              name="address2"
            >
              <input
                type="text"
                placeholder="Dirección"
                className={`input-base ${
                  methods.formState.errors.address2 ? "input-error" : ""
                } ${
                  !methods.formState.errors.address2 &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("address2")}
              />
            </CustomLabel>
            <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
              <button
                className="button-secondary text-sm py-2 px-4"
                onClick={() => {
                  confirmChange(
                    "¿Estas seguro de cancelar estos cambios?",
                    "Al cancelar, todos los cambios seran ignorados",
                    () => {
                      navigate(`/${currentUser.user?.id}/comercios`);
                    }
                  );
                }}
                type="button"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="button-primary text-sm py-2 px-4"
              >
                Siguiente
              </button>
            </div>
          </div>
        </ContentWithToggle>
      </form>
    </FormProvider>
  );
}
export default DataStoreForm;
