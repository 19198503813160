// React imports
import React from "react";

interface Props {
  title: string;
  description: React.ReactNode | string;
  buttonAction?: React.ReactNode;
}

function ErrorBoundary({ title, description, buttonAction }: Props) {
  return (
    <div className="bg-white mx-auto max-w-[481px] rounded-b-lg overflow-hidden">
      <div className="bg-red-500 border-b flex flex-col items-center w-full py-4 rounded-b-lg">
        <i className="text-white drop-shadow-lg text-[50px] text-whitebi bi-x-circle-fill"></i>
        <h2 className="drop-shadow-lg text-white mb-4 text-lg font-bold">
          {title}
        </h2>
      </div>
      <div className="w-full p-4 bg-white">
        <p className="text-gray-600 text-center">{description}</p>
        {buttonAction}
      </div>
    </div>
  );
}

export default ErrorBoundary;
