// React imports
import { Dispatch, SetStateAction } from "react";

// Components (react-router-dom) imports
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Logos imports
import imagotipo from "../assets/imagotipo.png";
import logotipo from "../assets/logotipo.png";

// Hooks & actions imports
import { useAppSelector } from "../app/hook";
import { ROLES } from "../features/users/types";

interface Prop {
  isOpenMenu: boolean;
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

function Logo({ isOpenMenu, setIsOpenMenu }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);
  const params = useParams();
  return (
    <div className="flex items-center h-full mr-auto">
      <button
        className="xl:hidden mr-3"
        onClick={() => setIsOpenMenu((preventState) => !preventState)}
      >
        <i
          className={`text-black text-[32px] bi ${
            isOpenMenu ? "bi-x-lg" : "bi-list"
          }`}
        ></i>
      </button>
      <Link
        to={
          currentUser.isAuthenticated && currentUser.user?.roles
            ? currentUser.user.roles === ROLES.Customer
              ? `/pagar/${params.idCliente}/cobros`
              : `/${params.idUser}/escritorio`
            : "/ingresar"
        }
      >
        <img
          className="w-8 h-8 xl:hidden"
          src={imagotipo}
          alt="Imagotipo de Pagos Multired"
        />
        <img
          className="hidden xl:block w-auto h-[38px]"
          src={logotipo}
          alt="Imagotipo de Pagos Multired"
        />
      </Link>
    </div>
  );
}
export default Logo;
