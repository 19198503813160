// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { NewlyRegisteredUser, UserRegister } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  user: UserRegister;
  notificationID: string | number;
}

export function useCreateUserMutation(
  onSuccessMutation?: (
    data: NewlyRegisteredUser,
    variables: paramsType
  ) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function createUser(params: paramsType): Promise<NewlyRegisteredUser> {
    const response = await api.post<NewlyRegisteredUser>(
      "account/registeruser",
      params.user
    );

    if (!response.ok || !response.data) {
      console.log("error en registro de usuario", response.ok, response.data);
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: createUser,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
