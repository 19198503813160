// ** React query Imports
import { useInfiniteQuery } from "@tanstack/react-query";

// ** Config apisauce
import api from "../../app/api";

// ** Helpers imports
import { timeout } from "../../helpers";

// ** Types Imports
import { PaginatedModelTicket, TicketList } from "./types";
import { STEP_LIMIT } from "./types";

type PageParamType = {
  page: number;
  size: number;
  currentFirstPosition: number;
};

const defaultPageParam = {
  page: 1,
  size: STEP_LIMIT,
  currentFirstPosition: 0,
};

export function useTicketsInfinite(params: PaginatedModelTicket) {
  async function getTickets(
    pageParam: PageParamType,
    params: PaginatedModelTicket
  ): Promise<TicketList> {
    await timeout(1000);
    const response = await api.post<TicketList>("invoices/tickets/paginated", {
      ...params,
      invoicesData: {
        ...params.invoicesData,
        currentFirstPosition: pageParam.currentFirstPosition,
        step: pageParam.size,
      },
    });
    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  const query = useInfiniteQuery({
    queryKey: ["TicketsInfinite", ...Object.values(params)],
    queryFn: ({ pageParam = defaultPageParam }) =>
      getTickets(pageParam, params),

    getNextPageParam: (lastPage, allPages) => {
      const totalItems = lastPage.invoicesData.totalItems;
      const totalPages = Math.ceil(totalItems / STEP_LIMIT);
      const penultimatePage = totalPages - 1;
      const items = allPages.flatMap((page) => page.invoices) || [];
      const offset = totalItems % STEP_LIMIT || STEP_LIMIT;
      const isPenultimatePage = allPages.length === penultimatePage;
      if (items.length === totalItems) {
        return undefined;
      } else {
        return {
          page: allPages.length + 1,
          size: isPenultimatePage ? offset : STEP_LIMIT,
          currentFirstPosition: items.length + 1,
        };
      }
    },
  });
  return query;
}
