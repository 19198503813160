interface Props {
  text: string;
}

function Loading({ text }: Props) {
  return (
    <div className="w-full flex justify-center items-center flex-col h-[200px]">
      <i className="animate-spin text-[64px] text-red-primary bi bi-arrow-repeat"></i>
      <span>{text}</span>
    </div>
  );
}

export default Loading;
