export const STEP_LIMIT = 5;

export interface Customer {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  birthCountryID?: number;
  birthDate?: string;
  onModified: string;
  vStatus: number;
  idCard: string;
  email: string;
  phoneNumber: string;
  phoneCountry: number;
  phoneNumberValue: string;
  idCardType: number;
  idCardValue: string;
}

export interface CustomerByModel {
  storeCityID?: number;
  storeID?: number;
  lastName?: string;
  idCard?: string;
  minimumDate?: string;
  customersData: {
    scope: number;
    listReference: string;
    recalculate?: boolean;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
    orderBy: number;
  };
}

export interface CustomerList {
  customers: Customer[];
  customersData: {
    scope: number;
    listReference: string;
    recalculate: boolean;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
    orderBy: number;
  };
}

export const fetchingModelDefaults: CustomerByModel = {
  storeCityID: 0,
  storeID: 0,
  lastName: "",
  idCard: "",
  minimumDate: undefined,
  customersData: {
    scope: 0,
    listReference: "",
    currentFirstPosition: 0,
    totalItems: 0,
    step: STEP_LIMIT,
    orderBy: 1,
  },
};

export type EditCustomerFormData = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  birthCountryID?: number;
  birthDate?: string;
  onModified: string;
  vStatus: number;
  idCard: string;
  email: string;
  phoneNumber: string;
  phoneCountry: number;
  phoneNumberValue: string;
  idCardType: number;
  idCardValue: string;
};
