// React imports
import { Dispatch, SetStateAction } from "react";

// Components (react-router-dom) imports
import { Link } from "react-router-dom";

// Custom helpers imports
import { roleDescription } from "../../../../features/auth/helpers";

// Hooks, Actions & States Redux imports
import { useAppSelector, useAppDispatch } from "../../../../app/hook";
import {
  logout,
  changeIsLoadingUser,
} from "../../../../features/auth/authSlice";

// Third helpers imports
import { toast } from "react-toastify";

interface Prop {
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

function AdminActions({ setIsOpenMenu }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  if (!currentUser.isAuthenticated || !currentUser.user?.roles) return null;

  return (
    <div className="cursor-pointer h-full flex items-center">
      <Link to={`/${currentUser.user?.id}/configuraciones`}>
        <div className="hidden xl:flex items-center flex-col">
          <span className="mx-3 font-medium text-lg">
            {currentUser.user?.firstName} {currentUser.user?.lastName}
          </span>
          <span className="text-xs text-white bg-blue-500 py-1 px-2 rounded-full truncate">
            {roleDescription(currentUser.user.roles)}
          </span>
        </div>
      </Link>
      <Link
        to="/ingresar"
        className="ml-[40px]"
        onClick={() => {
          dispatch(changeIsLoadingUser(true));
          dispatch(logout());
          setIsOpenMenu(false);
          toast.info("Cierre de sesión satisfactorio");
        }}
      >
        <i className="text-[26px] bi bi-box-arrow-right"></i>
      </Link>
    </div>
  );
}

export default AdminActions;
