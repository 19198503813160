import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div className="w-full flex flex-col items-center h-screen">
      <h3 className="mt-5 text-4xl font-bold">404</h3>
      <p className="text-lg font-normal text-gray-500">Página No Encontrada</p>
      <Link
        to="/"
        className="mt-5 button-primary text-sm w-full sm:w-[270px] disabled:pointer-events-none"
      >
        Ir al inicio
      </Link>
    </div>
  );
}

export default NotFound;
