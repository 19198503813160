import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hook";

// Custom Types imports
import { Ticket } from "../../features/payments/types";
import { PAYMENT_ENTITY_INFO } from "../../features/payment_methods/types";

// Custom helpers imports
import { getDescriptionEntity, formatDate, formatAmount } from "../../helpers";

interface Prop {
  ticket: Ticket;
  link: string;
}

function PaymentCard({ ticket, link }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);
  const total = ticket.amount;

  if (!currentUser.user?.roles) return null;

  const descriptionEntity = getDescriptionEntity(
    ticket,
    currentUser.user.roles
  );

  return (
    <Link
      to={link}
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
    >
      <div className="col-span-1 flex flex-col">
        <b className="text-lg truncate">{descriptionEntity}</b>
        <div className="mt-1 flex flex-col">
          <span className="text-sm">Nro. de factura</span>
          <b className="italic text-gray-500 truncate">#{ticket?.storeInvoice}</b>
        </div>
      </div>
      <div className="col-span-1 flex flex-col items-end">
        <div className="mt-1 flex flex-col">
          <span className="text-sm">Total</span>
          <b className="text-lg">{formatAmount(total)}</b>
        </div>
        <div className="flex items-center">
          <img
            className="w-[30px] h-[30px] rounded-[4px] shadow-custom-sm border border-primary"
            src={PAYMENT_ENTITY_INFO[ticket.paymentMethod].logo}
            alt="icono de bancolombia"
          />
          <div className="flex flex-col ml-2 items-end">
            <span className="text-sm">
              {PAYMENT_ENTITY_INFO[ticket.paymentMethod].shortDescription}
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <span className="text-sm mr-auto">Fecha de emisión</span>
        <span className="mr-auto italic text-red-500">
          {formatDate(ticket.date)}
        </span>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </Link>
  );
}

export default PaymentCard;
