// React imports
import { useState } from "react";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import ContentWithToggle from "../../../shared/ContentWithToggle";
import { QrScanner } from "@yudiel/react-qr-scanner";

// Hooks (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

// (react-toastify) imports
import { toast } from "react-toastify";

// Consts imports
import { defaultValues } from "../../../../features/payments/types";

// Custom queries imports
import { useCreatePaymentMutation } from "../../../../features/payments/useCreatePaymentMutation";

// Custom types imports
import { Payment } from "../../../../features/payments/types";
import { PAYMENT_ENTITY_INFO } from "../../../../features/payment_methods/types";
import { Ticket } from "../../../../features/payments/types";

interface Props {
  ticket: Ticket;
}

function CompensarForm({ ticket }: Props) {
  const [openQRScanner, setOpenQRScanner] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const methods = useForm<Payment>({
    defaultValues: {
      ...defaultValues,
      invoiceID: ticket.invoiceID,
      amount: ticket.amount,
      paymentMethod: Number(searchParams.get("pm")),
      idCard: ticket.idCard,
      invoiceStatus: ticket.invoiceStatus,
    },
  });
  const paymentEntity = PAYMENT_ENTITY_INFO[Number(searchParams.get("pm"))];

  const payInvoiceMutation = useCreatePaymentMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        autoClose: 5000,
        type: "success",
        render: "Cobro procesado satisfactoriamente",
      });
      navigate(
        `/pagar/${params.idCliente}/t/${
          params.idCobro
        }/resumen?accessCode=${searchParams.get("accessCode")}`
      );
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Hubo un error, al intentar procesar cobro",
        autoClose: 5000,
      });
      if (error.status === null) navigate(0);
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Procesando cobro...");
    payInvoiceMutation.mutate({
      payment: data,
      notificationID,
    });
  });

  return (
    <div className="w-full border-t">
      <ContentWithToggle
        title={paymentEntity.description}
        onBackButton={() =>
          navigate(
            `/pagar/${ticket.idCard}/c/${ticket.invoiceID}/metodos?accessCode=${ticket.accessCode}`
          )
        }
      >
        <div className="p-4">
          <p className="text-gray-primary my-4 px-4">{paymentEntity.caption}</p>
          <FormProvider {...methods}>
            <form
              className="flex flex-col justify-center items-center flex-wrap"
              onSubmit={onSubmit}
            >
              <CustomLabel
                label="Token"
                id="token"
                required={true}
                className="text-sm w-full"
                name="token"
              >
                {openQRScanner && (
                  <div className="fixed top-0 left-0 w-full h-screen flex justify-center items-center z-50 bg-black/50">
                    <QrScanner
                      containerStyle={{ width: "450px" }}
                      onDecode={(result) => {
                        setOpenQRScanner(false);
                        const code = result;
                        if (!code.includes("COMPENSAR")) {
                          return toast.error("QR no valido");
                        }
                        const firstPosition = code.indexOf("106") + 3;
                        const token = code.slice(
                          firstPosition,
                          firstPosition + 6
                        );
                        methods.setValue("token", token);
                        toast.info("QR escaneado");
                      }}
                      onError={(error) => console.log(error?.message)}
                    />
                  </div>
                )}
                <div className="relative w-full flex items-center">
                  <input
                    type="text"
                    placeholder="Token"
                    className={`input-base ${
                      methods.formState.errors.token ? "input-error" : ""
                    }`}
                    {...methods.register("token", {
                      required: "Este campo es requerido",
                    })}
                  />
                  <button
                    type="button"
                    className="w-[30px] h-[30px] text-white bg-black rounded-full absolute z-10 right-[13px]"
                    onClick={() => setOpenQRScanner(true)}
                  >
                    <i className="bi bi-qr-code"></i>
                  </button>
                </div>
              </CustomLabel>
              <button className="w-[270px] button-primary" type="submit">
                Procesar
              </button>
            </form>
          </FormProvider>
        </div>
      </ContentWithToggle>
    </div>
  );
}
export default CompensarForm;
