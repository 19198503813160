// Logos payment methods imports
import compensarLogo from "../../assets/compensar.webp";

export interface PaymentMethodN {
  id: number;
  paymentMethodName: string;
  description: string;
  ivaCommission: number;
  retention: boolean;
  status: number;
}

export enum paymentMethodEntityKeys {
  masterCard = "masterCard",
  visa = "visa",
  americanExpress = "americanExpress",
  nequi = "nequi",
}

export enum paymentMethodEntityEnum {
  unknown = 0,
  compensar = 1,
}

export interface paymentMethodEntity {
  logo: string;
  name: string;
  IDPaymentMethod: number;
}

export interface paymentMethod extends paymentMethodEntity {
  nroProduct: string;
}

export const PAYMENT_ENTITY_INFO: Record<
  number,
  {
    description: string;
    shortDescription: string;
    logo: string;
    caption?: string;
  }
> = {
  [paymentMethodEntityEnum.unknown]: {
    description: "Compensar",
    shortDescription: "Compensar",
    logo: compensarLogo,
    caption: "Ingresa el token que te provee Billetera Móvil Compensar",
  },
  [paymentMethodEntityEnum.compensar]: {
    description: "Compensar",
    shortDescription: "Compensar",
    logo: compensarLogo,
    caption: "Ingresa el token que te provee Billetera Móvil Compensar",
  },
};
