// Custom components imports
import Tabs from "../shared/Tabs";
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import InvoiceCard from "../cards/InvoiceCard";
import EndMessageInfiniteList from "../shared/EndMessageInfiniteList";
import LoaderInfiniteList from "../shared/LoaderInfiniteList";

// Components (react-infinite-scroll-component) imports
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";
import { UseInfiniteQueryResult } from "@tanstack/react-query";

// Custom types imports
import { Tab } from "../shared/Tabs";
import { TicketList } from "../../features/tickets/types";
import { ROLES } from "../../features/users/types";
import { Ticket } from "../../features/payments/types";

// States & actions Redux imports
import { useAppSelector } from "../../app/hook";

// Custom helpers import
import { formatAmount } from "../../helpers";

interface Props {
  tickets: UseInfiniteQueryResult<TicketList, unknown>;
  link: (ticket: Ticket) => string;
}

function InfiniteListInvoices({ tickets, link }: Props) {
  const params = useParams();
  const currentUser = useAppSelector((state) => state.auth);

  const tabs: Tab[] = [
    { label: "Pendientes", url: `/${params.idUser}/cobros` },
    { label: "Eiminados", url: `/${params.idUser}/eliminados` },
    { label: "Rechazados", url: `/${params.idUser}/rechazados` },
  ];

  const data = tickets.data?.pages?.flatMap((page) => page.invoices) || [];

  if (tickets.isLoading) {
    return <Loading text="Cargando cobros..." />;
  }

  if (tickets.isError || !tickets.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar cobros" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full border-t">
        {currentUser.user?.roles !== ROLES.Customer && <Tabs tabs={tabs} />}
        <div className="w-full">
          <EmptyContent text="No hay cobros" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      {currentUser.user?.roles !== ROLES.Customer && <Tabs tabs={tabs} />}
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Total de movimientos</span>
        <div className="w-1/2 text-end">
          <b>
            {tickets.data.pages[0].invoicesData.totalAmount &&
              formatAmount(tickets.data.pages[0].invoicesData.totalAmount)}
          </b>
        </div>
      </div>
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de cobros</span>
        <div className="w-1/2 text-end">
          <b>{tickets.data.pages[0].invoicesData.totalItems}</b>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.length || 0}
        next={() => tickets.fetchNextPage()}
        hasMore={!!tickets.hasNextPage}
        loader={<LoaderInfiniteList />}
        endMessage={<EndMessageInfiniteList message="Carga completada" />}
      >
        <div className="divide-y">
          {data.map((ticket, index) => (
            <InvoiceCard ticket={ticket} key={index} link={link(ticket)} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteListInvoices;
