// React imports
import { useRef } from "react";

// Hooks & Components (react-hook-form) imports
import { useForm } from "react-hook-form";

// Hooks & actions imports
import { useAppSelector, useAppDispatch } from "../../../app/hook";
import { logout } from "../../../features/auth/authSlice";

// Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom queries imports
import { useUpdateUsernameMutation } from "../../../features/auth/useUpdateUsernameMutation";

function UpdateUsernameForm() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth);
  const inputSubmitRef = useRef<HTMLInputElement | null>(null);

  const updateUsernameMutation = useUpdateUsernameMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        type: "success",
        isLoading: false,
        autoClose: 5000,
        render: "Nombre de usuario cambiado satisfactoriamente",
      });
      dispatch(logout());
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        autoClose: 5000,
        render:
          error.data?.description ||
          "¡Algo salio mal!, al intentar cambiar nombre de usuario",
      });
    }
  );

  const methods = useForm<{ username: string }>({
    defaultValues: {
      username: currentUser.user?.userName || "",
    },
  });

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Cambiando nombre de usuario...");
    updateUsernameMutation.mutate({
      username: data.username,
      notificationID,
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="w-full px-4 py-3 flex justify-between items-center flex-wrap">
        <span className="text-gray-600">Nombre de usuario</span>
        <div className="sm:mt-0 mt-2 w-full sm:w-[60%] flex justify-end items-center">
          <input
            className="input-base mr-2 text-sm"
            type="text"
            placeholder="Nombre de usuario"
            {...methods.register("username")}
          />
          <button
            type="button"
            onClick={() =>
              confirmChange(
                "¿Estas seguro de modificar el nombre de usuario?",
                "Al realizar esta modificación de nombre de usuario, la sesión será cerrada automáticamente",
                () => {
                  if (!!inputSubmitRef.current) {
                    inputSubmitRef.current.click();
                  }
                }
              )
            }
            disabled={currentUser.user?.userName === methods.watch("username")}
            className="button-third bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4"
          >
            Cambiar
          </button>
          <input ref={inputSubmitRef} type="submit" className="hidden" />
        </div>
      </div>
    </form>
  );
}
export default UpdateUsernameForm;
