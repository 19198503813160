// React Components
import { Dispatch, SetStateAction } from "react";

// Custom componentes imports
import LinkWithoutSubmenu from "../../../LinkWithoutSubmenu";

// Components () imports
import { Link } from "react-router-dom";

// Third helpers imports
import { toast } from "react-toastify";

// Hook & Components Redux imports
import { useAppSelector, useAppDispatch } from "../../../../app/hook";
import {
  logout,
  changeIsLoadingUser,
} from "../../../../features/auth/authSlice";

interface Props {
  inMenu?: boolean;
  setIsOpenMenu?: Dispatch<SetStateAction<boolean>>;
}

function LinksCustomer({ inMenu, setIsOpenMenu }: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  return (
    <>
      <LinkWithoutSubmenu
        text="Cobros"
        to={`/pagar/${currentUser.user?.idCard}/cobros`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Rechazados"
        to={`/pagar/${currentUser.user?.idCard}/rechazados`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Transacciones"
        to={`/pagar/${currentUser.user?.idCard}/transacciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Anulaciones"
        to={`/pagar/${currentUser.user?.idCard}/anulaciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Configuraciones"
        to={`/${currentUser.user?.id}/configuraciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <Link
        className="button-secondary p-4 w-full mb-[14px] flex justify-between rounded-none"
        to="/ingresar"
        onClick={() => {
          dispatch(changeIsLoadingUser(true));
          dispatch(logout());
          inMenu && !!setIsOpenMenu && setIsOpenMenu(false);
          toast.info("Cierre de sesión satisfactorio");
        }}
      >
        Cerrar Sesión
        <i className="bi bi-chevron-right"></i>
      </Link>
    </>
  );
}
export default LinksCustomer;
