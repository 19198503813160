// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// Custom types imports
import { Invoice } from "../../../../features/invoices/types";
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { fetchingModelDefaults } from "../../../../features/invoices/types";

// Custom components imports
import Loading from "../../../shared/Loading";
import ErrorLoading from "../../../shared/ErrorLoading";
import EmptyContent from "../../../shared/EmptyContent";
import ContentWithToggle from "../../../shared/ContentWithToggle";
import PublicInvoiceCard from "./PublicInvoiceCard";

// Custom queries imports
import { usePersonByIdCard } from "../../../../features/persons/usePersonByIdCard";
import { useInvoices } from "../../../../features/invoices/useInvoices";

function PublicInvoicesList() {
  const navigate = useNavigate();
  const params = useParams();
  const idCliente = params.idCliente;
  const personByID: any = usePersonByIdCard(idCliente);
  const invoices = useInvoices({
    ...fetchingModelDefaults,
    idCard: idCliente || "",
  });

  if (personByID.isError) {
    navigate("/pagar");
    return null;
  }

  if (invoices.isLoading) {
    return <Loading text="Cargando cobros..." />;
  }

  if (invoices.isError || !invoices.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar cobros" />
        </div>
      </div>
    );
  }

  if (invoices.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay cobros" />
        </div>
      </div>
    );
  }

  return (
    <div className="border-t w-full mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle title="Lista de cobros" isToogle={false}>
        <div className="w-full flex flex-col divide-y">
          <div className="text-sm flex flex-col sm:flex-row sm:justify-center items-center bg-blue-500 text-white py-2 px-4">
            <i className="text-2xl sm:text-lg bi bi-info-circle-fill mr-4"></i>
            <p className="my-2 sm:my-0 text-center w-full sm:text-left sm:w-[calc(100%-200px)]">
              ¿Tienes accesos a tu cuenta?, puedes restablecer tu contraseña en
              cualquier momento
            </p>
            <button
              tabIndex={-1}
              className="mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
              onClick={() => navigate("/account/requestResetPassword")}
            >
              Restablecer
            </button>
          </div>

          <div className="border-t w-full px-4 py-3 flex justify-between items-center">
            <span className="text-gray-600">Nombre completo</span>
            <span>
              {personByID.data?.firstName || ""}{" "}
              {personByID.data?.lastName || ""}
            </span>
          </div>
          <div className="border-t w-full px-4 py-3 flex justify-between items-center">
            <span className="text-gray-600">Documento de identidad</span>
            <span>
              {CARD_TYPES_INFO[personByID.data?.idCardType]?.prefix || ""}{" "}
              {new Intl.NumberFormat("es-CO").format(
                Number(personByID.data?.idCardValue || "")
              )}
            </span>
          </div>
          {invoices.data.reverse().map((invoice: Invoice, index: number) => (
            <PublicInvoiceCard
              invoice={invoice}
              key={index}
              link={`/pagar/${params.idCliente}/c/${invoice.id}?accessCode=${invoice.accessCode}`}
            />
          ))}
        </div>
      </ContentWithToggle>
    </div>
  );
}

export default PublicInvoicesList;
