// Hooks (react-router-dom) imports
import { useNavigate, useParams } from "react-router-dom";

// Third helpers imports
import { confirmChange } from "../../../features/auth/helpers";
import { toast } from "react-toastify";

// Custom hooks Redux imports
import { useAppSelector } from "../../../app/hook";

// Custom types imports
import { Ticket } from "../../../features/payments/types";

// Custom queries imports
import { useRefuseInvoiceMutation } from "../../../features/invoices/useRefuseInvoiceMutation";

interface Prop {
  ticket: Ticket;
}

function InvoiceActions({ ticket }: Prop) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const params = useParams();
  const refuseInvoiceMutation = useRefuseInvoiceMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        type: "success",
        isLoading: false,
        render: "Cobro rechazado",
        autoClose: 5000,
      });
      navigate(
        `/pagar/${params.idCliente}/${
          currentUser.isAuthenticated ? "cobros" : "cobrosPublicos"
        }`
      );
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Hubo un error, al intentar rechazar el cobro",
        autoClose: 5000,
      });
    }
  );

  return (
    <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
      <button
        className="button-secondary text-sm py-2 px-4"
        onClick={() => {
          confirmChange(
            "¿Estas seguro de rechazar este cobro?",
            "Al rechazar este cobro, sera un cambio permanente",
            () => {
              const notificationID = toast.loading("Rechazando cobro...");
              refuseInvoiceMutation.mutate({
                notificationID,
                id: ticket.invoiceID,
              });
            }
          );
        }}
        type="button"
      >
        Rechazar
      </button>
      <button
        onClick={() =>
          navigate(
            `/pagar/${params.idCliente}/c/${ticket.id}/metodos?accessCode=${ticket.accessCode}`
          )
        }
        className="button-primary text-sm py-2 px-4"
      >
        Pagar
      </button>
    </div>
  );
}

export default InvoiceActions;
