// Custom types imports
import { Plan } from "../../../../features/plans/types";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

// Third helpers imports
import { confirmChange } from "../../../../features/auth/helpers";

interface Props {
  plan: Plan;
}

function EditPlanActions({ plan }: Props) {
  const currentUser = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  return (
    <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
      <button
        className="button-secondary text-sm py-2 px-4"
        onClick={() => {
          confirmChange(
            "¿Estas seguro de cancelar esta modificación?",
            "Al cancelar, todos los cambios seran ignorados",
            () => {
              navigate(`/${currentUser?.id}/planes`);
            }
          );
        }}
        type="button"
      >
        Cancelar
      </button>
      <button type="submit" className="button-primary text-sm py-2 px-4">
        Actualizar
      </button>
    </div>
  );
}

export default EditPlanActions;
