// React imports
import { useState } from "react";

// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";
import RequestReport from "./RequestReport";

// Third components imports
import CurrencyInput from "react-currency-input-field";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

// Hooks & Components Redux imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { PaginatedModelTicket } from "../../../features/tickets/types";

// Custom queries imports
import { useProvicesByCountry } from "../../../features/provinces/useProvicesByCountry";
import { useCitiesByProvince } from "../../../features/cities/useCitiesByProvince";
import { useCardTypes } from "../../../features/card_types/useCardTypes";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../features/card_types/types";
import { ROLES } from "../../../features/users/types";

// Hooks & actions imports
import { useAppSelector } from "../../../app/hook";

// Custom helpers imports
import {
  isStoreEmployee,
  isStoreSupervisor,
} from "../../../features/auth/helpers";
import { fetchingModelDefaults } from "../../../../src/features/tickets/types";

interface Props {
  cardTypeSelected: number | undefined;
  setCardTypeSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
  disabledReportMutation: boolean;
}

function InvoiceFilterForm({
  cardTypeSelected,
  setCardTypeSelected,
  disabledReportMutation,
}: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useFormContext<PaginatedModelTicket>();
  const [provinceSelected, setProvinceSelected] = useState<number | undefined>(
    undefined
  );
  const [rageDate, setRageDate] = useState<any>([fetchingModelDefaults?.sinceDate, fetchingModelDefaults?.toDate]);
  const [minimumAmount, setMinimumAmount] = useState<string>("");
  const [maximumAmount, setMaximumAmount] = useState<string>("");

  const provinces = useProvicesByCountry(170);
  const cities = useCitiesByProvince(provinceSelected);
  const cardTypes = useCardTypes();

  if (!currentUser.user?.roles) return null;

  const isStore =
    isStoreEmployee(currentUser.user.roles) ||
    isStoreSupervisor(currentUser.user.roles);

  return (
    <form>
      <div className="border-b w-full flex flex-col divide-y">
        <div className="w-full px-4 py-3 flex flex-wrap justify-between items-center">
          {isStore ||
            (currentUser.user.roles === ROLES.Customer && (
              <>
                <CustomLabel
                  label="Departamento"
                  id="departamento"
                  required={false}
                  className="text-sm w-full sm:w-[48%]"
                >
                  <div className="w-full flex items-center relative">
                    <select
                      disabled={provinces.isLoading || !provinces.data}
                      className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                      value={provinceSelected}
                      onChange={(event) =>
                        setProvinceSelected(Number(event.currentTarget.value))
                      }
                    >
                      <option value="">Todas</option>
                      {provinces.data?.map((provice) => (
                        <option value={provice.id} key={provice.id}>
                          {provice?.value}
                        </option>
                      ))}
                    </select>
                    <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
                  </div>
                </CustomLabel>

                <CustomLabel
                  label="Ciudad"
                  id="storeCityID"
                  required={false}
                  className="text-sm w-full sm:w-[48%]"
                  name="storeCityID"
                >
                  <div className="w-full flex items-center relative">
                    <select
                      disabled={
                        provinces.isLoading || cities.isLoading || !cities.data
                      }
                      className="input-base pr-[40px] overflow-hidden appearance-none"
                      {...methods.register("storeCityID", {
                        valueAsNumber: true,
                      })}
                    >
                      <option value="0">Todas</option>
                      {cities.data?.map((city) => (
                        <option value={city.id} key={city.id}>
                          {city?.value}
                        </option>
                      ))}
                    </select>
                    <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
                  </div>
                </CustomLabel>
              </>
            ))}

          {currentUser.user?.roles !== ROLES.Customer && (
            <>
              <CustomLabel
                label="Tipos de  documento"
                id="tipoDocumento"
                required={false}
                className="text-sm w-full sm:w-[48%]"
              >
                <div className="w-full flex items-center relative">
                  <select
                    className="input-base pr-[40px] overflow-hidden appearance-none"
                    disabled={cardTypes.isLoading || !cardTypes.data}
                    value={cardTypeSelected}
                    onChange={(event) => {
                      setCardTypeSelected(Number(event.currentTarget.value));
                    }}
                  >
                    <option value="">Todos</option>
                    {cardTypes.data?.map((cardType) => (
                      <option value={cardType} key={cardType}>
                        {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                        {CARD_TYPES_INFO[cardType]?.description}
                      </option>
                    ))}
                  </select>
                  <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
                </div>
              </CustomLabel>

              <CustomLabel
                label="No. De documento"
                id="customerIdCard"
                required={false}
                className="text-sm w-full sm:w-[48%]"
                name="customerIdCard"
              >
                <input
                  type="number"
                  disabled={
                    cardTypes.isLoading || !cardTypes.data || !cardTypeSelected
                  }
                  maxLength={12}
                  min={0}
                  placeholder="No. De documento"
                  className="input-base"
                  {...methods.register("customerIdCard")}
                />
              </CustomLabel>
            </>
          )}

          {(methods.watch("invoiceStatus") === 2 ||
            methods.watch("invoiceStatus") === 5) && (
            <CustomLabel
              label="Método de pago"
              id="paymentMethod"
              required={false}
              className="text-sm w-full sm:w-[48%]"
              name="paymentMethod"
            >
              <div className="w-full flex items-center relative">
                <select
                  disabled={
                    methods.watch("invoiceStatus") < 2 ||
                    methods.watch("invoiceStatus") === 3
                  }
                  className="input-base pr-[40px] overflow-hidden appearance-none"
                  {...methods.register("paymentMethod", {
                    valueAsNumber: true,
                  })}
                >
                  <option value="0">Todos</option>
                  <option value="1">Compensar</option>
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>
          )}

          <CustomLabel
            label="Rango de fecha"
            id="date"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <DateRangePicker
              value={rageDate}
              onChange={(value: any) => {
                setRageDate(value);
                if (!value) {
                  methods.setValue("sinceDate", undefined);
                  methods.setValue("toDate", undefined);
                } else {
                  methods.setValue(
                    "sinceDate",
                    new Date(value[0]).toISOString()
                  );
                  methods.setValue("toDate", new Date(value[1]).toISOString());
                }
              }}
            />
          </CustomLabel>

          <CustomLabel
            label="Monto Minimo"
            id="factura"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <CurrencyInput
              className="input-base"
              decimalSeparator=","
              groupSeparator="."
              placeholder="Monto Minimo"
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              prefix="$ "
              value={minimumAmount}
              onValueChange={(value) => {
                setMinimumAmount(value || "");
                methods.setValue("minimumAmount", Number(value || 0));
              }}
            />
          </CustomLabel>

          <CustomLabel
            label="Monto Maximo"
            id="factura"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <CurrencyInput
              className="input-base"
              decimalSeparator=","
              groupSeparator="."
              placeholder="Monto Maximo"
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              prefix="$ "
              value={maximumAmount}
              onValueChange={(value) => {
                setMaximumAmount(value || "");
                methods.setValue("maximumAmount", Number(value || 0));
              }}
            />
          </CustomLabel>
          <RequestReport
            model={methods.watch()}
            disabledReportMutation={disabledReportMutation}
            cardTypeSelected={cardTypeSelected}
          />
        </div>
      </div>
    </form>
  );
}

export default InvoiceFilterForm;
