// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";
import { Person } from "./types";

interface paramsType {
  phone: string;
  notificationID: string | number;
}

export function usePersonByPhoneMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function personByPhone(params: paramsType): Promise<Person[]> {
    const response = await api.get<Person[]>(
      `admin/persons/byphone/${params.phone}`
    );

    if (!response.ok || !response.data || response.data.length === 0) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: personByPhone,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
