// React imports
import { useEffect } from "react";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";

// Custom types imports
import { Roster } from "../../../../features/roster/types";
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { PersonUpdate } from "../../../../features/persons/types";

// Custom queries imports
import { useCardTypes } from "../../../../features/card_types/useCardTypes";

// Hooks & Components (react-hook-form) imports
import { useFormContext } from "react-hook-form";

interface Props {
  roster: Roster;
}

function EditRosterForm({ roster }: Props) {
  const cardTypes = useCardTypes();
  const methods = useFormContext<PersonUpdate>();

  useEffect(() => {
    methods.setValue(
      "phoneNumber",
      `${methods.watch("phoneCountry")}${methods.watch("phoneNumberValue")}`
    );
    methods.setValue("phoneCountry", 170);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch("phoneNumberValue")]);

  useEffect(() => {
    methods.setValue(
      "name",
      `${methods.watch("firstName")} ${methods.watch("lastName")}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch("firstName"), methods.watch("lastName")]);

  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
      <CustomLabel
        label="Tipo documento"
        id="tipoDocumento"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <div className="w-full flex items-center relative">
          <select
            disabled
            className="input-base appearance-none"
            value={roster.idCard[2]}
          >
            {cardTypes.data?.map((cardType) => (
              <option value={cardType} key={cardType}>
                {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                {CARD_TYPES_INFO[cardType]?.description}
              </option>
            ))}
          </select>
        </div>
      </CustomLabel>

      <CustomLabel
        label="No. De documento"
        id="nroDocumento"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <input
          type="number"
          disabled
          placeholder="No. De documento"
          className="input-base"
          value={roster.idCard.slice(3)}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombres"
        id="nombres"
        required={true}
        className="text-sm sm:col-span-2"
        name="firstName"
      >
        <input
          type="text"
          placeholder="Nombres"
          className={`input-base ${
            methods.formState.errors?.firstName ? "input-error" : ""
          } ${
            !methods.formState.errors?.firstName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("firstName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Apellidos"
        id="apellidos"
        required={true}
        className="text-sm sm:col-span-2"
        name="lastName"
      >
        <input
          type="text"
          placeholder="Apellidos"
          className={`input-base ${
            methods.formState.errors?.lastName ? "input-error" : ""
          } ${
            !methods.formState.errors?.lastName && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("lastName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre completo"
        id="name"
        required={false}
        className="text-sm sm:col-span-4"
        name="name"
      >
        <input
          disabled
          type="text"
          placeholder="Nombre completo"
          className="input-base"
          {...methods.register("name")}
        />
      </CustomLabel>

      <CustomLabel
        label="Telefonos"
        id="telefono"
        required={true}
        className="text-sm sm:col-span-2"
        name="phoneNumberValue"
      >
        <input
          type="number"
          placeholder="Télefono"
          className={`input-base ${
            methods.formState.errors?.phoneNumberValue ? "input-error" : ""
          } ${
            !methods.formState.errors?.phoneNumberValue &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("phoneNumberValue")}
        />
      </CustomLabel>

      <CustomLabel
        label="Correo electronico"
        id="email"
        required={true}
        className="text-sm sm:col-span-2"
        name="email"
      >
        <input
          type="text"
          placeholder="Correo electronico"
          className={`input-base ${
            methods.formState.errors?.email ? "input-error" : ""
          } ${
            !methods.formState.errors?.email && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("email", {
            required: "Este campo es requerido",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Formato invalido",
            },
          })}
        />
      </CustomLabel>
    </div>
  );
}

export default EditRosterForm;
