// Custom components imports
import ContentWithToggle from "../../../components/shared/ContentWithToggle";
import CustomPasswordField from "../../../components/shared/fields/CustomPasswordField";
import PasswordValidator from "../../../components/PasswordValidator";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Third helpers imports
import { toast } from "react-toastify";

// Custom types imports
import { PasswordResetRequest } from "../../../features/auth/types";

// Custom helpers imports
import {
  validateMinLength,
  validateContainsUppercase,
  validateContainsNumber,
  validateContainsLowercase,
  validatePasswordConfirmation,
} from "../../../features/auth/helpers";

// Custom queries imports
import { useForgotPasswordMutation } from "../../../features/auth/useForgotPasswordMutation";
import { useResetPasswordMutation } from "../../../features/auth/useResetPasswordMutation";

function ResetPasswordForm() {
  const navigate = useNavigate();
  const methods = useFormContext<PasswordResetRequest>();

  const resetPassword = useResetPasswordMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        autoClose: 5000,
        render: "Contraseña restablecida satisfactoriamente",
      });
      navigate("/ingresar");
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        autoClose: 5000,
        render:
          error.data?.description ||
          "Algo salio mal al intentar restablecer contraseña",
      });
    }
  );

  const forgotPasswordMutation = useForgotPasswordMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        autoClose: 5000,
        render: `Solicitud enviada a ${variables.email}`,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        autoClose: 5000,
        render:
          error.data?.description ||
          "Algo salio mal al intentar enviar solicitud",
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Restableciendo contraseña...");
    resetPassword.mutate({
      data: data,
      notificationID,
    });
  });

  const onSendCode = () => {
    const notificationID = toast.loading("Enviando solicitud...");
    forgotPasswordMutation.mutate({
      email: methods.watch("userOrEmail"),
      notificationID,
    });
  };

  return (
    <div className="flex items-start flex-wrap">
      <div className="mb-5 mx-auto sm:rounded-[18px] w-full max-w-[520px] bg-white sm:mt-[65px] pb-2">
        <form onSubmit={onSubmit}>
          <ContentWithToggle
            onBackButton={() => navigate("/")}
            title="Validar codigo"
          >
            <div className="px-4 py-2 grid grid-cols-1 gap-x-4 gap-y-2">
              <CustomPasswordField
                id="password"
                name="password"
                required={true}
                placeholder="Nueva contraseña"
                label="Nueva contraseña"
                validate={{
                  minLength: (value: string) => validateMinLength(value, 8),
                  hasNumber: (value: string) => validateContainsNumber(value),
                  hasUppercase: (value: string) =>
                    validateContainsUppercase(value),
                  hasLowercase: (value: string) =>
                    validateContainsLowercase(value),
                }}
              />
              <PasswordValidator name="password" />
              <CustomPasswordField
                id="confirmPassword"
                name="confirmPassword"
                required={true}
                placeholder="Confirmar nueva contraseña"
                label="Confirmar nueva contraseña"
                validate={{
                  isConfirmed: (value: string) =>
                    validatePasswordConfirmation(
                      methods.getValues("password"),
                      value
                    ),
                }}
              />
              <p className="text-base text-center text-gray-primary my-2">
                ¿No te a llegado el codigo?
                <button
                  type="button"
                  className="underline ml-2"
                  onClick={onSendCode}
                >
                  solicitar nuevamente
                </button>
              </p>
              <button
                className="w-[270px] button-primary mx-auto"
                type="submit"
              >
                Confirmar
              </button>
            </div>
          </ContentWithToggle>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
