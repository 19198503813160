// Custom types imports
import { Person, PersonUpdate } from "./types";
import { Roster } from "../roster/types";
import { EditCustomerFormData } from "../customers/types";

export function rosterToPersonUpdate(roster: Roster): PersonUpdate {
  return {
    id: roster.id,
    firstName: roster.firstName,
    lastName: roster.lastName,
    name: roster.name,
    idCard: roster.idCard,
    phoneNumber: roster.phoneNumber,
    email: roster.email,
    phoneCountry: roster.phoneCountry,
    phoneNumberValue: roster.phoneNumberValue,
  };
}

export function personToPersonUpdate(person: Person): PersonUpdate {
  return {
    id: person.id,
    firstName: person.firstName,
    lastName: person.lastName,
    name: person.name,
    idCard: person.idCard,
    phoneNumber: person.phoneNumber,
    email: person.email,
    phoneCountry: person.phoneCountry,
    phoneNumberValue: person.phoneNumberValue,
  };
}

export function formDataCustomerToPersonUpdate(formData: EditCustomerFormData): PersonUpdate {
  return {
    id: formData.id,
    firstName: formData.firstName,
    lastName: formData.lastName,
    name: formData.name,
    idCard: formData.idCard,
    phoneNumber: formData.phoneNumber,
    email: formData.email,
    phoneCountry: formData.phoneCountry,
    phoneNumberValue: formData.phoneNumberValue,
  };
}
