// Custom components
import DataPlanForm from "./DataPlanForm";
import CreatePlanActions from "./CreatePlanActions";
import ContentWithToggle from "../../../shared/ContentWithToggle";

// Hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

// Custom types imports

import { Plan, defaultValues } from "../../../../features/plans/types";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks & components (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom queries imports
import { useCreatePlanMutation } from "../../../../features/plans/useCreatePlanMutation";

function CreatePlan() {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);
  const methods = useForm<Plan>({ defaultValues });

  const createPlanMutation = useCreatePlanMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Plan creado",
        autoClose: 5000,
      });

      navigate(`/${currentUser?.id}/planes/p/${data.id}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar crear el plan",
        autoClose: 5000,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Creando plan...");
    createPlanMutation.mutate({ data, notificationID });
  });

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <ContentWithToggle
            title="Crear plan"
            onBackButton={() => navigate(`/${currentUser?.id}/planes`)}
            isToogle={true}
          >
            <DataPlanForm />
          </ContentWithToggle>

          <CreatePlanActions />
        </form>
      </FormProvider>
    </div>
  );
}
export default CreatePlan;
