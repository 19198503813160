// Custom components imports
import RecentCustomersList from "../../components/dashboard/RecentCustomersList";
import RecentPaymentsList from "../../components/dashboard/RecentPaymentsList";
import PaymentSummary from "../../components/dashboard/PaymentSummary";

// Custom queries imports
import { useCustomers } from "../../features/customers/useCustomers";
import { useTickets } from "../../features/tickets/useTickets";

// Custom types imports
import { fetchingModelDefaults as fetchingModelCustomerDefaults } from "../../features/customers/types";
import { fetchingModelDefaults as fetchingModelTicketDefaults } from "../../features/tickets/types";

//Custom hooks Redux imports
import { useAppSelector } from "../../app/hook";


function Dashboard() {
  const currentUser = useAppSelector((state) => state.auth);
  const customers = useCustomers({
    ...fetchingModelCustomerDefaults,
    storeID: currentUser.user?.companyID || 0,
    customersData: { ...fetchingModelCustomerDefaults.customersData, step: 5 },
  });

  const tickets = useTickets({
    ...fetchingModelTicketDefaults,
    invoiceStatus: 2,
    invoicesData: {
      ...fetchingModelTicketDefaults.invoicesData,
      step: 3,
    },
  });

  return (
    <div className="sm:mt-0 mt-4 mx-auto max-w-[980px] w-full grid grid-cols-1 sm:grid-cols-2 sm:py-[50px] gap-x-4 gap-y-4 items-start">
      <div className="w-full flex flex-col gap-y-4">
        <PaymentSummary tickets={tickets} />
        <RecentPaymentsList tickets={tickets} />
      </div>
      <RecentCustomersList customers={customers} />
    </div>
  );
}
export default Dashboard;
