// React imports
import { useEffect } from "react";

//Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import CustomEmailField from "../../../shared/fields/CustomEmailField";
import CustomPasswordField from "../../../shared/fields/CustomPasswordField";
import PasswordValidator from "../../../PasswordValidator";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { CreateUserFormData } from "../../../../features/roster/types";
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { useCardTypes } from "../../../../features/card_types/useCardTypes";

// Custom helpers imports
import {
  validateMinLength,
  validateContainsUppercase,
  validateContainsNumber,
  validateContainsLowercase,
  validatePasswordConfirmation,
} from "../../../../features/auth/helpers";

const DrawerCreateRosterData = () => {
  const methods = useFormContext<CreateUserFormData>();
  const password = methods.watch("user.password");
  const cardTypes = useCardTypes();
  const person = methods.watch("person");

  useEffect(() => {
    if (methods.formState.isDirty) {
      const idCard = `00${person.idCardType}${person.idCardValue}`;
      methods.setValue("person.idCard", idCard);
      methods.setValue("user.idCard", idCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.idCardType, person.idCardValue]);

  useEffect(() => {
    methods.setValue("person.name", `${person.firstName} ${person.lastName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.firstName, person.lastName]);

  useEffect(() => {
    methods.setValue(
      "person.phoneNumber",
      `${person.phoneCountry}${person.phoneNumberValue}`
    );
    methods.setValue("person.phoneCountry", 170);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.phoneNumberValue, person.phoneCountry]);

  return (
    <div className="w-full px-4 py-3 grid sm:grid-cols-2 gap-x-4 gap-y-2 border-b">
      <CustomLabel
        label="Tipo documento"
        id="idCardType"
        required={true}
        className="text-sm"
        name="person.idCardType"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.person?.idCardType ? "input-error" : ""
            } ${
              !methods.formState.errors.person?.idCardType &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("person.idCardType", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            {cardTypes.data?.map((cardType) => (
              <option value={cardType} key={cardType}>
                {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                {CARD_TYPES_INFO[cardType]?.description}
              </option>
            ))}
          </select>
          <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="No. De documento"
        id="idCardValue"
        required={true}
        className="text-sm"
        name="person.idCardValue"
      >
        <input
          type="number"
          placeholder="No. De documento"
          className={`input-base ${
            methods.formState.errors.person?.idCardValue ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.idCardValue &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.idCardValue", {
            required: "Este campo es requerido",
            maxLength: {
              value: 12,
              message: "El número máximo de caracteres es 12",
            },
            minLength: {
              value: 6,
              message: "El número minimo de caracteres es 6",
            },
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombres"
        id="nombres"
        required={true}
        className="text-sm"
        name="person.firstName"
      >
        <input
          type="text"
          maxLength={20}
          placeholder="Nombres"
          className={`input-base ${
            methods.formState.errors.person?.firstName ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.firstName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.firstName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Apellidos"
        id="apellidos"
        required={true}
        className="text-sm"
        name="person.lastName"
      >
        <input
          type="text"
          placeholder="Apellidos"
          maxLength={20}
          className={`input-base ${
            methods.formState.errors.person?.lastName ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.lastName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.lastName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Nombre completo"
        id="name"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <input
          type="text"
          disabled
          placeholder="Nombre completo"
          className="input-base"
          value={`${methods.watch("person.firstName")} ${methods.watch(
            "person.lastName"
          )}`}
        />
      </CustomLabel>

      <CustomLabel
        label="Fecha de nacimiento"
        id="birthDate"
        required={false}
        className="text-sm"
        name="person.birthDate"
      >
        <input
          type="date"
          className={`input-base ${
            methods.formState.errors.person?.birthDate ? "input-error" : ""
          } ${
            !methods.formState.errors.person?.birthDate &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.birthDate", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Telefono"
        id="phoneNumberValue"
        required={false}
        className="text-sm"
        name="person.phoneNumberValue"
      >
        <input
          type="number"
          placeholder="Telefono"
          className={`input-base ${
            methods.formState.errors.person?.phoneNumberValue
              ? "input-error"
              : ""
          } ${
            !methods.formState.errors.person?.phoneNumberValue &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("person.phoneNumberValue", {
            required: "Este campo es requerido",
            maxLength: {
              value: 10,
              message: "El número máximo de caracteres es 10",
            },
            minLength: {
              value: 10,
              message: "El número minimo de caracteres es 10",
            },
          })}
        />
      </CustomLabel>

      <CustomEmailField
        placeholder="nombre@correo.com"
        id="person.email"
        required={true}
        label="Correo electrónico"
        name="person.email"
        classNameLabel="text-sm sm:col-span-2"
      />

      <CustomLabel
        label="Rol del usuario"
        id="role"
        required={true}
        className="text-sm"
        name="roleToAdd"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base pr-[40px] overflow-hidden appearance-none ${
              methods.formState.errors.roleToAdd ? "input-error" : ""
            } ${
              !methods.formState.errors.roleToAdd &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("roleToAdd", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            <option value="2">Empleado</option>
            <option value="4">Supervisor</option>
          </select>
          <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Nombre de usuario"
        id="username"
        required={true}
        className="text-sm"
        name="user.userName"
      >
        <input
          type="text"
          placeholder="Nombre de usuario"
          className={`input-base ${
            methods.formState.errors.user?.userName ? "input-error" : ""
          } ${
            !methods.formState.errors.user?.userName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("user.userName", {
            required: "Este campo es requerido",
            maxLength: {
              value: 20,
              message: "El número máximo de caracteres es 20",
            },
            minLength: {
              value: 8,
              message: "El número minimo de caracteres es 8",
            },
            validate: {
              hasNumber: (value: string) => validateContainsNumber(value),
              hasUppercase: (value: string) => validateContainsUppercase(value),
              hasLowercase: (value: string) => validateContainsLowercase(value),
            },
          })}
        />
      </CustomLabel>

      <CustomPasswordField
        placeholder="Contraseña"
        id="password"
        required={true}
        label="Contraseña"
        name="user.password"
        classNameLabel="text-sm"
        validate={{
          minLength: (value: string) => validateMinLength(value, 8),
          hasNumber: (value: string) => validateContainsNumber(value),
          hasUppercase: (value: string) => validateContainsUppercase(value),
          hasLowercase: (value: string) => validateContainsLowercase(value),
        }}
      />

      <CustomPasswordField
        placeholder="Confirmar contraseña"
        id="confirmPassword"
        required={true}
        label="Confirmar contraseña"
        name="user.confirmPassword"
        classNameLabel="text-sm"
        validate={{
          isConfirmed: (value: string) =>
            validatePasswordConfirmation(value, password),
        }}
      />

      <div className="sm:col-span-2">
        <PasswordValidator name="user.password" inDrawer={true} />
      </div>
    </div>
  );
};
export default DrawerCreateRosterData;
