// React imports
import { ReactElement } from "react";

// State Redux imports
import { useAppSelector } from "../../app/hook";

// Custom types imports
import { ROLES } from "../../features/users/types";

interface Prop {
  children: ReactElement;
}

function PublicRoute({ children }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);
  const isCustomer = currentUser.user?.roles === ROLES.Customer;
  const pathByUserAuthenticated = isCustomer
    ? `/pagar/${currentUser.user?.idCard}/cobros`
    : `/${currentUser.user?.id}/escritorio`;

  if (currentUser.isAuthenticated) {
    window.location.replace(pathByUserAuthenticated);
    return null;
  }
  return children;
}

export default PublicRoute;
