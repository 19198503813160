// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Ticket } from "../payments/types";

export function useTicket(id?: number, accessCode?: string) {
  async function getTicketById(
    id?: number,
    accessCode?: string
  ): Promise<Ticket> {
    const response = await api.get<Ticket>(`invoices/tickets/${id}`, {
      accessCode: accessCode || "",
    });
    if (!response.ok || !response.data) {
      throw response;
    }
    return response.data;
  }

  return useQuery({
    queryKey: ["Ticket", id],
    queryFn: () => getTicketById(id, accessCode),
    enabled: !!id,
  });
}
