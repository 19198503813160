// React imports
import { useEffect } from "react";

// Custom components imports
import ContentWithToggle from "../../shared/ContentWithToggle";
import CustomLabel from "../../shared/fields/CustomLabel";
import EditCustomerActions from "./EditCustomerActions";
import DeletePerson from "../../DeletePerson";

// Custom types imports
import { Person } from "../../../features/persons/types";
import { CARD_TYPES_INFO } from "../../../features/card_types/types";
import { EditCustomerFormData } from "../../../features/customers/types";

// Custom queries imports
import { useCardTypes } from "../../../features/card_types/useCardTypes";
import { useUpdatePersonMutation } from "../../../features/persons/useUpdatePersonMutation";
import { useRemovePersonMutation } from "../../../features/persons/useRemovePersonMutation";

// State Redux imports
import { useAppSelector } from "../../../app/hook";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Hooks & Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Custom helper imports
import { formDataCustomerToPersonUpdate } from "../../../features/persons/helpers";
import {
  isCorporateAdmin,
  isStoreEmployee,
  isStoreSupervisor,
} from "../../../features/auth/helpers";
import { confirmChange } from "../../../features/auth/helpers";

// Third helpers imports
import { toast } from "react-toastify";

interface Props {
  person: Person;
}

function EditCustomerForm({ person }: Props) {
  const cardTypes = useCardTypes();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<EditCustomerFormData>({ defaultValues: person });

  const removePersonMutation = useRemovePersonMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Usuario eliminado`,
        autoClose: 5000,
      });
      navigate(`/${currentUser.user?.id}/clientes`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar eliminar el usuario",
        autoClose: 5000,
      });
    }
  );

  const updatePersonMutation = useUpdatePersonMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Cliente actualizado`,
        autoClose: 5000,
      });
      navigate(`/${currentUser.user?.id}/clientes/c/${variables.data.idCard}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar actualizar el cliente",
        autoClose: 5000,
      });
    }
  );

  useEffect(() => {
    methods.setValue(
      "phoneNumber",
      `${methods.watch("phoneCountry")}${methods.watch("phoneNumberValue")}`
    );
    methods.setValue("phoneCountry", 170);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch("phoneNumberValue")]);

  useEffect(() => {
    methods.setValue(
      "name",
      `${methods.watch("firstName")} ${methods.watch("lastName")}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch("firstName"), methods.watch("lastName")]);

  const onSubmitUpdatePerson = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando información del usuario");
    const personUpdate = formDataCustomerToPersonUpdate(data);
    updatePersonMutation.mutate({
      data: personUpdate,
      notificationID,
    });
  });

  if (!currentUser.user) return null;

  const isStore =
    isStoreEmployee(currentUser.user.roles) ||
    isStoreSupervisor(currentUser.user.roles);

  return (
    <div className="w-full sm:max-w-[681px] mx-auto sm:mt-[40px] px-0">
      <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
        <FormProvider {...methods}>
          <form onSubmit={onSubmitUpdatePerson}>
            <ContentWithToggle
              title={`${methods.watch("firstName")} ${methods.watch(
                "lastName"
              )}`}
              onBackButton={() => navigate(`/${currentUser.user?.id}/clientes`)}
            >
              {(isStoreSupervisor(currentUser.user.roles) ||
                isStoreEmployee(currentUser.user.roles) ||
                isCorporateAdmin(currentUser.user.roles)) && (
                <DeletePerson
                  onDelete={() => {
                    confirmChange(
                      "¿Estas seguro de eliminar este usuario?",
                      "Al eliminar este usuario, el cambio sera permanente",
                      () => {
                        let notificationID = toast.loading(
                          "Eliminando el usuario..."
                        );
                        removePersonMutation.mutate({
                          email: methods.watch("email"),
                          notificationID,
                        });
                      }
                    );
                  }}
                />
              )}
              <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
                <CustomLabel
                  label="Tipo documento"
                  id="tipoDocumento"
                  required={false}
                  className="text-sm sm:col-span-2"
                  name="idCardType"
                >
                  <div className="w-full flex items-center relative">
                    <select
                      disabled
                      className="input-base appearance-none"
                      {...methods.register("idCardType")}
                    >
                      {cardTypes.data?.map((cardType) => (
                        <option value={cardType} key={cardType}>
                          {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                          {CARD_TYPES_INFO[cardType]?.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </CustomLabel>

                <CustomLabel
                  label="No. De documento"
                  id="nroDocumento"
                  required={false}
                  className="text-sm sm:col-span-2"
                  name="idCardValue"
                >
                  <input
                    type="number"
                    disabled
                    placeholder="No. De documento"
                    className="input-base"
                    {...methods.register("idCardValue")}
                  />
                </CustomLabel>

                <CustomLabel
                  label="Nombres"
                  id="nombres"
                  required={isStore ? false : true}
                  className="text-sm sm:col-span-2"
                  name="firstName"
                >
                  <input
                    disabled={isStore}
                    type="text"
                    placeholder="Nombres"
                    className={`input-base ${
                      methods.formState.errors?.firstName ? "input-error" : ""
                    } ${
                      !methods.formState.errors?.firstName &&
                      methods.formState.isSubmitted
                        ? "input-valid"
                        : ""
                    } `}
                    {...methods.register("firstName", {
                      required: "Este campo es requerido",
                    })}
                  />
                </CustomLabel>

                <CustomLabel
                  label="Apellidos"
                  id="apellidos"
                  required={isStore ? false : true}
                  className="text-sm sm:col-span-2"
                  name="lastName"
                >
                  <input
                    disabled={isStore}
                    type="text"
                    placeholder="Apellidos"
                    className={`input-base ${
                      methods.formState.errors?.lastName ? "input-error" : ""
                    } ${
                      !methods.formState.errors?.lastName &&
                      methods.formState.isSubmitted
                        ? "input-valid"
                        : ""
                    } `}
                    {...methods.register("lastName", {
                      required: "Este campo es requerido",
                    })}
                  />
                </CustomLabel>

                <CustomLabel
                  label="Nombre completo"
                  id="name"
                  required={false}
                  className="text-sm sm:col-span-4"
                  name="name"
                >
                  <input
                    disabled
                    type="text"
                    placeholder="Nombre completo"
                    className="input-base"
                    {...methods.register("name")}
                  />
                </CustomLabel>

                <CustomLabel
                  label="Telefonos"
                  id="telefono"
                  required={false}
                  className="text-sm sm:col-span-2"
                  name="phoneNumberValue"
                >
                  <input
                    disabled
                    type="number"
                    placeholder="Télefono"
                    className={`input-base ${
                      methods.formState.errors?.phoneNumberValue
                        ? "input-error"
                        : ""
                    } ${
                      !methods.formState.errors?.phoneNumberValue &&
                      methods.formState.isSubmitted
                        ? "input-valid"
                        : ""
                    } `}
                    {...methods.register("phoneNumberValue")}
                  />
                </CustomLabel>

                <CustomLabel
                  label="Correo electronico"
                  id="email"
                  required={false}
                  className="text-sm sm:col-span-2"
                  name="email"
                >
                  <input
                    disabled
                    type="text"
                    placeholder="Correo electronico"
                    className={`input-base ${
                      methods.formState.errors?.email ? "input-error" : ""
                    } ${
                      !methods.formState.errors?.email &&
                      methods.formState.isSubmitted
                        ? "input-valid"
                        : ""
                    } `}
                    {...methods.register("email", {
                      required: "Este campo es requerido",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Formato invalido",
                      },
                    })}
                  />
                </CustomLabel>
              </div>

              <EditCustomerActions />
            </ContentWithToggle>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default EditCustomerForm;
