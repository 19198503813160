import { useFormContext } from "react-hook-form";

interface Props {
  name: string;
  inDrawer?: boolean;
}

function PasswordValidator({ name, inDrawer }: Props) {
  const {
    formState: { isSubmitted },
    getFieldState,
  } = useFormContext();
  return (
    <div className="w-full mb-[35px]">
      <p className="text-gray-primary mb-3">La contraseña debe tener:</p>
      <div className={`w-full grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-y-[22px] gap-x-[5px]`}>
        <div className="flex items-center text-gray-primary">
          <i
            className={`text-[20px] bi bi-record-circle-fill ${
              isSubmitted &&
              (!getFieldState(name).error ||
                (getFieldState(name).error?.type !== "hasLowercase" &&
                  getFieldState(name).error?.type !== "required"))
                ? "text-red-primary"
                : "text-gray-primary"
            }`}
          ></i>
          <span className="ml-[10px]">Minúscula</span>
        </div>
        <div className="flex items-center text-gray-primary">
          <i
            className={`text-[20px] bi bi-record-circle-fill ${
              isSubmitted &&
              (!getFieldState(name).error ||
                (getFieldState(name).error?.type !== "hasUppercase" &&
                  getFieldState(name).error?.type !== "required"))
                ? "text-red-primary"
                : "text-gray-primary"
            }`}
          ></i>
          <span className="ml-[10px]">Mayúscula</span>
        </div>
        <div className="flex items-center text-gray-primary">
          <i
            className={`text-[20px] bi bi-record-circle-fill ${
              isSubmitted &&
              (!getFieldState(name).error ||
                (getFieldState(name).error?.type !== "hasNumber" &&
                  getFieldState(name).error?.type !== "required"))
                ? "text-red-primary"
                : "text-gray-primary"
            }`}
          ></i>
          <span className="ml-[10px]">Número</span>
        </div>
        <div className="flex items-center text-gray-primary">
          <i
            className={`text-[20px] bi bi-record-circle-fill ${
              isSubmitted &&
              (!getFieldState(name).error ||
                (getFieldState(name).error?.type !== "minLength" &&
                  getFieldState(name).error?.type !== "required"))
                ? "text-red-primary"
                : "text-gray-primary"
            }`}
          ></i>
          <span className="ml-[10px]">8 caracteres</span>
        </div>
      </div>
    </div>
  );
}

export default PasswordValidator;
