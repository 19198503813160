// React imports
import { useState } from "react";

// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Custom helpers imports
import { paginatedModelTicketToTicketModel } from "../../../features/invoices/helpers";
import {
  PaginatedModelTicket,
  TicketModel,
} from "../../../features/tickets/types";

// Custom queries imports
import { useTicketReportMutation } from "../../../features/tickets/useTicketReportMutation";

// Third helpers imports
import { toast } from "react-toastify";

interface Props {
  model: PaginatedModelTicket;
  disabledReportMutation: boolean;
  cardTypeSelected: number | undefined;
}

function RequestReport({
  model,
  disabledReportMutation,
  cardTypeSelected,
}: Props) {
  const [fileFormat, setFileFormat] = useState("0");
  const FILE_FORMATS = ["csv", "xlsx"];
  const TITLES_REPORT = [
    "Reporte de Cobros Pendientes",
    "Reporte de Cobros Eiminados",
    "Reporte de Transacciones",
    "Reporte de Anulaciones de Cliente",
    "Reporte de Anulaciones de Comercio",
  ];

  const downloadReport = (
    data: any,
    model: TicketModel,
    fileFormat: number
  ) => {
    const date = new Date().toLocaleDateString("es-CO", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    const blob = new Blob([data], { type: data.type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${TITLES_REPORT[model.invoiceStatus]} ${date}.${
      FILE_FORMATS[fileFormat]
    }`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  const ticketReportMutation = useTicketReportMutation(
    (data, variables) => {
      downloadReport(data, variables.model, variables.fileFormat);
      toast.update(variables.notificationID, {
        type: "success",
        isLoading: false,
        render: "Reporte generado satisfactoriamente",
        autoClose: 5000,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Algo salio mal, al intentar generar el reporte",
        autoClose: 5000,
      });
    }
  );

  return (
    <div className="border-t flex w-full pt-4 pb-2 items-center justify-between">
      <CustomLabel
        label="Formato de archivo"
        id="fileFormat"
        required={false}
        className="text-sm w-full sm:w-[48%]"
      >
        <div className="w-full flex items-center relative">
          <select
            className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
            value={fileFormat}
            onChange={(event) => setFileFormat(event.currentTarget.value)}
          >
            <option value="0">CSV</option>
            <option value="1">XLSX</option>
          </select>
          <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>
      <button
        disabled={disabledReportMutation}
        type="button"
        onClick={() => {
          const notificationID = toast.loading("Generando reporte...");
          const ticketModel = paginatedModelTicketToTicketModel(model);
          ticketReportMutation.mutate({
            model: {
              ...ticketModel,
              customerIdCard:
                (cardTypeSelected &&
                  ticketModel.customerIdCard &&
                  `00${cardTypeSelected}${ticketModel.customerIdCard}`) ||
                "",
            },
            fileFormat: Number(fileFormat),
            notificationID,
          });
        }}
        className="button-primary text-sm w-full sm:w-[48%] disabled:pointer-events-none"
      >
        Generar Reporte
      </button>
    </div>
  );
}
export default RequestReport;
