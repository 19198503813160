import { Link } from "react-router-dom";
function NotAuthorized() {
  return (
    <div className="w-full flex flex-col items-center h-screen">
      <h3 className="mt-5 text-4xl font-bold">401</h3>
      <p className="text-lg font-normal text-gray-500">
        No estás autorizado, procede a autenticarte
      </p>
      <Link
        to="/"
        className="mt-5 button-primary text-sm w-full sm:w-[270px] disabled:pointer-events-none"
      >
        Ir a iniciar sesión
      </Link>
    </div>
  );
}

export default NotAuthorized;
