// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PaymentMethodN } from "./types";

export function usePaymentMethod(id?: number) {
  async function getPaymentMethod(id?: number): Promise<PaymentMethodN> {
    const response = await api.get<PaymentMethodN>(`admin/paymentmethods/${id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["PaymentMethod", id],
    queryFn: () => getPaymentMethod(id),
    enabled: !!id,
  });
}
