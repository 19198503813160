// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";

// Third components imports
import CurrencyInput from "react-currency-input-field";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { FormDataCreateInvoice } from "../../../../features/invoices/types";

function DataInvoiceForm() {
  const methods = useFormContext<FormDataCreateInvoice>();
  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-5 gap-x-4 gap-y-2 border-b">
      <CustomLabel
        label="Nro. Factura"
        id="factura"
        required={true}
        className="text-sm sm:col-span-1"
        name="invoice.storeInvoice"
      >
        <input
          type="text"
          placeholder="Nro. Factura"
          className={`input-base ${
            methods.formState.errors.invoice?.storeInvoice ? "input-error" : ""
          } ${
            !methods.formState.errors.invoice?.storeInvoice &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("invoice.storeInvoice", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Precio de venta"
        id="factura"
        required={true}
        className="text-sm sm:col-span-2"
        name="invoice.amount"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          id="amount"
          placeholder="Precio de venta"
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          prefix="$ "
          value={methods.watch("invoice.amount")}
          onValueChange={(value) =>
            methods.setValue("invoice.amount", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.invoice?.amount ? "input-error" : ""
          } ${
            !methods.formState.errors.invoice?.amount &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("invoice.amount", {
            required: "Este campo es requerido",
            valueAsNumber: true
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="IVA"
        id="factura"
        required={true}
        className="text-sm sm:col-span-2"
        name="invoice.iva"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          id="iva"
          placeholder="IVA"
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          prefix="$ "
          value={methods.watch("invoice.iva")}
          onValueChange={(value) =>
            methods.setValue("invoice.iva", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.invoice?.amount ? "input-error" : ""
          } ${
            !methods.formState.errors.invoice?.amount &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("invoice.iva", {
            required: "Este campo es requerido",
            valueAsNumber: true
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Descripcion"
        id="description"
        required={true}
        className="text-sm sm:col-span-5"
        name="invoice.description"
      >
        <textarea
          autoCorrect="off"
          className={`w-full input-base h-[80px] p-5 text-gray-primary ${
            methods.formState.errors.invoice?.description ? "input-error" : ""
          } ${
            !methods.formState.errors.invoice?.description &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("invoice.description", {
            required: "Este campo es requerido",
          })}
        ></textarea>
      </CustomLabel>
    </div>
  );
}
export default DataInvoiceForm;
