// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Province } from "./types";

export function useProvicesByCountry(id: number = 170) {
  async function getProvicesByCountry(id: number): Promise<Province[]> {
    const response = await api.get<Province[]>(
      `admin/provinces/bycountry/${id}`
    );
    if (!response.ok || !response.data) {
      throw response;
    }
    return response.data;
  }

  return useQuery({
    queryKey: ["ProvincesByCountry", id],
    queryFn: () => getProvicesByCountry(id),
  });
}
