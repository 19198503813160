// Custom types imports
import { Roster } from "../../features/roster/types";
import { CARD_TYPES_INFO } from "../../features/card_types/types";

// Custom helpers impors
import { roleDescription } from "../../features/auth/helpers";
import { userStatusInfo } from "../../features/auth/helpers";

interface Props {
  roster: Roster;
  onSelect: (roster: Roster) => void;
}

function RosterCard({ roster, onSelect }: Props) {
  return (
    <div
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
      onClick={() => onSelect(roster)}
    >
      <div className="col-span-1 flex flex-col">
        <div className="mb-1">
          <span className="text-xs text-white bg-blue-500 py-1 px-2 rounded-full truncate">
            {roleDescription(roster.roles)}
          </span>
        </div>
        <b className="text-lg truncate">
          {roster.firstName} {roster.lastName}
        </b>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <b
          className={`text-${
            userStatusInfo(roster.userStatus).background
          } ml-auto`}
        >
          {userStatusInfo(roster.userStatus).description}
        </b>
      </div>
      <div className="col-span-1 mt-1 flex flex-col">
        <span className="text-sm">
          {CARD_TYPES_INFO[Number(roster.idCard.charAt(2))].description}
        </span>
        <b className="italic text-gray-500">
          {new Intl.NumberFormat("es-CO").format(Number(roster.idCard.slice(3)))}
        </b>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </div>
  );
}

export default RosterCard;
