// Logos imports
import mastercard from "../../../assets/mastercard.webp";
import visa from "../../../assets/visa.webp";
import amex from "../../../assets/amex.webp";

function CreditCardOptions() {
  return (
    <div className="w-full flex gap-x-2">
      <img
        className="w-[24px] h-auto rounded-[4px] shadow-custom-sm"
        src={mastercard}
        alt="logotipo de mastercard"
      />
      <img
        className="w-[24px] h-auto rounded-[4px] shadow-custom-sm"
        src={visa}
        alt="logotipo de visa"
      />
      <img
        className="w-[24px] h-auto rounded-[4px] shadow-custom-sm"
        src={amex}
        alt="logotipo de american express"
      />
    </div>
  );
}

export default CreditCardOptions;
