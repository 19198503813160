// React imports
import { useState } from "react";

// Custom components imports
import Steps from "./Steps";
import DataStoreForm from "./DataStoreForm";
import DataRosterForm from "./DataRosterForm";
import SelectedPlan from "./SelectedPlan";
import Summary from "./Summary";

// (react-hook-form) imports
import { useForm } from "react-hook-form";

// Custom types imports
import { formDefaultValues as formDefaultValuesCreateStore } from "../../../../features/stores/types";
import { formDefaultValues as formDefaultValuesCreateRoster } from "../../../../features/roster/types";
import { FormDataCreateStore } from "../../../../features/stores/types";
import { CreateUserFormData } from "../../../../features/roster/types";

function CreateStore() {
  const [step, setStep] = useState<number>(1);
  const [planSelected, setPlanSelected] = useState<number | undefined>(
    undefined
  );
  const [provinceSelected, setProvinceSelected] = useState<number | undefined>(
    undefined
  );

  const methodsCreateStore = useForm<FormDataCreateStore>({
    defaultValues: formDefaultValuesCreateStore,
  });
  const methodsCreateRoster = useForm<CreateUserFormData>({
    defaultValues: {
      ...formDefaultValuesCreateRoster,
      roleToAdd: 4,
    },
  });

  return (
    <div className="flex items-start flex-wrap">
      <div className="mx-auto sm:rounded-[18px] w-full max-w-[576px] pt-5 bg-white sm:mt-[35px]">
        <h2 className="text-[28px] text-center font-bold">Crear Comercio</h2>
        <Steps step={step} />
        <div className={step === 1 ? "" : "hidden"}>
          <DataStoreForm
            step={step}
            setStep={setStep}
            methods={methodsCreateStore}
            provinceSelected={provinceSelected}
            setProvinceSelected={setProvinceSelected}
          />
        </div>
        <div className={step === 2 ? "" : "hidden"}>
          <DataRosterForm
            step={step}
            setStep={setStep}
            methods={methodsCreateRoster}
          />
        </div>
        <div className={step === 3 ? "" : "hidden"}>
          <SelectedPlan
            step={step}
            setStep={setStep}
            planSelected={planSelected}
            setPlanSelected={setPlanSelected}
          />
        </div>
        <div className={step === 4 ? "" : "hidden"}>
          <Summary
            step={step}
            setStep={setStep}
            planSelected={planSelected}
            methodsUserForm={methodsCreateRoster}
            methodsStoreForm={methodsCreateStore}
            provinceSelected={provinceSelected}
            setProvinceSelected={setProvinceSelected}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateStore;
