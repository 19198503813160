// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Hooks & Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../features/card_types/types";
import { cardTypesMock } from "../../../features/customers/mocks";

// Third helpers imports
import { toast } from "react-toastify";

// Custom queries imports
import { usePersonByIdCardMutation } from "../../../features/persons/usePersonByIdCardMutation";

type FormData = {
  idCardType: number;
  idCardValue: string;
};

function ConsultClient() {
  const methods = useForm<FormData>();
  const navigate = useNavigate();
  const personByIdCardMutation = usePersonByIdCardMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Consulta realizada satisfactoriamente",
        autoClose: 5000,
      });
      navigate(`/pagar/00${data.idCardType}${data.idCardValue}/cobrosPublicos`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render: error.data?.description || "Hubo un error al realizar consulta",
        autoClose: 5000,
      });
    }
  );

  const onSubmit = methods.handleSubmit(async (data) => {
    const notificationID = toast.loading("Consultando...");
    personByIdCardMutation.mutate({
      idCard: `00${data.idCardType}${data.idCardValue}`,
      notificationID,
    });
  });

  return (
    <div className="p-4 w-full flex items-center flex-col">
      <FormProvider {...methods}>
        <form className="w-full grid" onSubmit={onSubmit}>
          <div className="w-full grid grid-cols-1 sm:gap-x-[15px]">
            <CustomLabel
              label="Tipo documento"
              id="tipoDocumento"
              required={true}
              className="text-sm"
              name="idCardType"
            >
              <div className="w-full flex items-center relative">
                <select
                  className={`input-base appearance-none ${
                    methods.formState.errors.idCardType ? "input-error" : ""
                  } ${
                    !methods.formState.errors.idCardType &&
                    methods.formState.isSubmitted
                      ? "input-valid"
                      : ""
                  } `}
                  {...methods.register("idCardType", {
                    required: "Este campo es requerido",
                    valueAsNumber: true,
                  })}
                >
                  {cardTypesMock?.map((cardType) => (
                    <option value={cardType} key={cardType}>
                      {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                      {CARD_TYPES_INFO[cardType]?.description}
                    </option>
                  ))}
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>

            <CustomLabel
              label="Nro de documento"
              id="idCardValue"
              required={true}
              className="text-sm"
              name="idCardValue"
            >
              <input
                type="text"
                placeholder="Nro de documento"
                className={`input-base ${
                  methods.formState.errors.idCardValue ? "input-error" : ""
                } ${
                  !methods.formState.errors.idCardValue &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("idCardValue", {
                  required: "Este campo es requerido",
                })}
              />
            </CustomLabel>
          </div>
          <button type="submit" className="mx-auto button-primary w-[270px]">
            Consultar
          </button>
        </form>
      </FormProvider>
    </div>
  );
}

export default ConsultClient;
