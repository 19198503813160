// Custom components imports
import PaymentInformation from "./forms/payment_gateway/PaymentInformation";
import Loading from "./shared/Loading";
import ErrorLoading from "./shared/ErrorLoading";

// Custom queries imports
import { useTicket } from "../features/tickets/useTicket";
import { usePaymentsById } from "../features/payments/usePayment";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function PaymentForm() {
  const params = useParams();
  const ticket = useTicket(Number(params.idTransaccion));
  const payment = usePaymentsById(Number(params.idTransaccion));

  if (ticket.isLoading) {
    return <Loading text="Cargando transacción..." />;
  }

  if (payment.isLoading) {
    return <Loading text="Cargando pago..." />;
  }

  if (ticket.isError || !ticket.data) {
    return <ErrorLoading text="Hubo un error al cargar la transacción" />;
  }

  if (payment.isError || !payment.data) {
    return <ErrorLoading text="Hubo un error al cargar el pago" />;
  }

  return (
    <div className="mx-auto sm:rounded-[18px] w-full max-w-[576px] pt-5 bg-white sm:mt-[35px]">
      <div className="w-full">
        <PaymentInformation
          payment={payment.data}
          ticket={ticket.data}
          backURL={`/${params.idCliente}/transacciones`}
        />
      </div>
    </div>
  );
}

export default PaymentForm;
