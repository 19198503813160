// React imports
import { useState } from "react";

// Custom components imports
import DetailsPlan from "./DetailsPlan";

// Custom types imports
import { Plan } from "../../../features/plans/types";

interface Props {
  plan: Plan;
  onSelect: (idPlan: number) => void;
  isSelected: boolean;
}
function PlanItem({ plan, isSelected, onSelect }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div
      onClick={(e: any) => {
        e.target.tagName !== "BUTTON" && onSelect(plan.id);
      }}
      className={`${!!plan.status ? "" : "opacity-100 pointer-events-none"} ${
        open ? "bg-slate-100" : "bg-white"
      } w-full p-3 hover:bg-slate-100 cursor-pointer`}
      key={plan.id}
    >
      <div className="flex w-full">
        <button
          className={`${
            open ? "rotate-180" : "rotate-0"
          } transition-all h-[30px]`}
          onClick={() => setOpen(!open)}
        >
          <i className="pointer-events-none text-black text-lg bi bi-chevron-down"></i>
        </button>
        <div className="w-full">
          <p className="flex items-center pl-3 h-[30px] pr-2 w-full truncate font-medium">
            {plan.description}
          </p>
          {open && <DetailsPlan plan={plan} />}
        </div>
        {isSelected ? (
          <i className="text-red-600 text-lg bi bi-check-circle-fill"></i>
        ) : (
          <i className="text-gray-400 text-lg bi bi-circle"></i>
        )}
      </div>
    </div>
  );
}
export default PlanItem;
