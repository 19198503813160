import { Store } from "../stores/types";
import { User } from "../users/types";
import { PersonRegistration } from "../persons/types";
import { UserRegister } from "../users/types";
export const STEP_LIMIT = 5;

export interface Roster extends User {
  store: Store;
}

export interface RosterModel {
  storeCityID: number;
  storeID: number;
  role: number;
  lastName: string;
  rosterData: {
    scope: number;
    listReference: string;
    orderBy: number;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
  };
}

export interface RosterList {
  roster: Roster[];
  rosterData: {
    scope: number;
    listReference: string;
    recalculate: boolean;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
    orderBy: number;
  };
}

export const fetchingModelDefaults: RosterModel = {
  storeCityID: 0,
  storeID: 0,
  role: 0,
  lastName: "",
  rosterData: {
    scope: 0,
    listReference: "",
    orderBy: 1,
    currentFirstPosition: 0,
    totalItems: 0,
    step: STEP_LIMIT,
  },
};

export type CreateUserFormData = {
  person: PersonRegistration;
  user: UserRegister;
  roleToAdd: number;
};

export const formDefaultValues: CreateUserFormData = {
  person: {
    maxItems: 0,
    modifiedAfter: new Date().toISOString(),
    expiredBy: new Date().toISOString(),
    vStatus: 0,
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    idCard: "",
    idCardType: 1,
    idCardValue: "",
    phoneNumber: "",
    phoneCountry: 170,
    phoneNumberValue: "",
    birthCountryID: 170,
    birthDate: "",
    name: "",
    passwordToken: true,
  },
  user: {
    maxItems: 0,
    modifiedAfter: new Date().toISOString(),
    expiredBy: new Date().toISOString(),
    vStatus: 0,
    userName: "",
    idCard: "",
    token: "",
    password: "",
    confirmPassword: "",
    login: true,
  },
  roleToAdd: 2,
};
