// Custom components imports
import Loading from "../shared/Loading";
import EmptyContent from "../shared/EmptyContent";
import ErrorLoading from "../shared/ErrorLoading";
import RosterCard from "../cards/RosterCard";
import EndMessageInfiniteList from "../shared/EndMessageInfiniteList";
import LoaderInfiniteList from "../shared/LoaderInfiniteList";

// Components (react-infinite-scroll-component) imports
import InfiniteScroll from "react-infinite-scroll-component";

// Hooks (react-router-dom) imports
import { UseInfiniteQueryResult } from "@tanstack/react-query";

// Custom types imports
import { RosterList, Roster } from "../../features/roster/types";

interface Props {
  roster: UseInfiniteQueryResult<RosterList, unknown>;
  onSelect?: (roster: Roster) => void;
}

function InfiniteListRoster({ roster, onSelect }: Props) {
  const data = roster.data?.pages?.flatMap((page) => page.roster) || [];

  if (roster.isLoading) {
    return <Loading text="Cargando usuarios..." />;
  }

  if (roster.isError || !roster.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar usuaruarios" />
        </div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay usuarios" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de usuarios</span>
        <div className="w-1/2 text-end">
          <b>{roster.data.pages[0].rosterData.totalItems}</b>
        </div>
      </div>
      <InfiniteScroll
        dataLength={data.length || 0}
        next={() => roster.fetchNextPage()}
        hasMore={!!roster.hasNextPage}
        loader={<LoaderInfiniteList />}
        endMessage={<EndMessageInfiniteList message="Carga completada" />}
      >
        <div className="divide-y">
          {data.map((roster, index) => (
            <RosterCard
              roster={roster}
              key={index}
              onSelect={(roster) => onSelect?.(roster)}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteListRoster;
