// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

//Custom components imports
import DrawerCreateRosterData from "./DrawerCreateRosterData";

// Components & Hooks (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom Hooks imports
import { useCreateRosterInStore } from "../../../../features/stores/useCreateRosterInStore";

//Third helpers imports
import { toast } from "react-toastify";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

// Custom types imports
import {
  formDefaultValues,
  CreateUserFormData,
  RosterModel,
} from "../../../../features/roster/types";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paramsRostersInfinite: RosterModel;
}

function DrawerCreateRoster({ open, setOpen, paramsRostersInfinite }: Props) {
  const params = useParams();
  const queryClient = useQueryClient();
  const methods = useForm<CreateUserFormData>({
    defaultValues: formDefaultValues,
  });

  const createRosterInStoreMutation = useCreateRosterInStore({
    data: {
      person: methods.watch("person"),
      user: methods.watch("user"),
      role: methods.watch("roleToAdd"),
      companyID: Number(params.idStore),
    },
    onSuccessMutation: () => {
      queryClient.invalidateQueries([
        "RosterInfinite",
        ...Object.values(paramsRostersInfinite),
      ]);
      closeDrawer();
    },
  });

  // const closeMenuClickOuside = (event: any) => {
  //   let idElement = event.target.id;
  //   if (idElement === "containerDrawer") closeDrawer();
  // };

  const closeDrawer = () => {
    methods.reset();
    setOpen(false);
  };

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("1/4 Validando email del usuario...");
    createRosterInStoreMutation.mutate({
      notificationID,
      email: data.person.email,
    });
  });
  
  return (
    <div
      // onClick={closeMenuClickOuside}
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[580px] h-full sm:rounded-l-xl">
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
              <button type="button" onClick={() => closeDrawer()}>
                <i className="mr-4 text-2xl bi bi-arrow-left"></i>
              </button>{" "}
              <b className="font-medium text-lg">Crear usuario</b>
              <button
                className="ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm"
                type="submit"
              >
                Agregar
              </button>
            </div>
            <DrawerCreateRosterData />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default DrawerCreateRoster;
