export interface idCard {
  idCardTypes: number;
  idCardValue: string;
}

export enum CardTypes {
  cedulaDeCiudadania = 1,
  numeroIdentificacionTributaria = 2,
  cedulaExtranjeria = 3,
  tarjetaIdentidad = 4,
  pasaporte = 5,
  tarjetaSeguroSocialExtranjero = 6,
  sociedadExtranjeriaSinNITColombia = 7,
  fideicomiso = 8,
  registroCivil = 9,
  CarnetDiplomatico = 10,
}

export const CARD_TYPES_INFO: Record<
  number,
  {
    description: string;
    prefix: string;
  }
> = {
  [CardTypes.cedulaDeCiudadania]: {
    description: "Cedula de ciudadanía",
    prefix: "CC",
  },
  [CardTypes.numeroIdentificacionTributaria]: {
    description: "Número de Identificación Tributaria",
    prefix: "NIT",
  },
  [CardTypes.cedulaExtranjeria]: {
    description: "Cedula de extranjería",
    prefix: "CE",
  },
  [CardTypes.tarjetaIdentidad]: {
    description: "Tarjeta de identidad",
    prefix: "TI",
  },
  [CardTypes.pasaporte]: {
    description: "Pasaporte",
    prefix: "PS",
  },
  [CardTypes.tarjetaSeguroSocialExtranjero]: {
    description: "Tarjeta del seguro social extranjero",
    prefix: "TSSE",
  },
  [CardTypes.sociedadExtranjeriaSinNITColombia]: {
    description: "Sociedad extranjería sin NIT en Colombia",
    prefix: "SESNC",
  },
  [CardTypes.fideicomiso]: {
    description: "Fideicomiso",
    prefix: "FC",
  },
  [CardTypes.registroCivil]: {
    description: "Registro civil",
    prefix: "RC",
  },
  [CardTypes.CarnetDiplomatico]: {
    description: "Carnet diplomático",
    prefix: "CD",
  },
};
