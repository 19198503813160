export const STEP_LIMIT = 5;

export interface idCard {
  idCardTypes: number;
  idCardValue: string;
}

export interface Store {
  id: number;
  storeName: string;
  address: string;
  address2: string;
  cityID: number;
  phone: string;
  email: string;
  status: number;
  taxCondition: number;
  taxIDNumber: string;
}

export interface City {
  id: number;
  value: string;
  description: string;
  parentID: number;
  isVisible: boolean;
}

export enum TAX_REGIME {
  Comun = 1,
  Simplificado = 2,
  Especial = 3,
}

export const TAX_REGIME_INFO: Record<
  number,
  {
    description: string;
    prefix: string;
  }
> = {
  [TAX_REGIME.Comun]: {
    description: "Régimen Común",
    prefix: "RC",
  },
  [TAX_REGIME.Simplificado]: {
    description: "Régimen Simplificado",
    prefix: "RS",
  },
  [TAX_REGIME.Especial]: {
    description: "Régimen Especial",
    prefix: "RE",
  },
};

export interface StoreModel {
  cityID?: number;
  taxCondition?: number;
  storesData: {
    scope: number;
    listReference: "";
    recalculate?: boolean;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
    orderBy: number;
  };
}

export interface StoreList {
  stores: Store[];
  storesData: {
    scope: number;
    listReference: "";
    recalculate: boolean;
    currentFirstPosition: number;
    totalItems: number;
    step: number;
    orderBy: number;
  };
}

export type FormDataCreateStore = {
  id: number;
  storeName: string;
  address: string;
  address2: string;
  cityID: number;
  phone: string;
  email: string;
  status: number;
  taxCondition: number;
  taxIDNumber: string;
};

export const formDefaultValues: FormDataCreateStore = {
  id: 0,
  storeName: "",
  address: "",
  address2: "",
  cityID: 1,
  phone: "",
  email: "",
  status: 1,
  taxCondition: 1,
  taxIDNumber: "",
};

export const fetchingModelDefaults: StoreModel = {
  cityID: 0,
  taxCondition: undefined,
  storesData: {
    scope: 0,
    listReference: "",
    currentFirstPosition: 0,
    totalItems: 0,
    step: STEP_LIMIT,
    orderBy: 1,
  },
};
