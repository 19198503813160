import { toast } from "react-toastify";
import api from "./app/api";
import { Ticket } from "./features/payments/types";
import { isStoreEmployee, isStoreSupervisor } from "./features/auth/helpers";

// Consts imports
import { MaximumNumberBeforeCompactNotation } from "./app/consts";

export const isUpperCase = (string: string) => /^[A-Z]*$/.test(string);
export const isLowerCase = (string: string) => /^[a-z]*$/.test(string);
export const isNumber = (character: string) => /^\d$/.test(character);

export async function validateToken(callback?: () => void) {
  const isValidToken = await api.get<string>("auth/limitedping");
  if (!isValidToken.ok && isValidToken.status === 401) {
    callback?.();
  }
  return isValidToken.ok;
}

export const requestConfirmEmail = async (email: string) => {
  const notificationID = toast.loading(
    "Solicitando enlace de confirmación de correo..."
  );
  const response = await api.post<any>(
    `account/sendconfirmationemail/${email}`
  );
  if (!response.ok) {
    toast.update(notificationID, {
      type: "error",
      isLoading: false,
      render:
        response.data?.description ||
        "Hubo un error, al intentar solicitar el enlace de confirmación de correo",
      autoClose: 5000,
    });
    throw response;
  }
  toast.update(notificationID, {
    type: "success",
    isLoading: false,
    render: "Solicitud enviada satisfactoriamente",
    autoClose: 5000,
  });
  return response.data;
};

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getDescriptionEntity = (ticket: Ticket, role: number) => {
  const entity =
    !isStoreEmployee(role) || !isStoreSupervisor(role)
      ? `${ticket.customer?.firstName || ""} ${ticket.customer?.lastName || ""}`
      : ticket.store?.storeName || "";

  return entity;
};

export const formatNumber = (number: number) => {
  return number.toLocaleString("es-CO");
};

export const formatAmount = (amount: number) => {
  const format = Intl.NumberFormat("es-CO", {
    notation:
      amount > MaximumNumberBeforeCompactNotation ? "compact" : "standard",
    currency: "COP",
    maximumFractionDigits: 1,
  }).format(amount);
  return `$ ${format} COP`;
};

export const formatDate = (date: string) => {
  const fixDate = date.substring(date.length - 1) === "Z" ? date : date + "Z";
  return new Date(fixDate).toLocaleDateString("es-CO", {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDateShort = (date: string) => {
  const fixDate = date.substring(date.length - 1) === "Z" ? date : date + "Z";
  return new Date(fixDate).toLocaleDateString("es-CO", {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  });
};
