// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { City } from "../stores/types";

export function useCitiesByProvince(
  id?: number,
  onSuccess?: (data: City[]) => void
) {
  async function getCitiesByProvince(id?: number): Promise<City[]> {
    const response = await api.get<City[]>(`admin/cities/byprovince/${id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["CitiesByProvince", id],
    queryFn: () => getCitiesByProvince(id),
    onSuccess: onSuccess,
    enabled: !!id,
  });
}
