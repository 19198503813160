import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { NewlyRegisteredUser } from "../users/types";
import { USER_KEY } from "./helpers";
import api from "../../app/api";
import { logoutAPI } from "./helpers";

interface UserStateTypes {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: NewlyRegisteredUser | null;
}

const initialState: UserStateTypes = {
  isLoading: true,
  isAuthenticated: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeIsLoadingUser: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    loadUser: (state, action: PayloadAction<NewlyRegisteredUser>) => {
      api.setHeader("Authorization", `Bearer ${action.payload.token.value}`);
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      logoutAPI();
      localStorage.removeItem(USER_KEY);
      api.deleteHeader("Authorization");
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { loadUser, logout, changeIsLoadingUser } = authSlice.actions;
export default authSlice.reducer;
