// Custom components imports
import ErrorBoundary from "../../components/ErrorBoundary";
import ValidateEmailConfirmationToken from "../../components/ValidateEmailConfirmationToken";

// Hooks & components (react-router-dom) imports
import { useSearchParams, useParams, useNavigate } from "react-router-dom";

// Custom consts imports
import { REGEX_VALIDATE_MAIL } from "../../app/consts";

function ConfirmEmail() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  if (!params.emailUser || !REGEX_VALIDATE_MAIL.test(params.emailUser)) {
    return (
      <ErrorBoundary
        title="Correo electrónico con formato inválido"
        description="El correo electrónico no tiene un formato inválido, reintente la solicitud."
        buttonAction={
          <button
            onClick={() => navigate("/")}
            className="w-[270px] button-secondary mx-auto block mt-4"
          >
            Volver al inicio
          </button>
        }
      />
    );
  }

  if (!token) {
    return (
      <ErrorBoundary
        title="Token Requerido"
        description="No se encontró un token en la consulta, el token es requerido para continuar con el procedimiento"
        buttonAction={
          <button
            onClick={() => navigate("/")}
            className="w-[270px] button-secondary mx-auto block mt-4"
          >
            Volver al inicio
          </button>
        }
      />
    );
  }

  return (
    <ValidateEmailConfirmationToken email={params.emailUser} token={token} />
  );
}
export default ConfirmEmail;
