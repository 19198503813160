// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import StoreFilterInvoice from "../../components/forms/store/StoreFilterInvoice";
import InfiniteListStores from "../../components/infinite_lists/InfiniteListStores";

// Hooks & Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Hooks & components (react-router-dom") imports
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// Custom types imports
import { fetchingModelDefaults } from "../../features/stores/types";
import { StoreModel } from "../../features/stores/types";

// Custom queries imports
import { useStoresInfinite } from "../../features/stores/useStoresInfinite";

function Stores() {
  const navigate = useNavigate();

  const methods = useForm<StoreModel>({
    defaultValues: {
      ...fetchingModelDefaults,
    },
  });

  const stores = useStoresInfinite({
    ...methods.watch(),
    taxCondition:
      methods.watch("taxCondition") === 0
        ? undefined
        : methods.watch("taxCondition"),
  });

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Filtros"
        isToogle={true}
        defaultOpenValue={false}
      >
        <FormProvider {...methods}>
          <StoreFilterInvoice />
        </FormProvider>
      </ContentWithToggle>

      <ContentWithToggle
        onBackButton={() => navigate("/")}
        title="Comercios"
        isToogle={false}
        actionButton={
          <div>
            <Link
              to="agregar"
              className="button-primary inline-block w-[90px] sm:w-[108px] text-sm"
            >
              Agregar
            </Link>
          </div>
        }
      >
        <InfiniteListStores stores={stores} />
      </ContentWithToggle>
    </div>
  );
}

export default Stores;
