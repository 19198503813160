interface Props {
    text: string;
  }
  
  function ErrorLoading({ text }: Props) {
    return (
      <div className="w-full flex justify-center items-center flex-col h-[200px]">
        <i className="text-[48px] text-red-primary bi bi-x-octagon-fill"></i>
        <span>{text}</span>
      </div>
    );
  }
  
  export default ErrorLoading;
  