// React imports
import { Dispatch, SetStateAction } from "react";

// Components (react-router-dom) imports
import { Link } from "react-router-dom";

// Hooks, Actions & States Redux imports
import { useAppSelector } from "../../../../app/hook";

interface Prop {
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

function PublicActions({ setIsOpenMenu }: Prop) {
  const currentUser = useAppSelector((state) => state.auth);

  if (currentUser.isAuthenticated) return null;

  return (
    <div className="grid sm:grid-cols-2 gap-5 ml-8">
      <Link
        className="button-primary w-[100px]"
        to="/pagar"
        onClick={() => setIsOpenMenu(false)}
      >
        Pagar
      </Link>
      <Link
        className="hidden sm:block button-secondary w-[100px]"
        to="/ingresar"
        onClick={() => setIsOpenMenu(false)}
      >
        Ingresar
      </Link>
    </div>
  );
}

export default PublicActions;
