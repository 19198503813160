// Custom components imports
import Loading from "./shared/Loading";
import EmptyContent from "./shared/EmptyContent";
import ErrorLoading from "./shared/ErrorLoading";
import PlanCard from "./cards/PlanCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { Plan } from "../features/plans/types";

interface Props {
  plans: UseQueryResult<Plan[], unknown>;
}

function ListPlans({ plans }: Props) {
  if (plans.isLoading) {
    return <Loading text="Cargando planes..." />;
  }

  if (plans.isError || !plans.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar planes" />
        </div>
      </div>
    );
  }

  if (plans.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay planes" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de planes</span>
        <div className="w-1/2 text-end">
          <b>{plans.data.length}</b>
        </div>
      </div>
      {plans.data.map((plan) => (
        <PlanCard plan={plan} link={`p/${plan.id}`} key={plan.id} />
      ))}
    </div>
  );
}

export default ListPlans;
