// React Components
import { Dispatch, SetStateAction } from "react";

// Custom componentes imports
import LinkWithoutSubmenu from "../../../LinkWithoutSubmenu";

// Components () imports
import { Link } from "react-router-dom";

// Third helpers imports
import { toast } from "react-toastify";

// Hook & Components Redux imports
import { useAppSelector, useAppDispatch } from "../../../../app/hook";
import {
  logout,
  changeIsLoadingUser,
} from "../../../../features/auth/authSlice";

interface Props {
  inMenu?: boolean;
  setIsOpenMenu?: Dispatch<SetStateAction<boolean>>;
}

function LinksCorporateAdmin({ inMenu, setIsOpenMenu }: Props) {
  const currentUser = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  return (
    <>
      <LinkWithoutSubmenu
        text="Escritorio"
        to={`/${currentUser.user?.id}/escritorio`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Clientes"
        to={`/${currentUser.user?.id}/clientes`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Comercios"
        to={`/${currentUser.user?.id}/comercios`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Empleados"
        to={`/${currentUser.user?.id}/usuarios`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Metodos"
        to={`/${currentUser.user?.id}/metodos`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Planes"
        to={`/${currentUser.user?.id}/planes`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Transacciones"
        to={`/${currentUser.user?.id}/transacciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Anulaciones"
        to={`/${currentUser.user?.id}/anulaciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <LinkWithoutSubmenu
        text="Configuraciones"
        to={`/${currentUser.user?.id}/configuraciones`}
        onClick={() =>
          inMenu && !!setIsOpenMenu ? setIsOpenMenu(false) : undefined
        }
      />
      <Link
        className="button-secondary p-4 w-full mb-[14px] flex justify-between rounded-none"
        to="/ingresar"
        onClick={() => {
          dispatch(changeIsLoadingUser(true));
          dispatch(logout());
          inMenu && !!setIsOpenMenu && setIsOpenMenu(false);
          toast.info("Cierre de sesión satisfactorio");
        }}
      >
        Cerrar Sesión
        <i className="bi bi-chevron-right"></i>
      </Link>
    </>
  );
}
export default LinksCorporateAdmin;
