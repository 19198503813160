// React imports
import { useEffect, useState } from "react";

// Custom components imports
import Menu from "./Menu";
import Logo from "./Logo";
import PublicActions from "./shared/navigations/actions/PublicActions";
import AdminActions from "./shared/navigations/actions/AdminActions";

function NavigationTop() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const toggleShadowOfNavigationTop = () => {
      setIsScrolling(window.scrollY > 0);
    };
    window.addEventListener("scroll", toggleShadowOfNavigationTop);
    return () =>
      window.removeEventListener("scroll", toggleShadowOfNavigationTop);
  }, []);

  return (
    <>
      <div
        id="NavigationTop"
        className={`z-40 w-full bg-white sm:sticky top-0 left-0 h-[65px] ${
          isScrolling ? "shadow-custom-lg" : "border-b"
        } ${isOpenMenu ? "sticky" : ""}`}
      >
        <div className="flex items-center w-full h-full p-6 max-w-[1280px] mx-auto">
          <Logo isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
          <PublicActions setIsOpenMenu={setIsOpenMenu} />
          <AdminActions setIsOpenMenu={setIsOpenMenu} />
        </div>
      </div>
      <Menu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
    </>
  );
}

export default NavigationTop;
