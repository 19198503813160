// Custom types imports
import {
  StatusName,
  invoiceStatus,
  invoiceStatusData,
  StatusIndex,
} from "../../../../features/invoices/types";

interface Props {
  status: StatusIndex;
}

function StatusHeder({ status }: Props) {
  const statusName = invoiceStatus[status];
  const statusData = invoiceStatusData[statusName as StatusName];
  return (
    <div
      className={`${statusData.color} border-b flex flex-col items-center w-full py-4`}
    >
      <i
        className={`drop-shadow-lg text-[50px] text-white ${statusData.icon}`}
      ></i>
      <h2 className="drop-shadow-lg text-white mb-4 text-lg font-bold">
        {statusData.description}
      </h2>
    </div>
  );
}

export default StatusHeder;
