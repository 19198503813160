// Logos payment methods imports
import compensar from "../../assets/compensar.webp";
import bancolombia from "../../assets/bancolombia.webp";
import pse from "../../assets/pse.webp";
import nequi from "../../assets/nequi.webp";
import daviplata from "../../assets/daviplata.webp";
import davivienda from "../../assets/davivienda.webp";
import CreditCardOptions from "../../components/forms/payment_gateway/CreditCardOptions";

export const METODOS = [
    {
      title: "Billetera Compensar",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      icon: (
        <img
          className="w-[42px] h-[42px]"
          src={compensar}
          alt="icono de Compensar"
        />
      ),
    },
    {
      title: "Tarjetas de Crédito/Débito",
      body: <CreditCardOptions />,
      disabled: true,
      icon: <i className="bi bi-credit-card text-red-primary text-[42px]"></i>,
    },
    {
      title: "Bancolombia",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      disabled: true,
      icon: (
        <img
          className="w-[42px] h-[42px] rounded-lg shadow-custom-sm"
          src={bancolombia}
          alt="icono de Bancolombia"
        />
      ),
    },
    {
      title: "Débito Bancario PSE (ACH Colombia)",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      disabled: true,
      icon: (
        <img
          className="w-[42px] h-[42px] rounded-full shadow-custom-sm"
          src={pse}
          alt="icono de PSE"
        />
      ),
    },
    {
      title: "Davivienda",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      disabled: true,
      icon: (
        <img
          className="w-[42px] h-[42px] rounded-lg shadow-custom-sm"
          src={davivienda}
          alt="icono de Davienda"
        />
      ),
    },
    {
      title: "Nequi",
      disabled: true,
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      icon: (
        <img
          className="w-[42px] h-[42px] rounded-lg shadow-custom-sm"
          src={nequi}
          alt="icono de Nequi"
        />
      ),
    },
    {
      title: "Daviplata",
      disabled: true,
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      icon: (
        <img
          className="w-[42px] h-[42px] rounded-lg shadow-custom-sm"
          src={daviplata}
          alt="icono de Daviplata"
        />
      ),
    },
  ];