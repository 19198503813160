// Custom type imports
import { Plan } from "../../../features/plans/types";

interface Props {
  plan: Plan;
}

function DetailsPlan({ plan }: Props) {
  return (
    <div className="w-full mx-auto bg-white overflow-hidden mt-2 rounded-lg">
      <div className="text-sm w-full flex flex-col divide-y divide-slate-100">
        <div className="w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Estado</span>
          <b
            className={`${!!plan.status ? "text-green-600" : "text-red-600"}`}
          >
            {!!plan.status ? "Activo" : "Inactivo"}
          </b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Transacciones diarias</span>
          <b className="text-gray-600">{plan.dailyTransactions}</b>
        </div>
        <div className="border-t w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Transacciones mensuales</span>
          <b className="text-gray-600">{plan.monthlyTransactions}</b>
        </div>
      </div>
    </div>
  );
}

export default DetailsPlan;
