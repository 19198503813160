// React Components
import { Dispatch, SetStateAction } from "react";

// Components () imports
import { Link } from "react-router-dom";

// Hook & Components Redux imports
import { useAppSelector } from "../../../../app/hook";

// Custom helpers imports
import { roleDescription } from "../../../../features/auth/helpers";

interface Props {
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
}

function CoverUsername({ setIsOpenMenu }: Props) {
  const currentUser = useAppSelector((state) => state.auth);

  if (!currentUser.isAuthenticated || !currentUser.user?.roles) return null;

  return (
    <div className="text-white w-full bg-blue-500 px-4 pb-4 pt-[86px] max-w-[380px]">
      <Link
        className="flex w-full"
        to={`/${currentUser.user?.id}/configuraciones`}
        onClick={() => setIsOpenMenu(false)}
      >
        <div className="w-full flex items-center">
          <div className="w-[70%] flex flex-col items-start">
            <b className="mr-3 text-lg truncate ...">
              {currentUser.user?.firstName} {currentUser.user?.lastName}
            </b>
            <span className="w-auto text-xs text-blue-500 bg-white py-1 px-2 rounded-full truncate">
              {roleDescription(currentUser.user.roles)}
            </span>
          </div>
          <i className="ml-auto text-xl bi bi-chevron-right"></i>
        </div>
      </Link>
    </div>
  );
}
export default CoverUsername;
