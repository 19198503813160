// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Invoice } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  invoice: Invoice;
  notificationID: string | number;
}

export function useSendInvoiceMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function sendInvoice(params: paramsType): Promise<any> {
    const response = await api.post<any>(
      `invoices/invoices/${params.invoice.id}/sendbyemail`
    );

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: sendInvoice,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
