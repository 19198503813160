// React imports
import { useState, useEffect } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import InfiniteListRoster from "../../components/infinite_lists/InfiniteListRoster";
import DrawerEditRoster from "../../components/forms/store/Edit/DrawerEditRoster";

// Custom queries imports
import { useRostersInfinite } from "../../features/roster/useRosterInfinite";

// Custom types imports
import {
  fetchingModelDefaults,
  RosterModel,
} from "../../features/roster/types";
import { ROLES } from "../../features/users/types";
import { Roster as RosterType } from "../../features/roster/types";

// Hooks (react-router-dom") imports
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Hooks & actions imports
import { useAppSelector } from "../../app/hook";

function Roster() {
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);

  const paramsRostersInfinite: RosterModel = {
    ...fetchingModelDefaults,
    storeID:
      (currentUser.user?.roles === ROLES.CorporateAdmin
        ? 0
        : currentUser.user?.companyID) || 0,
    role: (currentUser.user?.roles === ROLES.CorporateAdmin ? 8 : 2) || 0,
  };
  const roster = useRostersInfinite(paramsRostersInfinite);

  const [openDrawerEditRoster, setOpenDrawerEditRoster] =
    useState<boolean>(false);

  const [selectedRoster, setSelectedRoster] = useState<RosterType | undefined>(
    undefined
  );

  useEffect(() => {
    if (openDrawerEditRoster === false) {
      setSelectedRoster(undefined);
    }
  }, [openDrawerEditRoster, setSelectedRoster]);

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        onBackButton={() => navigate(`/${params.idUser}/escritorio`)}
        title="Empleados"
        actionButton={
          <div>
            <Link
              to="agregar"
              className="button-primary inline-block w-[180px] text-sm"
            >
              Agregar Empleado
            </Link>
          </div>
        }
      >
        <InfiniteListRoster
          roster={roster}
          onSelect={(roster) => {
            setOpenDrawerEditRoster(true);
            setSelectedRoster(roster);
          }}
        />
        {!!selectedRoster && (
          <DrawerEditRoster
            paramsRostersInfinite={paramsRostersInfinite}
            open={openDrawerEditRoster}
            setOpen={setOpenDrawerEditRoster}
            roster={selectedRoster}
          />
        )}
      </ContentWithToggle>
    </div>
  );
}

export default Roster;
