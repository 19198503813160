// Custom queries imports
import { useUpdatePlanMutation } from "../../../../features/plans/useUpdatePlanMutation";

// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import EditPlanActions from "./EditPlanActions";
import DataPlanForm from "./DataPlanForm";

// Custom types imports
import { Plan } from "../../../../features/plans/types";
import { PaymentMethodN } from "../../../../features/payment_methods/types";

// Custom helpers imports
import { toast } from "react-toastify";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Redux state
import { useAppSelector } from "../../../../app/hook";

interface Props {
  plan: Plan;
  paymentMethods: PaymentMethodN[];
}

function EditPlanForm({ plan, paymentMethods }: Props) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);

  const methods = useForm<Plan>({
    defaultValues: plan,
  });

  const paymentCommissions = methods.watch("paymentCommissions");

  const updatePlanByIdMutation = useUpdatePlanMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Plan actualizado`,
        autoClose: 5000,
      });
      navigate(`/${currentUser?.id}/planes/p/${variables.data.id}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar actualizar el cobro",
        autoClose: 5000,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando plan...");
    updatePlanByIdMutation.mutate({
      data,
      notificationID,
    });
  });

  const onChangePaymentCommission = ({
    idPaymentCommission,
    value,
  }: {
    idPaymentCommission: number;
    value: number;
  }) => {
    const newPaymentCommission = paymentCommissions.map((paymentCommission) => {
      if (paymentCommission.id === idPaymentCommission) {
        return { ...paymentCommission, commission: value };
      } else {
        return paymentCommission;
      }
    });

    methods.setValue("paymentCommissions", newPaymentCommission);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ContentWithToggle
          title={`Plan #${methods.watch("id")}`}
          onBackButton={() => navigate(`/${currentUser?.id}/planes`)}
          isToogle={true}
        >
          <div className="sm:col-span-4 text-sm flex sm:flex-row flex-col flex-wrap sm:flex-nowrap sm:justify-center items-center bg-blue-500 text-white py-2 px-4">
            <i className="text-lg bi bi-info-circle-fill mr-4"></i>
            <p className="flex-1">
              La eliminación de un plan solo será posible directamente desde el
              servidor, esto con el fin de evitar conflictos en las relaciones
              de las entidades involucradas.
            </p>
          </div>
          <DataPlanForm />
        </ContentWithToggle>
        {!!plan.paymentCommissions.length && (
          <ContentWithToggle
            title="Comisiones (%)"
            isToogle={false}
            defaultOpenValue={true}
          >
            <div className="sm:col-span-4 text-sm flex sm:flex-row flex-col flex-wrap sm:flex-nowrap sm:justify-center items-center bg-yellow-500 text-white py-2 px-4">
              <i className="text-lg bi bi-exclamation-triangle-fill mr-4"></i>
              <p className="flex-1">
                Antes de modificar las comisiones de los métodos de pago en este
                plan, notificar a los clientes afectados.
              </p>
            </div>
            {methods.watch("paymentCommissions").map((paymentCommission) => (
              <div
                className={`border-b w-full px-4 py-3 flex justify-between items-center`}
              >
                <span>
                  {
                    paymentMethods.find(
                      (paymentMethod) =>
                        paymentMethod.id === paymentCommission.paymentMethodID
                    )?.description
                  }
                </span>
                <input
                  type="number"
                  placeholder="Comisión"
                  className="input-base w-[80px] text-center"
                  value={paymentCommission.commission}
                  onChange={(e) =>
                    onChangePaymentCommission({
                      idPaymentCommission: paymentCommission.id,
                      value: Number(e.target.value),
                    })
                  }
                />
              </div>
            ))}
          </ContentWithToggle>
        )}

        <EditPlanActions plan={methods.watch()} />
      </form>
    </FormProvider>
  );
}

export default EditPlanForm;
