// Custom queries imports
import { useTicket } from "../../../../features/tickets/useTicket";

// Custom components imports
import Loading from "../../../shared/Loading";
import ErrorLoading from "../../../shared/ErrorLoading";
import EditInvoiceForm from "./EditInvoiceForm";

// Hooks (react-router-dom) imports
import { useParams, useSearchParams } from "react-router-dom";

function EditInvoice() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessCode = searchParams.get("accessCode");
  const ticket = useTicket(Number(params.idCobro), accessCode || "");

  if (ticket.isLoading) {
    return <Loading text="Cargando cobro..." />;
  }

  if (ticket.isError || !ticket.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar cobro" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditInvoiceForm ticket={ticket.data} />
    </div>
  );
}
export default EditInvoice;
