// React imports
import { useState } from "react";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

// Custom components imports
import ListPlansAssign from "../ListPlansAssign";

// Custom types imports
import { Plan } from "../../../../features/plans/types";

// Custom queries imports
import { useAssignPlanStoreMutation } from "../../../../features/plans/useAssignPlanStoreMutation";

// Third helpers imports
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  plan?: Plan;
}

function DrawerAssignPlanStore({ open, setOpen, plan }: Props) {
  const [planSelected, setPlanSelected] = useState<number | undefined>(
    plan?.id || undefined
  );
  const params = useParams();
  // const closeMenuClickOuside = (event: any) => {
  //   let idElement = event.target.id;
  //   if (idElement === "containerDrawer") setOpen(false);
  // };

  const assignPlanStoreMutation = useAssignPlanStoreMutation(
    (_data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Plan asignado`,
        autoClose: 5000,
      });
      setOpen(false);
    },
    (error, variable) => {
      toast.update(variable.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Hubo un error, al intentar asignar el plan",
        autoClose: 5000,
      });
    }
  );

  return (
    <div
      // onClick={closeMenuClickOuside}
      id="containerDrawer"
      className={`flex justify-end z-50 top-0 w-full h-screen bg-black/30 transition-all fixed  ${
        open ? "opacity-100 right-0" : "-right-full opacity-50"
      }`}
    >
      <div className="overflow-y-scroll bg-white w-full max-w-[380px] h-full sm:rounded-l-xl">
        <div className="w-full border-b h-[60px] flex items-center px-5 sticky top-0 bg-white z-50">
          <button type="button" onClick={() => setOpen(false)}>
            <i className="mr-4 text-2xl bi bi-arrow-left"></i>
          </button>{" "}
          <b className="font-medium text-lg">Asignar Plan</b>
          <button
            className={`${
              !planSelected || plan?.id === planSelected
                ? "opacity-50 pointer-events-none"
                : ""
            } ml-auto button-primary inline-block w-[90px] sm:w-[108px] text-sm`}
            type="button"
            onClick={() => {
              let notificationID = toast.loading("1/2 Cerrando plan actual...");
              assignPlanStoreMutation.mutate({
                notificationID,
                companyID: Number(params.idStore),
                planID: planSelected || 0,
              });
            }}
          >
            Actualizar
          </button>
        </div>
        <ListPlansAssign
          planSelected={planSelected}
          setPlanSelected={setPlanSelected}
        />
      </div>
    </div>
  );
}
export default DrawerAssignPlanStore;
