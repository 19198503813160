// Custom types imports
import { Invoice, InvoiceRegistration } from "./types";
import { TicketModel, PaginatedModelTicket } from "../tickets/types";
import { Ticket } from "../payments/types";

export function tikectToInvoice(ticket: Ticket): Invoice {
  return {
    id: ticket.id,
    invoiceID: ticket.invoiceID,
    invoiceType: ticket.invoiceType,
    storeID: ticket.storeID,
    idCard: ticket.idCard,
    amount: ticket.amount,
    iva: ticket.iva,
    subTotal: ticket.subTotal,
    commission: ticket.commission,
    ivaCommission: ticket.ivaCommission,
    reteICA: ticket.reteICA,
    reteIVA: ticket.reteIVA,
    reteFuente: ticket.reteFuente,
    collectionAmount: ticket.collectionAmount,
    invoiceStatus: ticket.invoiceStatus,
    date: ticket.date,
    storeInvoice: ticket.storeInvoice,
    invoiceSource: ticket.invoiceSource,
    description: ticket.description,
    accessCode: ticket.accessCode,
    paymentMethod: ticket.paymentMethod,
    minDate: ticket.minDate,
  };
}

export function invoiceToInvoiceRegistration(
  invoice: Invoice
): InvoiceRegistration {
  return {
    id: invoice.id,
    invoiceID: invoice.invoiceID,
    invoiceType: invoice.invoiceType,
    storeID: invoice.storeID,
    idCard: invoice.idCard,
    amount: invoice.amount,
    iva: invoice.iva,
    invoiceStatus: invoice.invoiceStatus,
    date: invoice.date,
    storeInvoice: invoice.storeInvoice,
    invoiceSource: invoice.invoiceSource,
    description: invoice.description,
    accessCode: invoice.accessCode,
    paymentMethod: invoice.paymentMethod,
  };
}

export function paginatedModelTicketToTicketModel(
  paginatedModelTicket: PaginatedModelTicket
): TicketModel {
  return {
    invoiceID: paginatedModelTicket.invoiceID,
    sinceDate: paginatedModelTicket.sinceDate,
    toDate: paginatedModelTicket.toDate,
    customerIdCard: paginatedModelTicket.customerIdCard,
    storeCityID: paginatedModelTicket.storeCityID,
    storeID: paginatedModelTicket.storeID,
    paymentMethod: paginatedModelTicket.paymentMethod,
    maximumAmount: paginatedModelTicket.maximumAmount,
    minimumAmount: paginatedModelTicket.minimumAmount,
    invoiceStatus: paginatedModelTicket.invoiceStatus,
  };
}

export function ticketToInvoiceRegistration(
  ticket: Ticket
): InvoiceRegistration {
  const invoice = tikectToInvoice(ticket);
  const invoiceResgistration = invoiceToInvoiceRegistration(invoice);
  return invoiceResgistration;
}

export const getDescriptionByCode = (code: string) => {
  let description = "";
  switch (code) {
    case "51":
      description = "Fondos insuficientes";
      break;

    case "61":
      description = "Excede el límite de retiro";
      break;

    case "55":
      description = "PIN incorrecto";
      break;

    case "75":
      description = "Intentos de PIN excedidos";
      break;

    case "17":
      description = "Cancelación del cliente";
      break;

    case "18":
      description = "Disputa del cliente ";
      break;

    case "19":
      description = "Reintente la transacción";
      break;

    case "30":
      description = "Error de formato";
      break;

    case "68":
      description = "Respuesta recibida demasiado tarde";
      break;

    case "69":
      description = "Notificación recibida demasiado tarde";
      break;

    case "91":
      description = "Entidad no responde o no está disponible";
      break;

    case "92":
      description = "Error de encaminamiento";
      break;

    case "94":
      description = "Transacción duplicada";
      break;

    case "96":
      description = "Mal funcionamiento del sistema";
      break;

    case "Z1":
      description = "Rechazado fuera de línea(por debajo de los límites)";
      break;

    case "Z3":
      description = "No se puede conectar, rechazado (problemas de red)";
      break;

    case "01":
      description = "Remítase con su Emisor";
      break;

    case "03":
      description = "Comercio inválido";
      break;

    case "04":
      description = "Tarjeta recogida";
      break;

    case "09":
      description = "Solicitud en curso";
      break;

    case "12":
      description = "Transacción inválida";
      break;

    case "13":
      description = "Monto inválida";
      break;

    case "14":
      description = "Número de tarjeta inválido";
      break;

    case "15":
      description = "No existe Emisor";
      break;

    case "22":
      description = "Mal funcionamiento sospechoso";
      break;

    case "23":
      description = "Cargo transaccional no aceptado";
      break;

    case "33":
      description = "Tarjeta vencida, recogida";
      break;

    case "34":
      description = "Sospecha de fraude, recogida";
      break;

    case "36":
      description = "Tarjeta restringida, recogida";
      break;

    case "41":
      description = "Tarjeta perdida, recogida";
      break;

    case "43":
      description = "Tarjeta robada, recogida";
      break;

    case "45":
      description = "Cuenta cerrada";
      break;

    case "54":
      description = "Tarjeta vencida";
      break;

    case "56":
      description = "Tarjeta no existe";
      break;

    case "58":
      description = "Transacción no permitida al tarjetahabiente";
      break;

    case "57":
      description = "Transacción no permitida en terminal";
      break;

    case "59":
      description = "Sospecha de fraude";
      break;

    case "62":
      description = "Tarjeta restringida";
      break;

    case "65":
      description = "Excede frecuencia de retiro";
      break;

    case "98":
      description = "Excede límite de efectivo";
      break;

    case "CO":
      description = "PIN inaceptable";
      break;

    case "NF":
      description = "Tarjeta Inactiva";
      break;

    default:
      description = "Hubo un error, al intentar procesar cobro";
      break;
  }
  return description;
};

export const addHours = (date: string, hours: number) => {
  let dateIssue = new Date(date);
  let hoursDateIssue = dateIssue.getHours();
  let dateHoursAdded = dateIssue.setHours(hoursDateIssue + hours);
  return dateHoursAdded;
};
