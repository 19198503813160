// React imports
import { useState } from "react";

// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import InfiniteListCustomers from "../../components/infinite_lists/InfiniteListCustomers";
import CustomerFilterForm from "../../components/forms/customer/CustomerFilterForm";

// Hooks (react-router-dom") imports
import { useNavigate } from "react-router-dom";

// Custom types imports
import { CustomerByModel } from "../../features/customers/types";
import { fetchingModelDefaults } from "../../features/customers/types";

// Custom queries imports;
import { useCustomersInfinite } from "../../features/customers/useCustomersInfinite";

// Custom hooks Redux imports
import { useAppSelector } from "../../app/hook";

// Hooks & Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

function Clients() {
  const [cardTypeSelected, setCardTypeSelected] = useState<number | undefined>(
    undefined
  );
  const currentUser = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const methods = useForm<CustomerByModel>({
    defaultValues: {
      ...fetchingModelDefaults,
      storeID:
        currentUser.user?.companyID === -1 ? currentUser.user.companyID : 0,
    },
  });

  const customers = useCustomersInfinite({
    ...methods.watch(),
    idCard:
      cardTypeSelected && methods.watch("idCard")
        ? `00${cardTypeSelected}${methods.watch("idCard")}`
        : "",
  });

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Filtros"
        isToogle={true}
        defaultOpenValue={false}
      >
        <FormProvider {...methods}>
          <CustomerFilterForm
            cardTypeSelected={cardTypeSelected}
            setCardTypeSelected={setCardTypeSelected}
          />
        </FormProvider>
      </ContentWithToggle>

      <ContentWithToggle
        onBackButton={() => navigate("/")}
        title="Clientes"
        isToogle={false}
      >
        <InfiniteListCustomers customers={customers} />
      </ContentWithToggle>
    </div>
  );
}

export default Clients;
