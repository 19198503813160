// Hooks & actions imports
import { useAppSelector } from "../../../app/hook";

// Helpers () imports
import { toast } from "react-toastify";

// Custom queries imports
import { useForgotPasswordMutation } from "../../../features/auth/useForgotPasswordMutation";

function ForgotPassword() {
  const currentUser = useAppSelector((state) => state.auth);

  const forgotPasswordMutation = useForgotPasswordMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        autoClose: 5000,
        render: `Solicitud enviada a ${variables.email}`,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        autoClose: 5000,
        render:
          error.data?.description ||
          "Algo salio mal al intentar enviar solicitud",
      });
    }
  );

  return (
    <div className="w-full px-4 py-4 flex justify-between items-center">
      <span className="text-gray-600">Cambiar contraseña</span>
      <button
        type="button"
        className="button-third bg-blue-500 text-sm py-1 px-4"
        onClick={() => {
          const notificationID = toast.loading("Enviando solicitud...");
          forgotPasswordMutation.mutate({
            email: currentUser.user?.email || "",
            notificationID,
          });
        }}
      >
        Solicitar
      </button>
    </div>
  );
}
export default ForgotPassword;
