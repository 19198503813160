import Steps from "../../components/forms/payment_gateway/Steps";
import { Outlet } from "react-router-dom";

function PayForm() {
  return (
    <div className="flex items-start flex-wrap">
      <div className="mx-auto sm:rounded-[18px] w-full max-w-[576px] pt-5 bg-white sm:mt-[35px]">
        <h2 className="text-[28px] text-center font-bold">Realizar Pago</h2>
        <Steps />
        <Outlet />
      </div>
    </div>
  );
}

export default PayForm;
