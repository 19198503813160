// Custom helpers imports
import { isNumber, isUpperCase, isLowerCase } from "../../helpers";

// Third helpers imports
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert";

// Custom Configs imports
import api from "../../app/api";

// Custom types imports
import { ROLES } from "../users/types";
import { USER_STATUS } from "../users/types";

// Consts
export const DEVICE_ID = "DEVICE_ID";
export const USER_KEY = "User";
export const ALL_ROLES = [
  ROLES.Customer,
  ROLES.StoreEmployee,
  ROLES.StoreSupervisor,
  ROLES.CorporateEmployee,
  ROLES.CorporateAdmin,
];

interface USER_STATUS_INFO {
  description: string;
  background: string;
  color: string;
}

export function userStatusInfo(status: USER_STATUS): USER_STATUS_INFO {
  if (status === USER_STATUS.Active) {
    return {
      description: "Activo",
      background: "green-500",
      color: "white",
    };
  }

  if (status === USER_STATUS.Inactive) {
    return {
      description: "Inactivo",
      background: "red-500",
      color: "white",
    };
  }

  if (status === USER_STATUS.Suspended) {
    return {
      description: "Supendido",
      background: "gray-500",
      color: "white",
    };
  }

  if (status === USER_STATUS.Blocked) {
    return {
      description: "Bloqueado",
      background: "yellow-500",
      color: "black",
    };
  }

  return {
    description: "Sin estado",
    background: "gray-500",
    color: "text-white",
  };
}

export const roleDescription = (role: number): string => {
  const roles = [];
  if (isCorporateAdmin(role)) roles.push("Supervisor Administrativo");
  if (isCorporateEmployee(role)) roles.push("Empleado Administrativo");
  if (isStoreEmployee(role)) roles.push("Empleado de Comercio");
  if (isStoreSupervisor(role)) roles.push("Supervisor de Comercio");
  if (isCustomer(role)) roles.push("Cliente");
  if (roles.length === 1) {
    return roles[0];
  }
  return roles.join(", ").replace(/,(?=[^,]+$)/, " y");
};

export const validateMinLength = (
  value: string,
  minLength: number,
  message?: string
) => {
  return (
    value.length >= minLength ||
    message ||
    `Se requiere un minimo de ${minLength} caracteres`
  );
};

export const validateContainsNumber = (value: string, message?: string) => {
  let foundNumber = Array.from(value).find((character) => isNumber(character));
  return (
    !!foundNumber ||
    message ||
    "Se requiere que almenos un caracter sea un número"
  );
};

export const validateContainsUppercase = (value: string, message?: string) => {
  let foundUppercase = Array.from(value).find((character) =>
    isUpperCase(character)
  );
  return (
    !!foundUppercase ||
    message ||
    "Se requiere que almenos un caracter sea una mayuscula"
  );
};

export const validateContainsLowercase = (value: string, message?: string) => {
  let foundLowercase = Array.from(value).find((character) =>
    isLowerCase(character)
  );
  return (
    !!foundLowercase ||
    message ||
    "Se requiere que almenos un caracter sea una minuscula"
  );
};

export const validatePasswordConfirmation = (
  password: string,
  passwordConfirmation: string,
  message?: string
) => {
  return (
    password === passwordConfirmation ||
    message ||
    "Las contraseñas no coinciden"
  );
};

export const generateDeviceID = () => {
  let deviceID = localStorage.getItem(DEVICE_ID);
  if (!!deviceID) return deviceID;
  localStorage.setItem(DEVICE_ID, uuidv4());
  return localStorage.getItem(DEVICE_ID);
};

export const logoutAPI = async () => {
  const response = await api.post<any>(`auth/logout`);
  if (!response.ok || !response.data) {
    throw response;
  }
  return response.data;
};

export const confirmChange = (
  title: string,
  message: string,
  action: () => void
) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: "Cancelar",
        className: "button-secondary",
      },
      {
        label: "Aceptar",
        className: "button-primary",
        onClick: () => action(),
      },
    ],
  });
};

export const getAllCombinationsOfARole = (set: number[], role: number) => {
  const combinations = set.reduce(
    (a, v) => a.concat(a.map((d) => [v].concat(d))),
    [[role]]
  );
  const roles = combinations.map((combination) =>
    combination.reduce((a, b) => a + b, 0)
  );

  return roles;
};

export const getAllCombinationsOfARoles = (roles: ROLES[]) => {
  const possibleRoles = roles.map((role) => {
    const set = ALL_ROLES.filter((r) => r !== role);
    return getAllCombinationsOfARole(set, role);
  }).flat();

  let possibleRolesRemovingDuplications = possibleRoles.filter((item, index) => {
    return possibleRoles.indexOf(item) === index;
  });

  return possibleRolesRemovingDuplications;
};

export const isCustomer = (role: number) => {
  const possibleRoles = getAllCombinationsOfARole(
    [
      ROLES.StoreEmployee,
      ROLES.StoreSupervisor,
      ROLES.CorporateEmployee,
      ROLES.CorporateAdmin,
    ],
    ROLES.Customer
  );

  return possibleRoles.includes(role);
};

export const isStoreEmployee = (role: number) => {
  const possibleRoles = getAllCombinationsOfARole(
    [
      ROLES.Customer,
      ROLES.StoreSupervisor,
      ROLES.CorporateEmployee,
      ROLES.CorporateAdmin,
    ],
    ROLES.StoreEmployee
  );

  return possibleRoles.includes(role);
};

export const isStoreSupervisor = (role: number) => {
  const possibleRoles = getAllCombinationsOfARole(
    [
      ROLES.Customer,
      ROLES.StoreEmployee,
      ROLES.CorporateEmployee,
      ROLES.CorporateAdmin,
    ],
    ROLES.StoreSupervisor
  );

  return possibleRoles.includes(role);
};

export const isCorporateEmployee = (role: number) => {
  const possibleRoles = getAllCombinationsOfARole(
    [
      ROLES.Customer,
      ROLES.StoreEmployee,
      ROLES.StoreSupervisor,
      ROLES.CorporateAdmin,
    ],
    ROLES.CorporateEmployee
  );

  return possibleRoles.includes(role);
};

export const isCorporateAdmin = (role: number) => {
  const possibleRoles = getAllCombinationsOfARole(
    [
      ROLES.Customer,
      ROLES.StoreEmployee,
      ROLES.StoreSupervisor,
      ROLES.CorporateEmployee,
    ],
    ROLES.CorporateAdmin
  );

  return possibleRoles.includes(role);
};
