// Custom queries imports
import { useUpdatePaymentMethodMutation } from "../../../../features/payment_methods/useUpdatePaymentMethodMutation";

// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import EditPaymentMethodActions from "./EditPaymentMethodActions";
import DataPaymentMethodForm from "./DataPaymentMethodForm";

// Custom types imports
import { PaymentMethodN } from "../../../../features/payment_methods/types";

// Custom helpers imports
import { toast } from "react-toastify";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Redux state
import { useAppSelector } from "../../../../app/hook";

interface Props {
  paymentMethod: PaymentMethodN;
}

function EditPaymentMethodForm({ paymentMethod }: Props) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.user);

  const methods = useForm<PaymentMethodN>({
    defaultValues: paymentMethod,
  });

  const updatePaymentMethodByIdMutation = useUpdatePaymentMethodMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: `Metodo de pago actualizado`,
        autoClose: 5000,
      });
      navigate(`/${currentUser?.id}/metodos/m/${variables.data.id}`);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar actualizar el metodo de pago",
        autoClose: 5000,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    let notificationID = toast.loading("Actualizando metodo de pago...");
    updatePaymentMethodByIdMutation.mutate({
      data,
      notificationID,
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ContentWithToggle
          title={`Metodo de pago #${methods.watch("id")}`}
          onBackButton={() => navigate(`/${currentUser?.id}/metodos`)}
          isToogle={true}
        >
          <div className="sm:col-span-4 text-sm flex sm:flex-row flex-col flex-wrap sm:flex-nowrap sm:justify-center items-center bg-blue-500 text-white py-2 px-4">
            <i className="text-lg bi bi-info-circle-fill mr-4"></i>
            <p className="flex-1">
              La eliminación de un método de pago solo será posible directamente
              desde el servidor, esto con el fin de evitar conflictos en las
              relaciones de las entidades involucradas.
            </p>
          </div>
          <DataPaymentMethodForm />
        </ContentWithToggle>

        <EditPaymentMethodActions paymentMethod={methods.watch()} />
      </form>
    </FormProvider>
  );
}

export default EditPaymentMethodForm;
