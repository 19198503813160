// Custom queries imports
import { useSendInvoiceMutation } from "../../../../features/invoices/useSendInvoiceMutation";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import CopyLinkPayment from "../../../shared/CopyLinkPayment";

// Custom helpers imports
import { toast } from "react-toastify";

// Components (react-currency-input-field) imports
import CurrencyInput from "react-currency-input-field";

// Custom types imports
import { FormDataEditInvoice } from "../../../../features/invoices/types";
import {
  StatusName,
  invoiceStatus,
  invoiceStatusData,
} from "../../../../features/invoices/types";
import { Ticket } from "../../../../features/payments/types";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

interface Props {
  ticket: Ticket;
}

function DataInvoiceForm({ ticket }: Props) {
  const methods = useFormContext<FormDataEditInvoice>();
  const statusName = invoiceStatus[ticket.invoiceStatus];
  const minDate = methods.watch("invoice.minDate");
  const statusData = invoiceStatusData[statusName as StatusName];
  const date = methods.watch("invoice.date");
  const invoiceExpirationDate =
    minDate && new Date(minDate).toISOString().substring(0, 10);
  const linkPay = `${window.location.origin}/pagar/${methods.watch(
    "person.idCard"
  )}/c/${methods.watch("invoice.id")}?accessCode=${methods.watch(
    "invoice.accessCode"
  )}`;

  const sendInvoiceByEmailMutation = useSendInvoiceMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        render: `Notificación enviada a ${methods.watch("person.email")}`,
        autoClose: 5000,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar enviar la notificacion",
        autoClose: 5000,
      });
    }
  );
  return (
    <>
      <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">
          Enviar enlace de pago por correo electrónico
        </span>
        <button
          type="button"
          onClick={() => {
            let notificationID = toast.loading("Enviando enlace de pago...");
            sendInvoiceByEmailMutation.mutate({
              invoice: methods.watch("invoice"),
              notificationID,
            });
          }}
          className="button-third text-sm py-1 px-4"
        >
          Enviar
        </button>
      </div>

      <CopyLinkPayment linkPay={linkPay} />

      <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Código de accesoón</span>
        <b>{ticket.accessCode}</b>
      </div>
      <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Fecha de emisión</span>
        <b>
          {" "}
          {date
            ? new Date(date).toLocaleDateString("es-CO", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            : ""}
        </b>
      </div>

      <div
        className={`${statusData.color} text-white border-b w-full px-4 py-3 flex justify-between items-center`}
      >
        <span>Estado</span>
        <b>{statusData.description}</b>
      </div>
      <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
        <CustomLabel
          label="ID"
          id="id"
          required={false}
          className="text-sm sm:col-span-1"
          name="invoice.amount"
        >
          <input
            type="text"
            disabled
            placeholder="ID"
            className="input-base"
            value={methods.watch("invoice.id")}
          />
        </CustomLabel>

        <CustomLabel
          label="Nro. Factura"
          id="factura"
          required={true}
          className="text-sm sm:col-span-1"
        >
          <input
            type="text"
            placeholder="Factura de compra"
            className={`input-base ${
              methods.formState.errors.invoice?.storeInvoice
                ? "input-error"
                : ""
            } ${
              !methods.formState.errors.invoice?.storeInvoice &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("invoice.storeInvoice", {
              required: "Este campo es requerido",
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Vigencia"
          id="factura"
          required={false}
          className="text-sm sm:col-span-2"
        >
          <input
            type="date"
            disabled
            placeholder="Vigencia"
            className="input-base"
            value={invoiceExpirationDate}
          />
        </CustomLabel>

        <CustomLabel
          label="Precio de venta"
          id="factura"
          required={true}
          className="text-sm sm:col-span-2"
          name="invoice.amount"
        >
          <CurrencyInput
            decimalSeparator=","
            groupSeparator="."
            placeholder="Precio de venta"
            decimalsLimit={0}
            allowNegativeValue={false}
            allowDecimals={false}
            prefix="$ "
            value={methods.getValues("invoice.amount")}
            onValueChange={(value) =>
              methods.setValue("invoice.amount", Number(value) || 0)
            }
            className={`input-base ${
              methods.formState.errors.invoice?.amount ? "input-error" : ""
            } ${
              !methods.formState.errors.invoice?.amount &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
          />
        </CustomLabel>

        <CustomLabel
          label="IVA"
          id="iva"
          required={true}
          className="text-sm sm:col-span-2"
          name="invoice.iva"
        >
          <CurrencyInput
            decimalSeparator=","
            groupSeparator="."
            placeholder="IVA"
            decimalsLimit={0}
            allowNegativeValue={false}
            allowDecimals={false}
            prefix="$ "
            value={methods.getValues("invoice.iva")}
            onValueChange={(value) =>
              methods.setValue("invoice.iva", Number(value) || 0)
            }
            className={`input-base ${
              methods.formState.errors.invoice?.iva ? "input-error" : ""
            } ${
              !methods.formState.errors.invoice?.iva &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
          />
        </CustomLabel>

        <CustomLabel
          label="Descripcion"
          id="description"
          required={true}
          className="text-sm sm:col-span-4"
          name="invoice.description"
        >
          <textarea
            autoCorrect="off"
            className={`w-full input-base h-[80px] p-5 text-gray-primary ${
              methods.formState.errors.invoice?.description ? "input-error" : ""
            } ${
              !methods.formState.errors.invoice?.description &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("invoice.description", {
              required: "Este campo es requerido",
            })}
          ></textarea>
        </CustomLabel>
      </div>
    </>
  );
}
export default DataInvoiceForm;
