// Custom helpers imports
import { formatAmount } from "../../helpers";

// Custom types imports
import { FormDataEditInvoice } from "../../features/invoices/types";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

export default function SettlementTaxesAndCommissions() {
  const methods = useFormContext<FormDataEditInvoice>();
  const total = methods.watch("invoice.amount");
  const subTotal =
    methods.watch("invoice.amount") - methods.watch("invoice.iva");
  return (
    <>
      <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">SubTotal</span>
        <b className="text-gray-600">{formatAmount(subTotal)}</b>
      </div>
      <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">IVA</span>
        <b className="text-gray-600">
          {formatAmount(methods.watch("invoice.iva"))}
        </b>
      </div>
      <div className="bg-gray-600 text-white w-full px-4 py-3 flex justify-between items-center">
        <span>Total</span>
        <b>{formatAmount(total)}</b>
      </div>
      {/* <ContentWithToggle
        title="Total Deducción"
        isToogle={true}
        defaultOpenValue={false}
        actionButton={
          <b className="text-[#EA002A] mr-2">- {formatAmount(4675.78)}</b>
        }
      >
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Rete iva (15% del iva)</span>
          <span className="text-gray-600">{formatAmount(2394.9)}</span>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Rete fuente (1.5%)</span>
          <span className="text-gray-600">{formatAmount(2394.9)}</span>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Rete ica (0.414%)</span>
          <span className="text-gray-600">{formatAmount(2394.9)}</span>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Comision (0.8%)</span>
          <span className="text-gray-600">{formatAmount(2394.9)}</span>
        </div>
      </ContentWithToggle>
      <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <h3 className="font-medium text-xl">Total a cruzar</h3>
        <b className="text-gray-600">{formatAmount(2394.9)}</b>
      </div> */}
    </>
  );
}
