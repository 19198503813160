// Hooks (react-router-dom) imports
import { useNavigate, useParams } from "react-router-dom";

//Custom components imports
import ContentWithToggle from "../../../components/shared/ContentWithToggle";
import CurrentPlan from "../../../components/forms/store/CurrentPlan";
import ErrorLoading from "../../../components/shared/ErrorLoading";
import Loading from "../../../components/shared/Loading";
import Tabs from "../../../components/shared/Tabs";
import EmptyContent from "../../../components/shared/EmptyContent";

// Custom queries imports
import { usePlansByStore } from "../../../features/plans/usePlansByStore";

// Custom types imports
import { Tab } from "../../../components/shared/Tabs";
import { PlanStore as PlanStoreType } from "../../../features/plans/types";
import { UseQueryResult } from "@tanstack/react-query";

// Custom helpers imports
import { isStoreSupervisor } from "../../../features/auth/helpers";

// Hooks & actions imports
import { useAppSelector } from "../../../app/hook";

function RenderPlan({
  plan,
}: {
  plan: UseQueryResult<PlanStoreType[], unknown>;
}) {
  if (plan.isLoading) {
    return <Loading text="Cargando informacion de comercio..." />;
  }

  if (plan.isError || !plan.data) {
    return (
      <ErrorLoading text="¡Hubo un error al cargar informacion del comercio!" />
    );
  }

  if (plan.data.length === 0) {
    return <EmptyContent text="No hay plan asignado" />;
  }

  return <CurrentPlan plan={plan.data[0]} />;
}

function Plan() {
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const plan = usePlansByStore(currentUser.user?.companyID);

  const tabsStoreSupervisor: Tab[] = [
    { label: "General", url: `/${params.idUser}/configuraciones` },
    {
      label: "Seguridad",
      url: `/${params.idUser}/configuraciones/seguridad`,
      desactive: true,
    },
    {
      label: "Plan",
      url: `/${params.idUser}/configuraciones/plan`,
    },
  ];

  const tabsNormal: Tab[] = [
    { label: "General", url: `/${params.idUser}/configuraciones` },
    {
      label: "Seguridad",
      url: `/${params.idUser}/configuraciones/seguridad`,
      desactive: true,
    },
  ];

  if (!currentUser.user?.roles) return null;

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Plan"
        onBackButton={() => navigate(`/${params.idUser}/escritorio`)}
      >
        <Tabs
          tabs={
            isStoreSupervisor(currentUser.user.roles)
              ? tabsStoreSupervisor
              : tabsNormal
          }
        />
        <RenderPlan plan={plan} />
      </ContentWithToggle>
    </div>
  );
}
export default Plan;
