// Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";
import ContentWithToggle from "../../shared/ContentWithToggle";
import TotalInvoiceAmount from "../../shared/TotalInvoiceAmount";
import InvoiceActions from "./InvoiceActions";

// Third components imports
import CurrencyInput from "react-currency-input-field";

// Hooks & components (react-router-dom) imports
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../features/card_types/types";
import { Ticket } from "../../../features/payments/types";
import { TAX_REGIME_INFO } from "../../../features/stores/types";

import { taxRegimeMock } from "../../../features/stores/mocks";

// Hooks Redux imports
import { useAppSelector } from "../../../app/hook";

// Third helpers imports
import { toast } from "react-toastify";

interface Prop {
  ticket: Ticket;
}

function InvoiceInformation({ ticket }: Prop) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const minDate = ticket.minDate;
  const linkPay = `${window.location.origin}/pagar/${params.idCliente}/c/${
    params.idCobro
  }?accessCode=${searchParams.get("accessCode")}`;
  const invoiceExpirationDate =
    minDate && new Date(minDate).toISOString().substring(0, 10);
  return (
    <>
      <ContentWithToggle
        title={`Detalle de cobro #${ticket.id}`}
        defaultOpenValue={false}
        onBackButton={() =>
          navigate(
            `/pagar/${params.idCliente}/${
              currentUser.isAuthenticated ? "cobros" : "cobrosPublicos"
            }`
          )
        }
        isToogle={true}
      >
        {!currentUser.isAuthenticated && (
          <div className="text-sm flex flex-col sm:flex-row sm:justify-center items-center bg-blue-500 text-white py-2 px-4">
            <i className="text-2xl sm:text-lg bi bi-info-circle-fill mr-4"></i>
            <p className="my-2 sm:my-0 text-center w-full sm:text-left sm:w-[calc(100%-200px)]">
              ¿Tienes accesos a tu cuenta?, puedes restablecer tu contraseña en
              cualquier momento
            </p>
            <button
              tabIndex={-1}
              className="mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
              onClick={() => navigate("/account/requestResetPassword")}
            >
              Restablecer
            </button>
          </div>
        )}
        <div className="border-b text-gray-500 w-full px-4 py-3 flex flex-col">
          <span>Copia y comparte el enlace de pago</span>
          <div className="w-full mt-2 flex text-sm">
            <input
              disabled
              type="text"
              className="text-sm input-base bg-gray-100 rounded-r-none border-r-0"
              value={linkPay}
            />
            <button
              className="text-2xl button-primary rounded-lg rounded-l-none py-0 h-auto"
              type="button"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(linkPay);
                  toast.success("Enlace copiado");
                } catch (error) {
                  toast.error("Algo salio mal al intentar copiar enlace");
                }
              }}
            >
              <i className="bi bi-link-45deg"></i>
            </button>
          </div>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Código de acceso</span>
        <b>{ticket.accessCode}</b>
      </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Fecha de emisión</span>
          <b>
            {" "}
            {ticket.date
              ? new Date(ticket.date).toLocaleDateString("es-CO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : ""}
          </b>
        </div>
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="ID"
            id="id"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="ID"
              className="input-base"
              value={ticket.id}
            />
          </CustomLabel>

          <CustomLabel
            label="Nro. Factura"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Factura de compra"
              className="input-base"
              value={ticket.storeInvoice}
            />
          </CustomLabel>

          <CustomLabel
            label="Precio de venta"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <CurrencyInput
              decimalSeparator=","
              groupSeparator="."
              id="amount"
              name="amount"
              placeholder="Precio de venta"
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              disabled
              prefix="$ "
              value={ticket.amount}
              className="input-base"
            />
          </CustomLabel>

          <CustomLabel
            label="Vigencia"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="date"
              disabled
              placeholder="Vigencia"
              className="input-base"
              value={invoiceExpirationDate}
            />
          </CustomLabel>

          <CustomLabel
            label="Descripcion"
            id="description"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <textarea
              autoCorrect="off"
              disabled
              defaultValue={ticket?.description}
              className="w-full input-base h-[80px] p-5 text-gray-primary"
            ></textarea>
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del comercio"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={CARD_TYPES_INFO[2]?.description}
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="taxIDNumber"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.store?.taxIDNumber}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombre de comercio"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Razon social"
              className="input-base"
              value={ticket.store?.storeName || ""}
            />
          </CustomLabel>

          {!!ticket.store?.taxCondition && (
            <CustomLabel
              label="Régimen tributario"
              id="taxCondition"
              required={false}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  className="input-base appearance-none"
                  disabled
                  value={ticket.store.taxCondition}
                >
                  {taxRegimeMock?.map((taxRegime) => (
                    <option value={taxRegime} key={taxRegime}>
                      {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                      {TAX_REGIME_INFO[taxRegime]?.description}
                    </option>
                  ))}
                </select>
              </div>
            </CustomLabel>
          )}
        </div>
      </ContentWithToggle>

      <TotalInvoiceAmount
        status={ticket.invoiceStatus}
        amount={ticket.amount}
      />
      <InvoiceActions ticket={ticket} />
    </>
  );
}

export default InvoiceInformation;
