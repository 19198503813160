// (react-hook-form) imports
import { UseFormReturn } from "react-hook-form";

// Custom types imports
import { FormDataCreateStore } from "../../../../features/stores/types";
import { CreateUserFormData } from "../../../../features/roster/types";
import {
  CARD_TYPES_INFO,
  CardTypes as CardTypesEnum,
} from "../../../../features/card_types/types";
import { TAX_REGIME_INFO } from "../../../../features/stores/types";
import { taxRegimeMock } from "../../../../features/stores/mocks";

// Custom queries imports
import { useCardTypes } from "../../../../features/card_types/useCardTypes";
import { useProvicesByCountry } from "../../../../features/provinces/useProvicesByCountry";
import { useCitiesByProvince } from "../../../../features/cities/useCitiesByProvince";
import { usePlan } from "../../../../features/plans/usePlan";
import { useCreateOperationalStoreMutation } from "../../../../features/stores/useCreateOperationalStoreMutation";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import ContentWithToggle from "../../../shared/ContentWithToggle";
import DetailsPlan from "../DetailsPlan";
import Loading from "../../../shared/Loading";
import { toast } from "react-toastify";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hook";

interface Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  planSelected: number | undefined;
  methodsUserForm: UseFormReturn<CreateUserFormData, any>;
  methodsStoreForm: UseFormReturn<FormDataCreateStore, any>;
  provinceSelected: number | undefined;
  setProvinceSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function Summary({
  step,
  setStep,
  planSelected,
  methodsUserForm,
  methodsStoreForm,
  provinceSelected,
  setProvinceSelected,
}: Props) {
  const cardTypes = useCardTypes();
  const provinces = useProvicesByCountry(170);
  const cities = useCitiesByProvince(provinceSelected);
  const plan = usePlan(planSelected);
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);

  const createOperationalStoreMutation = useCreateOperationalStoreMutation({
    data: {
      store: methodsStoreForm.watch(),
      person: methodsUserForm.watch("person"),
      user: methodsUserForm.watch("user"),
      role: methodsUserForm.watch("roleToAdd"),
      planID: planSelected || 0,
    },
    onSuccessMutation: (companyID) => {
      navigate(`/${currentUser.user?.id}/comercios/c/${companyID}`);
    },
  });
  return (
    <>
      <ContentWithToggle
        title="Información del comercio"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                value={CardTypesEnum.numeroIdentificacionTributaria}
                disabled
                className="input-base appearance-none"
              >
                {cardTypes.data?.map((cardType) => (
                  <option value={cardType} key={cardType}>
                    {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                    {CARD_TYPES_INFO[cardType]?.description}
                  </option>
                ))}
              </select>
            </div>
          </CustomLabel>

          <CustomLabel
            label="No. de documento"
            id="taxIDNumber"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              min={0}
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={methodsStoreForm.watch("taxIDNumber")}
            />
          </CustomLabel>

          <CustomLabel
            label="Razon social"
            id="storeName"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <input
              type="text"
              disabled
              placeholder="Razon social"
              maxLength={60}
              className="input-base"
              value={methodsStoreForm.watch("storeName")}
            />
          </CustomLabel>

          <CustomLabel
            label="Régimen tributario"
            id="taxCondition"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled
                className="input-base appearance-none "
                value={methodsStoreForm.watch("taxCondition")}
              >
                <option value="">Seleccionar</option>
                {taxRegimeMock?.map((taxRegime) => (
                  <option value={taxRegime} key={taxRegime}>
                    {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                    {TAX_REGIME_INFO[taxRegime]?.description}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Departamento"
            id="province"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled
                className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                value={provinceSelected}
                onChange={(event) =>
                  setProvinceSelected(Number(event.currentTarget.value))
                }
              >
                <option value="0">Todas</option>
                {provinces.data?.map((provice) => (
                  <option value={provice.id} key={provice.id}>
                    {provice?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Ciudad"
            id="cityID"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled
                className="input-base pr-[40px] overflow-hidden appearance-none"
                value={methodsStoreForm.watch("cityID")}
              >
                <option value="">Todas</option>
                {cities.data?.map((city) => (
                  <option value={city.id} key={city.id}>
                    {city?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Teléfono"
            id="phone"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="Télefono"
              className="input-base"
              value={methodsStoreForm.watch("phone")}
            />
          </CustomLabel>

          <CustomLabel
            label="Correo electronico"
            id="phone"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <input
              type="email"
              disabled
              placeholder="nombre@correo.com"
              className="input-base"
              value={methodsStoreForm.watch("email")}
            />
          </CustomLabel>

          <CustomLabel
            label="Dirección"
            id="address"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <input
              type="text"
              disabled
              placeholder="Dirección"
              className="input-base"
              value={methodsStoreForm.watch("address")}
            />
          </CustomLabel>

          <CustomLabel
            label="Segunda dirección"
            id="address2"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <input
              type="text"
              disabled
              placeholder="Dirección"
              className="input-base"
              value={methodsStoreForm.watch("address2")}
            />
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del Supervisor"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
          <CustomLabel
            label="Tipo documento"
            id="idCardType"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled
                className="input-base pr-[40px] overflow-hidden appearance-none"
                value={methodsUserForm.watch("person.idCardType")}
              >
                {cardTypes.data?.map((cardType) => (
                  <option value={cardType} key={cardType}>
                    {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                    {CARD_TYPES_INFO[cardType]?.description}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="idCardValue"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={methodsUserForm.watch("person.idCardValue")}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombres"
            id="nombres"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              disabled
              type="text"
              maxLength={20}
              placeholder="Nombres"
              className="input-base"
              value={methodsUserForm.watch("person.firstName")}
            />
          </CustomLabel>

          <CustomLabel
            label="Apellidos"
            id="apellidos"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              disabled
              type="text"
              placeholder="Apellidos"
              maxLength={20}
              className="input-base"
              value={methodsUserForm.watch("person.lastName")}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombre completo"
            id="name"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <input
              type="text"
              disabled
              placeholder="Nombre completo"
              className="input-base"
              value={`${methodsUserForm.watch(
                "person.firstName"
              )} ${methodsUserForm.watch("person.lastName")}`}
            />
          </CustomLabel>

          <CustomLabel
            label="Fecha de nacimiento"
            id="birthDate"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              disabled
              type="date"
              className="input-base"
              value={methodsUserForm.watch("person.birthDate")}
            />
          </CustomLabel>

          <CustomLabel
            label="Telefono"
            id="phoneNumberValue"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              disabled
              type="number"
              placeholder="Telefono"
              className="input-base"
              value={methodsUserForm.watch("person.phoneNumberValue")}
            />
          </CustomLabel>

          <CustomLabel
            label="Correo electronico"
            id="phone"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <input
              type="email"
              disabled
              placeholder="nombre@correo.com"
              className="input-base"
              value={methodsUserForm.watch("person.email")}
            />
          </CustomLabel>

          <CustomLabel
            label="Rol del usuario"
            id="role"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled
                className="input-base pr-[40px] overflow-hidden appearance-none"
                value={methodsUserForm.watch("roleToAdd")}
              >
                <option value="2">Empleado</option>
                <option value="4">Supervisor</option>
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Nombre de usuario"
            id="username"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              disabled
              type="text"
              placeholder="Nombre de usuario"
              className="input-base"
              value={methodsUserForm.watch("user.userName")}
            />
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Plan seleccionado"
        isToogle={true}
        defaultOpenValue={false}
      >
        {plan.isLoading || !plan.data ? (
          <Loading text="Cargando plan seleccionado..." />
        ) : (
          <div className="w-full px-4 py-3">
            <div className="w-full p-3 bg-slate-100 cursor-pointer`">
              <div className="flex w-full">
                <div className="w-full">
                  <p className="flex items-center pl-3 h-[30px] pr-2 w-full truncate font-medium">
                    {plan.data?.description}
                  </p>
                  <DetailsPlan plan={plan.data} />
                </div>
              </div>
            </div>
          </div>
        )}
      </ContentWithToggle>

      <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
        <button
          onClick={() => setStep(step - 1)}
          type="button"
          className="button-secondary  text-sm py-2 px-4"
        >
          Anterior
        </button>
        <button
          onClick={() => {
            const notificationID = toast.loading(
              "Validando email del usuario..."
            );
            createOperationalStoreMutation.mutate({
              email: methodsUserForm.watch("person.email"),
              notificationID,
            });
          }}
          type="button"
          className="button-primary text-sm py-2 px-4"
        >
          Confirmar
        </button>
      </div>
    </>
  );
}
export default Summary;
