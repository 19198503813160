// Custom components imports
import Loading from "./shared/Loading";
import EmptyContent from "./shared/EmptyContent";
import ErrorLoading from "./shared/ErrorLoading";
import PaymentMethodCard from "./cards/PaymentMethodCard";

// Hooks (react-router-dom) imports
import { UseQueryResult } from "@tanstack/react-query";

// Custom types imports
import { PaymentMethodN } from "../features/payment_methods/types";

interface Props {
  paymentMethods: UseQueryResult<PaymentMethodN[], unknown>;
}

function ListPaymentMethods({ paymentMethods }: Props) {
  if (paymentMethods.isLoading) {
    return <Loading text="Cargando métodos de pago..." />;
  }

  if (paymentMethods.isError || !paymentMethods.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar metodos de pago" />
        </div>
      </div>
    );
  }

  if (paymentMethods.data.length === 0) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <EmptyContent text="No hay metodos de pago" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col divide-y">
      <div className="border-t w-full px-4 py-3 flex justify-between items-center">
        <span className="text-gray-600">Número de métodos de pago</span>
        <div className="w-1/2 text-end">
          <b>{paymentMethods.data.length}</b>
        </div>
      </div>
      {paymentMethods.data.map((paymentMethod) => (
        <PaymentMethodCard paymentMethod={paymentMethod} link={`m/${paymentMethod.id}`} key={paymentMethod.id} />
      ))}
    </div>
  );
}

export default ListPaymentMethods;
