export interface Token {
  id: number;
  value: string;
  description: string;
  onModified: string;
  vStatus: number;
  expiredBy: string;
}

export interface User {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  birthCountryID: number;
  birthDate: string;
  onModified: string;
  vStatus: number;
  idCard: string;
  phoneNumber: string;
  phoneCountry: number;
  phoneNumberValue: string;
  userName: string;
  email: string;
  companyID: number;
  roles: number;
  loginStatus: number;
  userStatus: USER_STATUS;
  emailConfirmed: boolean;
}

export interface UserRegister {
  maxItems: number;
  modifiedAfter: string;
  expiredBy: string;
  vStatus: number;
  userName: string;
  idCard: string;
  token: string;
  password: string;
  confirmPassword: string;
  login: boolean;
}

export interface NewlyRegisteredUser extends User {
  token: Token;
}

export enum ROLES {
  Customer = 1,
  StoreEmployee = 2,
  StoreSupervisor = 4,
  CorporateEmployee = 8,
  CorporateAdmin = 16,
}

export enum USER_STATUS {
  NotCreated = 0,
  Active = 1,
  Inactive = 2,
  Suspended = 4,
  Blocked = 8,
}
