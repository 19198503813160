// Configs imports
import api from "../../app/api";

// Custom types imports
import { Store } from "./types";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

export function useStore(id: number) {
  async function getStoreById(id: number): Promise<Store> {
    const response = await api.get<Store>(`admin/stores/${id}`);
    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Store", id],
    queryFn: () => getStoreById(id),
    enabled: !!id,
  });
}
