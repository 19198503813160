// Custom components imports
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import ListPlans from "../../components/ListPlans";

// Custom queries imports
import { usePlans } from "../../features/plans/usePlans";

// Hooks (react-router-dom") imports
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Planes() {
  const params = useParams();
  const navigate = useNavigate();

  const plans = usePlans();

  return (
    <div className="w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        onBackButton={() => navigate(`/${params.idUser}/escritorio`)}
        title="Planes"
      >
        <ListPlans plans={plans} />
      </ContentWithToggle>
    </div>
  );
}

export default Planes;
