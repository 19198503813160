// React imports
import { useEffect } from "react";

//Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import CustomLabel from "../../../shared/fields/CustomLabel";
import CustomEmailField from "../../../shared/fields/CustomEmailField";
import CustomPasswordField from "../../../shared/fields/CustomPasswordField";
import PasswordValidator from "../../../PasswordValidator";

// Custom types imports
import { CreateUserFormData } from "../../../../features/roster/types";
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { useCardTypes } from "../../../../features/card_types/useCardTypes";

// Custom hooks imports
import { useValidRosterMutation } from "../../../../features/stores/useValidRosterMutation";

// Custom helpers imports
import {
  validateMinLength,
  validateContainsUppercase,
  validateContainsNumber,
  validateContainsLowercase,
  validatePasswordConfirmation,
} from "../../../../features/auth/helpers";

// (react-hook-form) imports
import { FormProvider, UseFormReturn } from "react-hook-form";
import { toast } from "react-toastify";

interface Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  methods: UseFormReturn<CreateUserFormData, any>;
}

function DataRosterForm({ step, setStep, methods }: Props) {
  const password = methods.watch("user.password");
  const person = methods.watch("person");
  const cardTypes = useCardTypes();

  const validRosterMutation = useValidRosterMutation({
    data: {
      person: methods.watch("person"),
      user: methods.watch("user"),
    },
    onSuccessMutation: () => {
      setStep(step + 1);
    },
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      const idCard = `00${person.idCardType}${person.idCardValue}`;
      methods.setValue("person.idCard", idCard);
      methods.setValue("user.idCard", idCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.idCardType, person.idCardValue]);

  useEffect(() => {
    methods.setValue("person.name", `${person.firstName} ${person.lastName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.firstName, person.lastName]);

  useEffect(() => {
    methods.setValue(
      "person.phoneNumber",
      `${person.phoneCountry}${person.phoneNumberValue}`
    );
    methods.setValue("person.phoneCountry", 170);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.phoneNumberValue, person.phoneCountry]);

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Validando correo electronico...");
    validRosterMutation.mutate({
      email: data.person.email,
      notificationID,
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ContentWithToggle title="Datos del Supervisor" isToogle={false}>
          <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
            <CustomLabel
              label="Tipo documento"
              id="idCardType"
              required={true}
              className="text-sm sm:col-span-2"
              name="person.idCardType"
            >
              <div className="w-full flex items-center relative">
                <select
                  className={`input-base pr-[40px] overflow-hidden appearance-none ${
                    methods.formState.errors.person?.idCardType
                      ? "input-error"
                      : ""
                  } ${
                    !methods.formState.errors.person?.idCardType &&
                    methods.formState.isSubmitted
                      ? "input-valid"
                      : ""
                  } `}
                  {...methods.register("person.idCardType", {
                    required: "Este campo es requerido",
                    valueAsNumber: true,
                  })}
                >
                  {cardTypes.data?.map((cardType) => (
                    <option value={cardType} key={cardType}>
                      {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                      {CARD_TYPES_INFO[cardType]?.description}
                    </option>
                  ))}
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>

            <CustomLabel
              label="No. De documento"
              id="idCardValue"
              required={true}
              className="text-sm sm:col-span-2"
              name="person.idCardValue"
            >
              <input
                type="number"
                placeholder="No. De documento"
                className={`input-base ${
                  methods.formState.errors.person?.idCardValue
                    ? "input-error"
                    : ""
                } ${
                  !methods.formState.errors.person?.idCardValue &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("person.idCardValue", {
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 12,
                    message: "El número máximo de caracteres es 12",
                  },
                  minLength: {
                    value: 6,
                    message: "El número minimo de caracteres es 6",
                  },
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Nombres"
              id="nombres"
              required={true}
              className="text-sm sm:col-span-2"
              name="person.firstName"
            >
              <input
                type="text"
                maxLength={20}
                placeholder="Nombres"
                className={`input-base ${
                  methods.formState.errors.person?.firstName
                    ? "input-error"
                    : ""
                } ${
                  !methods.formState.errors.person?.firstName &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("person.firstName", {
                  required: "Este campo es requerido",
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Apellidos"
              id="apellidos"
              required={true}
              className="text-sm sm:col-span-2"
              name="person.lastName"
            >
              <input
                type="text"
                placeholder="Apellidos"
                maxLength={20}
                className={`input-base ${
                  methods.formState.errors.person?.lastName ? "input-error" : ""
                } ${
                  !methods.formState.errors.person?.lastName &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("person.lastName", {
                  required: "Este campo es requerido",
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Nombre completo"
              id="name"
              required={false}
              className="text-sm sm:col-span-4"
            >
              <input
                type="text"
                disabled
                placeholder="Nombre completo"
                className="input-base"
                value={`${methods.watch("person.firstName")} ${methods.watch(
                  "person.lastName"
                )}`}
              />
            </CustomLabel>

            <CustomLabel
              label="Fecha de nacimiento"
              id="birthDate"
              required={false}
              className="text-sm sm:col-span-2"
              name="person.birthDate"
            >
              <input
                type="date"
                className={`input-base ${
                  methods.formState.errors.person?.birthDate
                    ? "input-error"
                    : ""
                } ${
                  !methods.formState.errors.person?.birthDate &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("person.birthDate", {
                  required: "Este campo es requerido",
                })}
              />
            </CustomLabel>

            <CustomLabel
              label="Telefono"
              id="phoneNumberValue"
              required={false}
              className="text-sm sm:col-span-2"
              name="person.phoneNumberValue"
            >
              <input
                type="number"
                placeholder="Telefono"
                className={`input-base ${
                  methods.formState.errors.person?.phoneNumberValue
                    ? "input-error"
                    : ""
                } ${
                  !methods.formState.errors.person?.phoneNumberValue &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("person.phoneNumberValue", {
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 10,
                    message: "El número máximo de caracteres es 10",
                  },
                  minLength: {
                    value: 10,
                    message: "El número minimo de caracteres es 10",
                  },
                })}
              />
            </CustomLabel>

            <CustomEmailField
              placeholder="nombre@correo.com"
              id="person.email"
              required={true}
              label="Correo electrónico"
              name="person.email"
              classNameLabel="text-sm sm:col-span-4"
            />

            <CustomLabel
              label="Rol del usuario"
              id="role"
              required={true}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  disabled
                  className="input-base pr-[40px] overflow-hidden appearance-none"
                  value={methods.watch("roleToAdd")}
                >
                  <option value="2">Empleado</option>
                  <option value="4">Supervisor</option>
                </select>
                <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
              </div>
            </CustomLabel>

            <CustomLabel
              label="Nombre de usuario"
              id="username"
              required={true}
              className="text-sm sm:col-span-2"
              name="user.userName"
            >
              <input
                type="text"
                placeholder="Nombre de usuario"
                className={`input-base ${
                  methods.formState.errors.user?.userName ? "input-error" : ""
                } ${
                  !methods.formState.errors.user?.userName &&
                  methods.formState.isSubmitted
                    ? "input-valid"
                    : ""
                } `}
                {...methods.register("user.userName", {
                  required: "Este campo es requerido",
                  maxLength: {
                    value: 20,
                    message: "El número máximo de caracteres es 20",
                  },
                  minLength: {
                    value: 8,
                    message: "El número minimo de caracteres es 8",
                  },
                  validate: {
                    hasNumber: (value: string) => validateContainsNumber(value),
                    hasUppercase: (value: string) =>
                      validateContainsUppercase(value),
                    hasLowercase: (value: string) =>
                      validateContainsLowercase(value),
                  },
                })}
              />
            </CustomLabel>

            <CustomPasswordField
              placeholder="Contraseña"
              id="password"
              required={true}
              label="Contraseña"
              name="user.password"
              classNameLabel="text-sm sm:col-span-2"
              validate={{
                minLength: (value: string) => validateMinLength(value, 8),
                hasNumber: (value: string) => validateContainsNumber(value),
                hasUppercase: (value: string) =>
                  validateContainsUppercase(value),
                hasLowercase: (value: string) =>
                  validateContainsLowercase(value),
              }}
            />

            <CustomPasswordField
              placeholder="Confirmar contraseña"
              id="confirmPassword"
              required={true}
              label="Confirmar contraseña"
              name="user.confirmPassword"
              classNameLabel="text-sm sm:col-span-2"
              validate={{
                isConfirmed: (value: string) =>
                  validatePasswordConfirmation(value, password),
              }}
            />

            <div className="sm:col-span-4">
              <PasswordValidator name="user.password" inDrawer={true} />
            </div>
            <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
              <button
                onClick={() => setStep(step - 1)}
                type="button"
                className="button-secondary  text-sm py-2 px-4"
              >
                Anterior
              </button>
              <button
                type="submit"
                className="button-primary text-sm py-2 px-4"
              >
                Siguiente
              </button>
            </div>
          </div>
        </ContentWithToggle>
      </form>
    </FormProvider>
  );
}
export default DataRosterForm;
