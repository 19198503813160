// Hooks (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom types imports
import { PaymentMethodN } from "./types";

// Configs imports
import api from "../../app/api";

export function usePaymentMethods() {
  async function getPaymentMethods(): Promise<PaymentMethodN[]> {
    const response = await api.get<PaymentMethodN[]>("admin/paymentmethods");

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["PaymentMethods"],
    queryFn: () => getPaymentMethods(),
  });
}
