// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { NewlyRegisteredPerson, PersonRegistration } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  person: PersonRegistration;
  notificationID: string | number;
}

export function useCreatePersonMutation(
  onSuccessMutation?: (
    data: NewlyRegisteredPerson,
    variables: paramsType
  ) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function createPerson(
    params: paramsType
  ): Promise<NewlyRegisteredPerson> {
    const response = await api.post<NewlyRegisteredPerson>(
      "account/registerperson",
      params.person
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: createPerson,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
