// Custom types imports
import { Ticket } from "../../../../features/payments/types";

// Hooks (React Router Dom) imports
import { useNavigate } from "react-router-dom";

// Hooks Redux imports
import { useAppSelector } from "../../../../app/hook";

// Custom components imports
import InvoiceInformation from "./InvoiceInformation";
import PaymentInformation from "./PaymentInformation";
import StatusHeder from "./StatusHeder";
import TotalInvoiceAmount from "../../../shared/TotalInvoiceAmount";

interface Props {
  ticket: Ticket;
}

function RenderStatus({ ticket }: Props) {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  return (
    <div className="flex items-center flex-col w-full mx-auto bg-white pb-4 rounded-lg overflow-hidden">
      <StatusHeder status={ticket.invoiceStatus} />
      {ticket.invoiceStatus > 1 && ticket.invoiceStatus !== 3 ? (
        <PaymentInformation ticket={ticket} />
      ) : (
        <InvoiceInformation ticket={ticket} />
      )}
      <TotalInvoiceAmount
        amount={ticket.amount}
        status={ticket.invoiceStatus}
      />
      <div className="w-full pt-4 flex justify-center">
        <button
          onClick={() => {
            navigate(
              `/pagar/${ticket.idCard}/${
                currentUser.isAuthenticated ? "cobros" : "cobrosPublicos"
              }`
            );
          }}
          className="button-primary w-[270px] mx-auto h-[49px]"
        >
          Realizar otro pago
        </button>
      </div>
    </div>
  );
}

export default RenderStatus;
