// React imports
import { useState } from "react";

// Third components imports
import CustomLabel from "../../shared/fields/CustomLabel";

// Hooks & Components Redux imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { CustomerByModel } from "../../../features/customers/types";

// Custom queries imports
import { useProvicesByCountry } from "../../../features/provinces/useProvicesByCountry";
import { useCitiesByProvince } from "../../../features/cities/useCitiesByProvince";
import { useCardTypes } from "../../../features/card_types/useCardTypes";

// Custom types imports
import { CARD_TYPES_INFO } from "../../../features/card_types/types";

interface Props {
  cardTypeSelected: number | undefined;
  setCardTypeSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function CustomerFilterForm({
  cardTypeSelected,
  setCardTypeSelected,
}: Props) {
  const methods = useFormContext<CustomerByModel>();
  const [provinceSelected, setProvinceSelected] = useState<number | undefined>(
    undefined
  );

  const provinces = useProvicesByCountry(170);
  const cities = useCitiesByProvince(provinceSelected);
  const cardTypes = useCardTypes();

  return (
    <form>
      <div className="border-b w-full flex flex-col divide-y">
        <div className="w-full px-4 py-3 flex flex-wrap justify-between items-center">
          <CustomLabel
            label="Departamento"
            id="departamento"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={provinces.isLoading || !provinces.data}
                className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                value={provinceSelected}
                onChange={(event) =>
                  setProvinceSelected(Number(event.currentTarget.value))
                }
              >
                <option value="">Todas</option>
                {provinces.data?.map((provice) => (
                  <option value={provice.id} key={provice.id}>
                    {provice?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Ciudad"
            id="storeCityID"
            required={false}
            className="text-sm w-full sm:w-[48%]"
            name="storeCityID"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  provinces.isLoading || cities.isLoading || !cities.data
                }
                className="input-base pr-[40px] overflow-hidden appearance-none"
                {...methods.register("storeCityID", {
                  valueAsNumber: true,
                })}
              >
                <option value="0">Todas</option>
                {cities.data?.map((city) => (
                  <option value={city.id} key={city.id}>
                    {city?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Tipos de  documento"
            id="tipoDocumento"
            required={false}
            className="text-sm w-full sm:w-[48%]"
          >
            <div className="w-full flex items-center relative">
              <select
                className="input-base pr-[40px] overflow-hidden appearance-none"
                disabled={cardTypes.isLoading || !cardTypes.data}
                value={cardTypeSelected}
                onChange={(event) => {
                  setCardTypeSelected(Number(event.currentTarget.value));
                }}
              >
                <option value="">Todos</option>
                {cardTypes.data?.map((cardType) => (
                  <option value={cardType} key={cardType}>
                    {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                    {CARD_TYPES_INFO[cardType]?.description}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="idCard"
            required={false}
            className="text-sm w-full sm:w-[48%]"
            name="idCard"
          >
            <input
              type="number"
              disabled={
                cardTypes.isLoading || !cardTypes.data || !cardTypeSelected
              }
              maxLength={12}
              min={0}
              placeholder="No. De documento"
              className="input-base"
              {...methods.register("idCard")}
            />
          </CustomLabel>

        </div>
      </div>
    </form>
  );
}

export default CustomerFilterForm;
