// Custom components imports
import EditStoreForm from "./EditStoreForm";
import Loading from "../../../shared/Loading";
import ErrorLoading from "../../../shared/ErrorLoading";

// Custom queries imports
import { useStore } from "../../../../features/stores/useStore";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditStore() {
  const params = useParams();

  const store = useStore(Number(params.idStore));

  if (store.isLoading) {
    return <Loading text="Cargando informacion de comercio..." />;
  }

  if (store.isError || !store.data) {
    return (
      <ErrorLoading text="¡Hubo un error al cargar informacion del comercio!" />
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <EditStoreForm store={store.data} />
    </div>
  );
}

export default EditStore;
