import CustomLabel from "./shared/fields/CustomLabel";
import api from "../app/api";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loadUser } from "../features/auth/authSlice";
import { generateDeviceID, USER_KEY } from "../features/auth/helpers";

type FormData = {
  code: string;
};

function ValidCodeAccess() {
  const methods = useForm<FormData>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  const onSubmit = methods.handleSubmit(async (data) => {
    const notificationID = toast.loading("Iniciando sesión...");
    const deviceID = generateDeviceID();
    const response = await api.post<any>("auth/loginaccesscode", {
      modifiedAfter: new Date().toISOString(),
      expiredBy: new Date().toISOString(),
      userOrEmail: `@${searchParams.get("idCard")}`,
      code: data.code,
      deviceID,
    });
    if (!response.ok) {
      toast.update(notificationID, {
        isLoading: false,
        type: "error",
        render:
          response.data?.description ||
          "Algo salio mal al intentar iniciar de sesión",
        autoClose: 5000,
      });
      throw response;
    }
    localStorage.setItem(USER_KEY, JSON.stringify(response.data));
    dispatch(loadUser(response.data));
    navigate(`/pagar/${searchParams.get("idCard")}/cobros`);
    toast.update(notificationID, {
      isLoading: false,
      type: "success",
      render: "Inicio de sesión satisfactorio",
      autoClose: 5000,
    });
  });
  return (
    <div className="flex items-start flex-wrap">
      <div className="mx-auto sm:rounded-[18px] w-full max-w-[576px] px-8 sm:px-[60px] py-5 bg-white sm:mt-[35px]">
        <h2 className="text-[28px] text-center font-bold">
          Validar codigo de acceso
        </h2>
        <div className="p-4 w-full flex items-center flex-col">
          <FormProvider {...methods}>
            <form className="w-full grid" onSubmit={onSubmit}>
              <div className="w-full grid grid-cols-1 sm:gap-x-[15px]">
                <p className="text-base text-center text-gray-primary my-7">
                  Ingresa el codigo de acceso enviado a tu correo
                </p>
                <CustomLabel
                  label="Codigo de acceso"
                  id="code"
                  required={true}
                  className="text-sm"
                  name="code"
                >
                  <input
                    type="text"
                    placeholder="Codigo de acceso"
                    className={`input-base ${
                      methods.formState.errors.code ? "input-error" : ""
                    } ${
                      !methods.formState.errors.code &&
                      methods.formState.isSubmitted
                        ? "input-valid"
                        : ""
                    } `}
                    {...methods.register("code", {
                      required: "Este campo es requerido",
                    })}
                  />
                </CustomLabel>
              </div>
              <button
                type="submit"
                className="mx-auto button-primary w-[270px]"
              >
                Validar
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}

export default ValidCodeAccess;
