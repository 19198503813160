// Hooks & actions imports
import { useAppSelector } from "../../../app/hook";

// Helpers () imports
import { toast } from "react-toastify";

// Custom queries imports
import { useSendConfirmationEmailMutation } from "../../../features/auth/useSendConfirmationEmailMutation";

function RequestConfirmEmail() {
  const currentUser = useAppSelector((state) => state.auth);

  const sendConfirmationEmailMutation = useSendConfirmationEmailMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        type: "success",
        isLoading: false,
        render: "Solicitud enviada satisfactoriamente",
        autoClose: 5000,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Hubo un error, al intentar solicitar el enlace de confirmación de correo",
        autoClose: 5000,
      });
    }
  );

  return (
    <div className="w-full px-4 py-4 flex justify-between items-center">
      <div
        className={`flex items-center ${
          !currentUser.user?.emailConfirmed ? "text-gray-400" : "text-green-500"
        }`}
      >
        <i className="text-lg bi bi-check-circle-fill"></i>
        <span className=" ml-2">Correo electrónico verificado</span>
      </div>
      {!currentUser.user?.emailConfirmed && (
        <button
          type="button"
          className="button-third bg-blue-500 text-sm py-1 px-4"
          onClick={() => {
            const notificationID = toast.loading("Enviando...");
            sendConfirmationEmailMutation.mutate({
              email: currentUser.user?.email || "",
              notificationID,
            });
          }}
        >
          Solicitar
        </button>
      )}
    </div>
  );
}
export default RequestConfirmEmail;
