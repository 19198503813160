//Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import ListPlansAssign from "../ListPlansAssign";

interface Props {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  planSelected: number | undefined;
  setPlanSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function SelectedPlan({ step, setStep, planSelected, setPlanSelected }: Props) {
  return (
    <ContentWithToggle title="Información del comercio" isToogle={false}>
      <div className="w-full">
        <ListPlansAssign
          planSelected={planSelected}
          setPlanSelected={setPlanSelected}
        />
        <div className="w-full grid sm:col-span-4 grid-cols-2 gap-x-6 p-4">
          <button
            onClick={() => setStep(step - 1)}
            type="button"
            className="button-secondary  text-sm py-2 px-4"
          >
            Anterior
          </button>
          <button
            onClick={() => setStep(step + 1)}
            type="button"
            className={`${
              planSelected ? "" : "opacity-50 pointer-events-none"
            } button-primary text-sm py-2 px-4`}
          >
            Siguiente
          </button>
        </div>
      </div>
    </ContentWithToggle>
  );
}
export default SelectedPlan;
