// Hooks (react-router-dom) imports
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Custom components imports
import InvoiceInformation from "./InvoiceInformation";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Custom queries imports
import { useTicket } from "../../../features/tickets/useTicket";
import { usePersonByIdCard } from "../../../features/persons/usePersonByIdCard";

function InvoiceDetails() {
  const navigate = useNavigate();
  const params = useParams();
  let [searchParams] = useSearchParams();
  
  const personByID: any = usePersonByIdCard(params.idCliente);
  const ticket = useTicket(
    Number(params.idCobro),
    searchParams.get("accessCode") || ""
  );

  if (ticket.isLoading || personByID.isLoading) {
    return <Loading text="Cargando cobro..." />;
  }

  if (personByID.isError) {
    navigate("/pagar");
    return null;
  }

  if (ticket.isError || !ticket.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar cobro" />
        </div>
      </div>
    );
  }

  if (ticket.data.invoiceStatus > 0) {
    navigate(
      `/pagar/${ticket.data.idCard}/t/${ticket.data.invoiceID}/resumen?accessCode=${ticket.data.accessCode}`
    );
    return null;
  }

  return (
    <div className="w-full border-t">
      <div className="w-full">
        <InvoiceInformation ticket={ticket.data} />
      </div>
    </div>
  );
}

export default InvoiceDetails;
