import CustomLabel from "./CustomLabel";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { SyntheticEvent } from "react";

interface Props {
  placeholder: string;
  id: string;
  required: boolean;
  label: string;
  name: string;
  classNameLabel?: string;
  classNameInput?: string;
  notRegisted?: boolean;
  onChange?: (e: SyntheticEvent) => void;
}

function CustomEmailField({
  placeholder,
  id,
  required,
  label,
  name,
  classNameLabel,
  classNameInput,
  notRegisted,
  onChange,
}: Props) {
  const {
    register,
    formState: { errors, isSubmitted },
    getFieldState
  } = useFormContext();
  return (
    <CustomLabel
      label={label}
      id={id}
      required={required}
      className={`w-full ${classNameLabel || ""}`}
    >
      {notRegisted ? (
        <input
          autoComplete="off"
          autoCorrect="off"
          className="input-base"
          disabled
          id={id}
          placeholder={placeholder}
        />
      ) : (
        <input
          autoComplete="off"
          autoCorrect="off"
          className={`input-base ${getFieldState(name).error ? "input-error" : ""} ${
            !getFieldState(name).error && isSubmitted ? "input-valid" : ""
          } ${classNameInput || ""}`}
          id={id}
          placeholder={placeholder}
          {...register(name, {
            required: required ? "Este campo es requerido" : undefined,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Formato invalido",
            },
            onChange: (e: SyntheticEvent) => onChange && onChange(e),
          })}
        />
      )}
      {!notRegisted && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className="mt-2 text-red-600">{message}</p>
          )}
        />
      )}
    </CustomLabel>
  );
}

export default CustomEmailField;
