// Custom components imports
import ContentWithToggle from "../shared/ContentWithToggle";
import Loading from "../shared/Loading";
import ErrorLoading from "../shared/ErrorLoading";
import EmptyContent from "../shared/EmptyContent";
import CustomerCard from "../cards/CustomerCard";

// Hooks (react-router-dom) imports
import { useParams, useNavigate } from "react-router-dom";

// Custom types imports
import { CustomerList } from "../../features/customers/types";

// Types (@tanstack/react-query) imports
import { UseQueryResult } from "@tanstack/react-query";

interface Props {
  customers: UseQueryResult<CustomerList, unknown>;
}

function RecentCustomersList({ customers }: Props) {
  const params = useParams();
  const navigate = useNavigate();

  if (customers.isLoading) {
    return (
      <div className="w-full bg-white rounded-lg overflow-hidden">
        <ContentWithToggle title="Clientes recientes" isToogle={true}>
          <Loading text="Cargando clientes..." />
        </ContentWithToggle>
      </div>
    );
  }

  if (customers.isError || !customers.data) {
    return (
      <div className="w-full bg-white rounded-lg overflow-hidden">
        <ContentWithToggle title="Clientes recientes" isToogle={true}>
          <div className="w-full border-t">
            <div className="w-full">
              <ErrorLoading text="Hubo un error al cargar clientes" />
            </div>
          </div>
        </ContentWithToggle>
      </div>
    );
  }

  if (customers.data.customers.length === 0) {
    return (
      <div className="w-full bg-white rounded-lg overflow-hidden">
        <ContentWithToggle title="Clientes recientes" isToogle={true}>
          <div className="w-full border-t">
            <div className="w-full">
              <EmptyContent text="No hay clientes" />
            </div>
          </div>
        </ContentWithToggle>
      </div>
    );
  }

  return (
    <div className="w-full bg-white rounded-lg overflow-hidden">
      <ContentWithToggle title="Clientes recientes" isToogle={true}>
        <div className="w-full flex flex-col divide-y">
          {customers.data.customers.map((customer, index) => (
            <CustomerCard
              customer={customer}
              key={index}
              link={`/${params.idCliente}/clientes/c/${customer.idCard}`}
            />
          ))}

          <button
            onClick={() => navigate(`/${params.idCliente}/clientes`)}
            className="bg-red-primary hover:saturate-150 font-bold  text-white py-2 flex items-center justify-center gap-x-4"
          >
            Ver más
          </button>
        </div>
      </ContentWithToggle>
    </div>
  );
}
export default RecentCustomersList;
