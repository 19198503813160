// Custom queries imports
import { usePersonByIdCard } from "../../../features/persons/usePersonByIdCard";

// Custom components imports
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";
import EditCustomerForm from "./EditCustomerForm";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

function EditCustomer() {
  const params = useParams();
  const person = usePersonByIdCard(params.idCard);

  if (person.isLoading) {
    return <Loading text="Cargando cliente..." />;
  }

  if (person.isError || !person.data) {
    return (
      <div className="w-full border-t">
        <div className="w-full">
          <ErrorLoading text="Hubo un error al cargar persona" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full sm:max-w-[581px] mx-auto rounded-md overflow-hidden">
      <EditCustomerForm person={person.data} />
    </div>
  );
}
export default EditCustomer;
