// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Store } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  store: Store;
  notificationID: string | number;
}

export function useCreateStoreMutation(
  onSuccessMutation?: (data: Store, variables: paramsType) => void,
  onErrorMutation?: (
    error: ApiErrorResponse<any>,
    variables: paramsType
  ) => void
) {
  async function createStore(variables: paramsType): Promise<Store> {
    const response = await api.post<Store>("admin/stores", variables.store);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: createStore,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
