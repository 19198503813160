// Config imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom types imports
import { Person } from "./types";

export function usePersonByIdCard(idCard?: string) {
  async function getPersonByIdCard(idCard?: string): Promise<Person> {
    const response = await api.get<Person>(`admin/persons/byidcard/${idCard}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Person", idCard],
    queryFn: () => getPersonByIdCard(idCard),
    enabled: !!idCard,
  });
}
