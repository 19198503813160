// Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Custom helpers imports
import { generateDeviceID } from "../../features/auth/helpers";

// Custom components imports
import LoginForm from "../../components/forms/login/LoginForm";

// Custom types imports
import { UserCredentials } from "../../features/auth/types";

function Login() {
  const deviceID = generateDeviceID();
  const methods = useForm<UserCredentials>({
    defaultValues: {
      maxItems: 0,
      modifiedAfter: new Date().toISOString(),
      expiredBy: new Date().toISOString(),
      vStatus: 0,
      userNameOrEmail: "",
      password: "",
      rememberMe: false,
      resendEmail: "",
      deviceID: deviceID || "",
      returnUrl: "",
    },
  });
  return (
    <div className="flex items-start flex-wrap">
      <FormProvider {...methods}>
        <LoginForm />
      </FormProvider>
    </div>
  );
}

export default Login;
