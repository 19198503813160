// Hooks (react-router-dom) imports
import { useNavigate, useParams } from "react-router-dom";

// State Redux imports
import { useAppSelector } from "../../../../app/hook";

// Third helpers imports
import { toast } from "react-toastify";

// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import DataStoreForm from "./DataStoreForm";
import EditStoreActions from "./EditStoreActions";
import Tabs from "../../../shared/Tabs";

// Custom queries imports
import { useUpdateStoreMutation } from "../../../../features/stores/useUpdateStoreMutation";

// Custom types imports
import { Store } from "../../../../features/stores/types";
import { Tab } from "../../../shared/Tabs";

// (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

interface Props {
  store: Store;
}

function EditStoreForm({ store }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = useAppSelector((state) => state.auth);
  const methods = useForm<Store>({ defaultValues: store });
  const tabs: Tab[] = [
    {
      label: "General",
      url: `/${params.idUser}/comercios/c/${params.idStore}/`,
    },
    {
      label: "Usuarios",
      url: `/${params.idUser}/comercios/c/${params.idStore}/usuarios/`,
    },
    {
      label: "Plan",
      url: `/${params.idUser}/comercios/c/${params.idStore}/plan/`,
    },
  ];

  const updateStoreMutation = useUpdateStoreMutation(
    (_data, variable) => {
      toast.update(variable.notificationID, {
        type: "success",
        isLoading: false,
        render: `Comercio #${variable.store.id} actualizado satisfactoriamente`,
        autoClose: 5000,
      });
    },
    (error, variable) => {
      toast.update(variable.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Hubo un error, al intentar actualizar comercio",
        autoClose: 5000,
      });
    }
  );

  const onSubmitStore = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Actualizando comercio...");
    updateStoreMutation.mutate({ store: data, notificationID });
  });

  return (
    <ContentWithToggle
      title={`${store.storeName}#${store.id}` || ""}
      onBackButton={() => navigate(`/${currentUser?.user?.id}/comercios`)}
      isToogle={true}
    >
      <Tabs tabs={tabs} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmitStore}>
          <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
            <DataStoreForm store={store} />
            <EditStoreActions />
          </div>
        </form>
      </FormProvider>
    </ContentWithToggle>
  );
}
export default EditStoreForm;
