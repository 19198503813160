// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { UserCredentials } from "./types";
import { NewlyRegisteredUser } from "../users/types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  credentials: UserCredentials;
  notificationID: string | number;
}

export function useLoginMutation(
  onSuccessMutation?: (data: NewlyRegisteredUser, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {

  async function loginUser(params: paramsType): Promise<NewlyRegisteredUser> {
    const response = await api.post<NewlyRegisteredUser>("auth/login", params.credentials);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: loginUser,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
