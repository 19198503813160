// Custom types imports
import { Plan } from "../../features/plans/types";
import { Link } from "react-router-dom";

// Custom helpers
import { formatNumber } from "../../helpers";

interface Props {
  plan: Plan;
  link: string;
}

function PlanCard({ plan, link }: Props) {
  return (
    <Link
      to={link}
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
    >
      <div className="col-span-1 flex flex-col">
        <div className="mb-1">
          <span
            className={`${
              !!plan.status ? "bg-green-500" : "bg-red-500"
            } text-xs text-white py-1 px-2 rounded-full truncate`}
          >
            {!!plan.status ? "Activo" : "Inactivo"}
          </span>
        </div>
        <b className="text-lg truncate">{plan.serviceName}</b>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <span className="text-sm ml-auto">Transacciones Mensuales</span>
        <b className="text-gray-500 ml-auto">
          {formatNumber(plan.monthlyTransactions)}
        </b>
      </div>
      <div className="col-span-1 mt-1 flex flex-col">
        <span className="text-sm">Transacciones Diarias</span>
        <b className="italic text-gray-500">
          {formatNumber(plan.dailyTransactions)}
        </b>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </Link>
  );
}
export default PlanCard;
