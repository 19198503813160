import { Customer } from "../../features/customers/types";
import { CARD_TYPES_INFO } from "../../features/card_types/types";
import { Link } from "react-router-dom";

interface Props {
  customer: Customer;
  link: string;
}

function CustomerCard({ customer, link }: Props) {
  return (
    <Link
      to={link}
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
    >
      <div className="col-span-1 flex flex-col">
        <b className="text-lg truncate">{`${customer.firstName} ${customer.lastName}`}</b>
      </div>
      <div className="col-span-1 mt-1 flex items-end flex-col">
        <span className="text-sm">
          {CARD_TYPES_INFO[customer.idCardType].description}
        </span>
        <b className="italic text-gray-500">
          {new Intl.NumberFormat("es-CO").format(Number(customer.idCardValue))}
        </b>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <span className="text-sm mr-auto">Correo electrónico</span>
        <p className="w-full mr-auto italic text-red-500 truncate">{customer.email}</p>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </Link>
  );
}

export default CustomerCard;
