// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  email: string;
  notificationID: string | number;
}

export function useRemovePersonMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function removePerson(params: paramsType): Promise<any> {
    const response = await api.delete<any>(
      `admin/deleteperson/${params.email}`
    );

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: removePerson,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
