// Configs imports
import api from "../../app/api";

// Custom types imports
import { PaginatedModelTicket, TicketList } from "./types";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

export function useTickets(params: PaginatedModelTicket) {
  async function getTicketByModel(
    params: PaginatedModelTicket
  ): Promise<TicketList> {
    const response = await api.post<TicketList>(
      "invoices/tickets/paginated",
      params
    );
    if (!response.ok || !response.data) {
      throw response;
    }
    return response.data;
  }

  return useQuery({
    queryKey: ["TicketList", ...Object.values(params)],
    queryFn: () => getTicketByModel(params),
  });
}
