// React imports
import { useRef } from "react";

// Hooks & Components (react-hook-form) imports
import { useForm } from "react-hook-form";

// Hooks & actions imports
import { useAppSelector, useAppDispatch } from "../../../app/hook";
import { logout } from "../../../features/auth/authSlice";

// Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { confirmChange } from "../../../features/auth/helpers";

// Custom queries imports
import { useUpdateEmailMutation } from "../../../features/auth/useUpdateEmailMutation";

function UpdateEmailForm() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth);
  const inputSubmitRef = useRef<HTMLInputElement | null>(null);

  const updateEmailMutation = useUpdateEmailMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        type: "success",
        isLoading: false,
        autoClose: 5000,
        render: "Correo electrónico cambiado satisfactoriamente",
      });
      dispatch(logout());
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        autoClose: 5000,
        render:
          error.data?.description ||
          "¡Algo salio mal!, al intentar cambiar correo electrónico",
      });
    }
  );

  const methods = useForm<{ email: string }>({
    defaultValues: {
      email: currentUser.user?.email || "",
    },
  });

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Cambiando correo electrónico...");
    updateEmailMutation.mutate({
      email: data.email,
      notificationID,
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="w-full px-4 py-3 flex justify-between items-center flex-wrap">
        <span className="text-gray-600">Correo electrónico</span>
        <div className="sm:mt-0 mt-2 w-full sm:w-[60%] flex justify-end items-center">
          <input
            className="input-base mr-2 text-sm"
            type="text"
            placeholder="Correo electrónico"
            {...methods.register("email")}
          />
          <button
            type="button"
            onClick={() =>
              confirmChange(
                "¿Estas seguro de modificar el correo electrónico?",
                "Al realizar esta modificación de correo electrónico, la sesión será cerrada automáticamente",
                () => {
                  if (!!inputSubmitRef.current) {
                    inputSubmitRef.current.click();
                  }
                }
              )
            }
            disabled={currentUser.user?.email === methods.watch("email")}
            className="button-third bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4"
          >
            Cambiar
          </button>
          <input className="hidden" ref={inputSubmitRef} type="submit" />
        </div>
      </div>
    </form>
  );
}
export default UpdateEmailForm;
