// Custom queries imports
import { usePersonByEmailMutation } from "../persons/usePersonByEmailMutation";
import { usePersonByUsernameMutation } from "../persons/usePersonByUsernameMutation";
import { usePersonByPhoneMutation } from "../persons/usePersonByPhoneMutation";
import { usePersonByIdCardMutation } from "../persons/usePersonByIdCardMutation";

// Third components & helpers imports
import { toast } from "react-toastify";

// Custom types imports
import { UserRegister } from "../users/types";
import { PersonRegistration } from "../persons/types";

interface paramsType {
  data: {
    person: PersonRegistration;
    user: UserRegister;
  };
  onSuccessMutation?: () => void;
}

export function useValidRosterMutation(params: paramsType) {
  const validIdCardMutation = usePersonByIdCardMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El documento de identidad ya está en uso, intenta de nuevo con otro documento de identidad",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Usuario validado",
        autoClose: 5000,
      });
      params.onSuccessMutation?.();
    }
  );

  const validPhoneMutation = usePersonByPhoneMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El número telefónico ya está en uso, intenta de nuevo con otro número telefónico",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "Validando documento de identidad...",
        autoClose: 5000,
      });
      validIdCardMutation.mutate({
        idCard: params.data.person.idCard,
        notificationID: variables.notificationID,
      });
    }
  );

  const validUsernameMutation = usePersonByUsernameMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El nombre de usuario ya está en uso, intenta de nuevo con otro nombre de usuario",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "Validando número telefónico...",
        autoClose: 5000,
      });
      validPhoneMutation.mutate({
        phone: params.data.person.phoneNumber,
        notificationID: variables.notificationID,
      });
    }
  );

  const validEmailMutation = usePersonByEmailMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El correo electrónico ya está en uso, intenta de nuevo con otro correo electrónico",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "Validando nombre de usuario...",
        autoClose: 5000,
      });
      validUsernameMutation.mutate({
        username: params.data.user.userName,
        notificationID: variables.notificationID,
      });
    }
  );

  return validEmailMutation;
}
