// Actions & States Redux imports
import { useAppSelector } from "../app/hook";

// Helpers () imports
import { toast } from "react-toastify";

// Custom queries imports
import { useSendConfirmationEmailMutation } from "../features/auth/useSendConfirmationEmailMutation";

function EmailConfirmationBanner() {
  const currentUser = useAppSelector((state) => state.auth);
  const sendConfirmationEmailMutation = useSendConfirmationEmailMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        type: "success",
        isLoading: false,
        render: "Solicitud enviada satisfactoriamente",
        autoClose: 5000,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        type: "error",
        isLoading: false,
        render:
          error.data?.description ||
          "Hubo un error, al intentar solicitar el enlace de confirmación de correo",
        autoClose: 5000,
      });
    }
  );

  return (
    <div className="text-sm flex justify-center items-center bg-yellow-200 text-black py-2 px-4">
      <span>Confirma tu correo electrónico</span>
      <button
        tabIndex={-1}
        className="bg-black text-white rounded-md px-5 py-1 ml-4 font-bold"
        onClick={() => {
          const notificationID = toast.loading("Enviando...");
          sendConfirmationEmailMutation.mutate({
            email: currentUser.user?.email || "",
            notificationID,
          });
        }}
      >
        Enviar
      </button>
    </div>
  );
}

export default EmailConfirmationBanner;
