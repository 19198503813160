// Hook & Components Redux imports
import { useAppSelector } from "../app/hook";

// Custom componets imports
import LinksCustomer from "./shared/navigations/links/LinksCustomer";
import LinkStoreEmployee from "./shared/navigations/links/LinkStoreEmployee";
import LinksStoreSupervisor from "./shared/navigations/links/LinksStoreSupervisor";
import LinksCorporateEmployee from "./shared/navigations/links/LinksCorporateEmployee";
import LinksCorporateAdmin from "./shared/navigations/links/LinksCorporateAdmin";
import LinksPublic from "./shared/navigations/links/LinksPublic";

// Custom helpers imports
import {
  isCustomer,
  isStoreEmployee,
  isStoreSupervisor,
  isCorporateEmployee,
  isCorporateAdmin,
} from "../features/auth/helpers";

function Links() {
  const currentUser = useAppSelector((state) => state.auth);
  if (!currentUser.isAuthenticated) {
    return <LinksPublic />;
  }

  if (!currentUser.user || !currentUser.user.roles) {
    return null;
  }

  if (isCorporateAdmin(currentUser.user.roles)) {
    return <LinksCorporateAdmin />;
  }

  if (isCorporateEmployee(currentUser.user.roles)) {
    return <LinksCorporateEmployee />;
  }

  if (isStoreSupervisor(currentUser.user.roles)) {
    return <LinksStoreSupervisor />;
  }

  if (isStoreEmployee(currentUser.user.roles)) {
    return <LinkStoreEmployee />;
  }

  if (isCustomer(currentUser.user.roles)) {
    return <LinksCustomer />;
  }

  return null;
}

export default Links;
