// Hooks (@tanstack/react-query) imports
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PlanStore } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  planID: number;
  companyID: number;
  notificationID: string | number;
}

export function useAssignPlanStoreMutation(
  onSuccessMutation?: (data: PlanStore, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  const queryClient = useQueryClient();

  async function assignPlanStore(params: paramsType): Promise<PlanStore> {
    const response = await api.put<PlanStore>(
      `admin/stores/${params.companyID}/plans/${params.planID}`,
      {},
      {
        params: {
          includePlanInfo: true,
        },
      }
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: assignPlanStore,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["PlansByStore", variables.companyID]);
      onSuccessMutation?.(data, variables);
    },
    onError: onErrorMutation,
  });
}
