function LoaderInfiniteList() {
  return (
    <div className="bg-red-primary  font-bold  text-white py-2 flex items-center justify-center gap-x-4">
      <i className="animate-spin text-xl text-whitebi bi-arrow-repeat"></i>
      Cargando...
    </div>
  );
}

export default LoaderInfiniteList;
