// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import CustomLabel from "../../../shared/fields/CustomLabel";

// Custom types imports
import { PAYMENT_ENTITY_INFO } from "../../../../features/payment_methods/types";
import { usePaymentsById } from "../../../../features/payments/usePayment";
import { CARD_TYPES_INFO } from "../../../../features/card_types/types";
import { TAX_REGIME_INFO } from "../../../../features/stores/types";
import { Ticket } from "../../../../features/payments/types";

// Components (react-currency-input-field) imports
import CurrencyInput from "react-currency-input-field";

// Custom mocks imports
import { taxRegimeMock } from "../../../../features/stores/mocks";

interface Prop {
  ticket: Ticket;
}

function PaymentInformation({ ticket }: Prop) {
  const payment = usePaymentsById(ticket.invoiceID, ticket.accessCode);

  return (
    <>
      <ContentWithToggle
        title={`Detalle de transacción #${ticket.invoiceID}`}
        isToogle={true}
        defaultOpenValue={true}
      >
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Metodo de pago</span>
          <div className="w-1/2 flex items-center justify-end">
            <img
              className="w-[30px] h-[30px] shadow-custom-md rounded-[5px]"
              src={PAYMENT_ENTITY_INFO[ticket.paymentMethod].logo}
              alt=""
            />
            <div className="flex flex-col ml-[14px]">
              <b className="truncate ...">
                {PAYMENT_ENTITY_INFO[ticket.paymentMethod].description}
              </b>
            </div>
          </div>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Fecha de emisión</span>
          <b>
            {" "}
            {ticket.date
              ? new Date(ticket.date).toLocaleDateString("es-CO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : ""}
          </b>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Nro. de comprobante</span>
          <b>
            {payment.isLoading
              ? "Cargando..."
              : payment.data?.receipt || "No disponible"}
          </b>
        </div>
        <div className="border-b w-full px-4 py-3 flex justify-between items-center">
          <span className="text-gray-600">Codigo de autorización</span>
          <b>
            {payment.isLoading
              ? "Cargando..."
              : payment.data?.authorizationCode || "No disponible"}
          </b>
        </div>

        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="ID"
            id="id"
            required={false}
            className="text-sm sm:col-span-1"
          >
            <input
              type="text"
              disabled
              placeholder="ID"
              className="input-base"
              value={ticket.invoiceID}
            />
          </CustomLabel>

          <CustomLabel
            label="Nro. Factura"
            id="factura"
            required={false}
            className="text-sm sm:col-span-1"
          >
            <input
              type="text"
              disabled
              placeholder="Factura de compra"
              className="input-base"
              value={ticket.storeInvoice}
            />
          </CustomLabel>

          <CustomLabel
            label="Precio de venta"
            id="factura"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <CurrencyInput
              decimalSeparator=","
              groupSeparator="."
              id="amount"
              name="amount"
              placeholder="Precio de venta"
              decimalsLimit={0}
              allowNegativeValue={false}
              allowDecimals={false}
              disabled
              prefix="$ "
              value={ticket.amount}
              className="input-base"
            />
          </CustomLabel>

          <CustomLabel
            label="Descripcion"
            id="description"
            required={false}
            className="text-sm sm:col-span-4"
          >
            <textarea
              autoCorrect="off"
              disabled
              defaultValue={ticket.description}
              className="w-full input-base h-[80px] p-5 text-gray-primary"
            ></textarea>
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del cliente"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={
                (!!ticket.customer?.idCardType &&
                  CARD_TYPES_INFO[ticket.customer?.idCardType]?.description) ||
                1
              }
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="idCardValue"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.customer?.idCardValue}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombres"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Nombres"
              className="input-base"
              value={ticket.customer?.firstName || ""}
            />
          </CustomLabel>

          <CustomLabel
            label="Apellidos"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Nombres"
              className="input-base"
              value={ticket.customer?.lastName || ""}
            />
          </CustomLabel>
        </div>
      </ContentWithToggle>

      <ContentWithToggle
        title="Información del comercio"
        isToogle={true}
        defaultOpenValue={false}
      >
        <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
          <CustomLabel
            label="Tipo documento"
            id="tipoDocumento"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Tipo documento"
              className="input-base"
              value={CARD_TYPES_INFO[2]?.description}
            />
          </CustomLabel>

          <CustomLabel
            label="No. De documento"
            id="taxIDNumber"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="number"
              disabled
              placeholder="No. De documento"
              className="input-base"
              value={ticket.store?.taxIDNumber}
            />
          </CustomLabel>

          <CustomLabel
            label="Nombre de comercio"
            id="nombre"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              disabled
              placeholder="Razon social"
              className="input-base"
              value={ticket.store?.storeName || ""}
            />
          </CustomLabel>

          {!!ticket.store?.taxCondition && (
            <CustomLabel
              label="Régimen tributario"
              id="taxCondition"
              required={false}
              className="text-sm sm:col-span-2"
            >
              <div className="w-full flex items-center relative">
                <select
                  className="input-base appearance-none"
                  disabled
                  value={ticket.store.taxCondition}
                >
                  {taxRegimeMock?.map((taxRegime) => (
                    <option value={taxRegime} key={taxRegime}>
                      {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                      {TAX_REGIME_INFO[taxRegime]?.description}
                    </option>
                  ))}
                </select>
              </div>
            </CustomLabel>
          )}
        </div>
      </ContentWithToggle>
    </>
  );
}

export default PaymentInformation;
