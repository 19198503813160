// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Plan } from "./types";

export function usePlan(id?: number) {
  async function getPlan(id?: number): Promise<Plan> {
    const response = await api.get<Plan>(`admin/plans/${id}`);

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Plan", id],
    queryFn: () => getPlan(id),
    enabled: !!id,
  });
}
