// Custom components imports
import CustomEmailField from "../../../components/shared/fields/CustomEmailField";
import ContentWithToggle from "../../../components/shared/ContentWithToggle";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Third helpers imports
import { toast } from "react-toastify";

// Custom queries imports
import { useForgotPasswordMutation } from "../../../features/auth/useForgotPasswordMutation";

type FormData = {
  email: string;
};

function RequestResetPasswordForm() {
  const navigate = useNavigate();
  const methods = useFormContext<FormData>();

  const forgotPasswordMutation = useForgotPasswordMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "info",
        autoClose: 5000,
        render: `Solicitud enviada a ${variables.email}`,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        autoClose: 5000,
        render:
          error.data?.description ||
          "Algo salio mal al intentar enviar solicitud",
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Enviando solicitud...");
    forgotPasswordMutation.mutate({
      email: data.email,
      notificationID,
    });
  });

  return (
    <div className="flex items-start flex-wrap">
      <div className="mb-5 mx-auto sm:rounded-[18px] w-full max-w-[520px] bg-white sm:mt-[65px] pb-2">
        <form onSubmit={onSubmit}>
          <ContentWithToggle
            title="Solicitar restablecer contraseña"
            onBackButton={() => navigate("/")}
          >
            <div className="px-4 pt-4 pb-2 grid grid-cols-1 gap-x-4 gap-y-2">
              <CustomEmailField
                placeholder="nombre@correo.com"
                id="email"
                required={true}
                label="Correo electrónico"
                name="email"
              />
              <button
                className="w-[270px] button-primary mx-auto"
                type="submit"
              >
                Enviar solicitud
              </button>
            </div>
          </ContentWithToggle>
        </form>
      </div>
    </div>
  );
}

export default RequestResetPasswordForm;
