// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";

// Third components imports
import CurrencyInput from "react-currency-input-field";

// Hooks (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom types imports
import { Plan } from "../../../../features/plans/types";

function DataPlanForm() {
  const methods = useFormContext<Plan>();
  return (
    <div className="w-full px-4 py-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2 border-b">
      <CustomLabel
        label="Nombre del servicio"
        id="serviceName"
        required={true}
        className="text-sm sm:col-span-3"
        name="serviceName"
      >
        <input
          type="text"
          placeholder="Nombre del servicio"
          className={`input-base ${
            methods.formState.errors.serviceName ? "input-error" : ""
          } ${
            !methods.formState.errors.serviceName &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("serviceName", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Estado"
        id="status"
        required={true}
        className="text-sm sm:col-span-1"
        name="status"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`border-none text-white ${
              methods.watch("status") ? "bg-green-500" : "bg-red-500"
            } input-base appearance-none ${
              methods.formState.errors.status ? "input-error" : ""
            } ${
              !methods.formState.errors.status && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("status", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            {[
              { description: "Activo", value: 1 },
              { description: "Inactivo", value: 0 },
            ].map((status) => (
              <option value={status.value} key={status.value}>
                {status.description}
              </option>
            ))}
          </select>
          <i className="text-white text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Descripcion"
        id="description"
        required={true}
        className="text-sm sm:col-span-4"
        name="description"
      >
        <textarea
          autoCorrect="off"
          className={`w-full input-base h-[80px] p-5 text-gray-primary ${
            methods.formState.errors.description ? "input-error" : ""
          } ${
            !methods.formState.errors.description &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("description", {
            required: "Este campo es requerido",
          })}
        ></textarea>
      </CustomLabel>

      <CustomLabel
        label="Trans diarias"
        id="dailyTransactions"
        required={true}
        className="text-sm sm:col-span-1"
        name="dailyTransactions"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          id="dailyTransactions"
          placeholder="Trans diarias"
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          prefix=""
          value={methods.watch("dailyTransactions")}
          onValueChange={(value) =>
            methods.setValue("dailyTransactions", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.dailyTransactions ? "input-error" : ""
          } ${
            !methods.formState.errors.dailyTransactions &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("dailyTransactions", {
            required: "Este campo es requerido",
            valueAsNumber: true,
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Trans mensual"
        id="monthlyTransactions"
        required={true}
        className="text-sm sm:col-span-1"
        name="monthlyTransactions"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          id="monthlyTransactions"
          placeholder="Trans mensual"
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          prefix=""
          value={methods.watch("monthlyTransactions")}
          onValueChange={(value) =>
            methods.setValue("monthlyTransactions", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.monthlyTransactions ? "input-error" : ""
          } ${
            !methods.formState.errors.monthlyTransactions &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("monthlyTransactions", {
            required: "Este campo es requerido",
            valueAsNumber: true,
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Dimensión"
        id="dimension"
        required={true}
        className="text-sm sm:col-span-1"
        name="dimension"
      >
        <CurrencyInput
          decimalSeparator=","
          groupSeparator="."
          id="dimension"
          placeholder="Dimensión"
          decimalsLimit={0}
          allowNegativeValue={false}
          allowDecimals={false}
          prefix=""
          value={methods.watch("dimension")}
          onValueChange={(value) =>
            methods.setValue("dimension", Number(value) || 0)
          }
          className={`input-base ${
            methods.formState.errors.dimension ? "input-error" : ""
          } ${
            !methods.formState.errors.dimension && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("dimension", {
            required: "Este campo es requerido",
            valueAsNumber: true,
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Comisión (%)"
        id="commission"
        required={true}
        className="text-sm sm:col-span-1"
        name="commission"
      >
        <input
          type="number"
          placeholder="Comisión"
          className={`input-base ${
            methods.formState.errors.commission ? "input-error" : ""
          } ${
            !methods.formState.errors.commission &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("commission", {
            required: "Este campo es requerido",
            valueAsNumber: true,
          })}
        />
      </CustomLabel>
      <CustomLabel
        label="Usuarios activos"
        id="activeUsers"
        required={true}
        className="text-sm sm:col-span-1"
        name="activeUsers"
      >
        <input
          type="number"
          placeholder="Usuarios activos"
          className={`input-base ${
            methods.formState.errors.activeUsers ? "input-error" : ""
          } ${
            !methods.formState.errors.activeUsers &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("activeUsers", {
            required: "Este campo es requerido",
            valueAsNumber: true,
          })}
        />
      </CustomLabel>
    </div>
  );
}
export default DataPlanForm;
