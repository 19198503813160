import { Invoice, StatusIndex } from "../invoices/types";
import { Customer } from "../customers/types";
import { Store } from "../stores/types";

export interface PaymentReceipt {
  id: number;
  purchaseActionType: number;
  invoiceID: number;
  paymentMethod: number;
  transactionID: string;
  transactionResult: number;
  amount: number;
  receipt: string;
  authorizationCode: string;
  details: string;
  reply: string;
  accessCode: string;
  date: string;
}

export interface Ticket extends Invoice {
  customer: Customer;
  store: Store;
  invoiceStatusDescription: string;
  paymentMethodDescription: string;
  paymentMethod: number;
}

export interface Payment {
  maxItems?: number;
  modifiedAfter?: string;
  expiredBy?: string;
  vStatus?: number;
  storeID: number;
  invoiceID: number;
  amount: number;
  paymentMethod: number;
  idCard: string;
  token: string;
  storeInvoice: string;
  invoiceStatus: StatusIndex;
  date: string;
  accessCode?: string;
  paymentReference?: string | null;
}


export const defaultValues: Payment = {
  maxItems: 0,
  modifiedAfter: new Date().toISOString(),
  date: new Date().toISOString(),
  expiredBy: new Date().toISOString(),
  storeID: 0,
  invoiceID: 0,
  amount: 0,
  paymentMethod: 0,
  idCard: "",
  token: "",
  storeInvoice: "",
  invoiceStatus: 0,
  accessCode: "",
  paymentReference: null
};