// React imports
import { useState, useEffect } from "react";

// Custom componets imports
import Links from "./Links";

// Hook & Components Redux imports
import { useAppSelector } from "../app/hook";

function Sidebar() {
  const currentUser = useAppSelector((state) => state.auth);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const toggleShadowOfNavigationTop = () => {};
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) return setIsScrolling(true);
      setIsScrolling(false);
    });
    return () =>
      window.removeEventListener("scroll", toggleShadowOfNavigationTop);
  }, []);

  if (!currentUser.user) return null;

  return (
    <div
      className={`divide-y hidden xl:block fixed top-[65px] h-[calc(100vh-65px)] left-0 z-10 w-full max-w-[250px] bg-white rounded-br-[18px] ${
        isScrolling ? "shadow-custom-lg" : "border"
      }`}
    >
      <Links />
    </div>
  );
}

export default Sidebar;
