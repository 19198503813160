// Custom types imports
import { StatusIndex } from "../../features/invoices/types";

// Custom helpers imports
import { formatAmount } from "../../helpers";

interface Props {
  amount: number;
  status: StatusIndex;
}

function TotalInvoiceAmount({ amount, status }: Props) {
  return (
    <div className="bg-gray-600 text-white w-full px-4 py-3 flex justify-between items-center">
      <span>
        {status > 1 && status !== 3
          ? "Total de la transacción"
          : "Total del cobro"}
      </span>
      <b>{formatAmount(amount)}</b>
    </div>
  );
}
export default TotalInvoiceAmount;
