// React imports
import { useEffect } from "react";

// Actions & States Redux imports
import { useAppSelector, useAppDispatch } from "./app/hook";
import {
  loadUser,
  changeIsLoadingUser,
  logout,
} from "./features/auth/authSlice";

// Custom components imports
import NavigationTop from "./components/NavigationTop";
import Loading from "./components/shared/Loading";
import Sidebar from "./components/Sidebar";
import EmailConfirmationBanner from "./components/EmailConfirmationBanner";
import TokenExpirationCountdown from "./components/shared/navigations/TokenExpirationCountdown";

// Custom consts imports
import { USER_KEY } from "./features/auth/helpers";

// Components (react-toastify) imports
import { ToastContainer } from "react-toastify";

// Components (react-router-dom) imports
import { Outlet } from "react-router-dom";

// Custom queries imports
import { useLimitedPingMutation } from "./features/auth/useLimitedPingMutation";

// Custom types imports
import { NewlyRegisteredUser } from "./features/users/types";

// Stylesheets imports
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  const currentUser = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const userInLocalStorage = localStorage.getItem(USER_KEY);

  const validateTokenInitiallyMutation = useLimitedPingMutation(
    (_data, variables) => {
      dispatch(loadUser(variables.user));
    },
    (_error, _variables) => {
      dispatch(logout());
    }
  );

  const tokenValidIntervalMutation = useLimitedPingMutation(
    undefined,
    (_error, _variables) => {
      dispatch(logout());
    }
  );

  useEffect(() => {
    if (!userInLocalStorage) {
      dispatch(changeIsLoadingUser(false));
    } else {
      const user: NewlyRegisteredUser = JSON.parse(userInLocalStorage);
      const tokenValidInterval = setInterval(() => {
        tokenValidIntervalMutation.mutate({ user });
      }, 300000);

      validateTokenInitiallyMutation.mutate({
        user,
        interval: tokenValidInterval,
      });

      return () => {
        clearInterval(tokenValidInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentUser.isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-full bg-white fixed top-0 left-0">
        <Loading text="Cargando..." />
      </div>
    );
  }

  return (
    <div className="App">
      <NavigationTop />
      <div className="w-full container-wrap flex flex-nowrap">
        {currentUser.isAuthenticated && <Sidebar />}
        <div
          className={`w-full xl:ml-auto ${
            currentUser.isAuthenticated && "xl:w-[calc(100%-250px)]"
          }`}
        >
          {currentUser.isAuthenticated && <TokenExpirationCountdown />}
          
          {currentUser.isAuthenticated && !currentUser.user?.emailConfirmed && (
            <EmailConfirmationBanner />
          )}

          <Outlet />
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
  );
}

export default App;
