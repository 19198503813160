// React imports
import { ReactNode } from "react";

// Hooks (react-router-dom) imports
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface Prop {
  id: number;
  title: string;
  body: string | ReactNode;
  icon: ReactNode;
  disabled?: boolean;
}
function CardMetodoPago({ id, title, body, icon, disabled }: Prop) {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  return (
    <div
      onClick={() =>
        navigate(
          `/pagar/${params.idCliente}/c/${
            params.idCobro
          }/procesar?accessCode=${searchParams.get("accessCode")}&pm=${id}`
        )
      }
      className={`flex items-center w-full px-4 py-5 bg-white  cursor-pointer transition-all ${
        disabled ? "grayscale pointer-events-none" : ""
      }`}
    >
      <div className="flex items-center">{icon}</div>
      <div className="flex flex-col flex-1 ml-4 max-w-[180px] sm:max-w-[380px]">
        <h3 className="font-bold text-sm mb-[4px] sm:text-base">{title}</h3>
        <div className="text-sm text-gray-primary leading-[14px]">{body}</div>
      </div>
      <div className="ml-auto">
        <i className="bi bi-arrow-right text-red-primary text-[24px] sm:text-[28px]"></i>
      </div>
    </div>
  );
}

export default CardMetodoPago;
