// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { PaymentMethodN } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  data: PaymentMethodN;
  notificationID: string | number;
}

export function useUpdatePaymentMethodMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function updatePaymentMethod(params: paramsType): Promise<any> {
    const response = await api.put<any>("admin/paymentmethods", params.data);

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: updatePaymentMethod,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
