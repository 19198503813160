// Custom types imports
import { PaymentMethodN } from "../../features/payment_methods/types";
import { Link } from "react-router-dom";

// Custom helpers
import { formatNumber } from "../../helpers";

interface Props {
  paymentMethod: PaymentMethodN;
  link: string;
}

function PaymentMethodCard({ paymentMethod, link }: Props) {
  return (
    <Link
      to={link}
      className="hover:bg-slate-50 cursor-pointer w-full p-4 grid grid-cols-2"
    >
      <div className="col-span-1 flex flex-col">
        <div className="mb-1">
          <span
            className={`${
              !!paymentMethod.status ? "bg-green-500" : "bg-red-500"
            } text-xs text-white py-1 px-2 rounded-full truncate`}
          >
            {!!paymentMethod.status ? "Activo" : "Inactivo"}
          </span>
        </div>
        <b className="text-lg truncate">{paymentMethod.paymentMethodName}</b>
      </div>
      <div className="col-span-1 mt-1 flex items-center flex-col">
        <span className="text-sm ml-auto">Comisiones IVA</span>
        <b className="text-gray-500 ml-auto">
          {formatNumber(paymentMethod.ivaCommission)}%
        </b>
      </div>
      <div className="col-span-1 mt-1 flex flex-col">
        <span className="text-sm">{paymentMethod.retention ? "Aplica retenciones" : "No aplica retenciones"}</span>
      </div>
      <p className="col-span-1 font-medium underline flex items-center justify-end">
        Ver detalle
        <i className="ml-4 text-red-primary text-2xl bi bi-arrow-right-circle-fill"></i>
      </p>
    </Link>
  );
}
export default PaymentMethodCard;
