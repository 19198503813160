// React imports
import { useState } from "react";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

//Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";
import CurrentPlan from "../CurrentPlan";
import ErrorLoading from "../../../shared/ErrorLoading";
import Loading from "../../../shared/Loading";
import Tabs from "../../../shared/Tabs";
import EmptyContent from "../../../shared/EmptyContent";
import DrawerAssignPlanStore from "./DrawerAssignPlanStore";

// Custom queries imports
import { usePlansByStore } from "../../../../features/plans/usePlansByStore";

// Custom types imports
import { Tab } from "../../../shared/Tabs";
import { PlanStore as PlanStoreType } from "../../../../features/plans/types";
import { UseQueryResult } from "@tanstack/react-query";

function RenderPlan({
  plan,
}: {
  plan: UseQueryResult<PlanStoreType[], unknown>;
}) {
  if (plan.isLoading) {
    return <Loading text="Cargando informacion de comercio..." />;
  }

  if (plan.isError || !plan.data) {
    return (
      <ErrorLoading text="¡Hubo un error al cargar informacion del comercio!" />
    );
  }

  if (plan.data.length === 0) {
    return <EmptyContent text="No hay plan asignado" />;
  }

  return <CurrentPlan plan={plan.data[0]} />;
}

function PlanStore() {
  const params = useParams();
  const [openDrawerAssignPlanStore, setOpenDrawerAssignPlanStore] =
    useState<boolean>(false);
  const plan = usePlansByStore(Number(params.idStore));

  const tabs: Tab[] = [
    {
      label: "General",
      url: `/${params.idUser}/comercios/c/${params.idStore}/`,
    },
    {
      label: "Usuarios",
      url: `/${params.idUser}/comercios/c/${params.idStore}/usuarios/`,
    },
    {
      label: "Plan",
      url: `/${params.idUser}/comercios/c/${params.idStore}/plan/`,
    },
  ];

  return (
    <div className="w-full sm:max-w-[581px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <ContentWithToggle
        title="Plan"
        actionButton={
          <div>
            <button
              className={`${
                plan.isLoading ? "opacity-50 pointer-events-none" : ""
              } button-primary inline-block w-[90px] sm:w-[108px] text-sm`}
              onClick={() => setOpenDrawerAssignPlanStore(true)}
            >
              Asignar
            </button>
          </div>
        }
      >
        <Tabs tabs={tabs} />
        <RenderPlan plan={plan} />
      </ContentWithToggle>
      {!!plan.isSuccess && (
        <DrawerAssignPlanStore
          open={openDrawerAssignPlanStore}
          setOpen={setOpenDrawerAssignPlanStore}
          plan={plan.data[0]?.plan}
        />
      )}
    </div>
  );
}
export default PlanStore;
