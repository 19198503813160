// Custom components imports
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";
import EmptyContent from "../../shared/EmptyContent";
import PlanItem from "./PlanItem";

// Custom queries imports
import { usePlans } from "../../../features/plans/usePlans";

interface Props {
  planSelected: number | undefined;
  setPlanSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
}

function ListPlansAssign({ planSelected, setPlanSelected }: Props) {
  const plans = usePlans();

  if (plans.isLoading) {
    return <Loading text="Cargando planes..." />;
  }

  if (plans.isError || !plans.data) {
    return <ErrorLoading text="¡Hubo un error al cargar los planes!" />;
  }

  if (plans.data.length === 0) {
    return <EmptyContent text="No hay planes" />;
  }

  return (
    <div className="w-full divide-y border-b">
      {plans.data.map((planItem) => (
        <PlanItem
          key={planItem.id}
          plan={planItem}
          isSelected={planItem.id === planSelected}
          onSelect={(idPlan) => setPlanSelected(idPlan)}
        />
      ))}
    </div>
  );
}
export default ListPlansAssign;
