// Custom components imports
import PaymentInformation from "./PaymentInformation";
import Loading from "../../shared/Loading";
import ErrorLoading from "../../shared/ErrorLoading";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";

// Custom queries imports
import { useTicket } from "../../../features/tickets/useTicket";
import { usePersonByIdCard } from "../../../features/persons/usePersonByIdCard";
import { usePaymentsById } from "../../../features/payments/usePayment";

// Hooks Redux imports
import { useAppSelector } from "../../../app/hook";

function PaymentDetails() {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const ticket = useTicket(
    Number(params.idTransaccion),
    searchParams.get("accessCode") || ""
  );
  const payment = usePaymentsById(
    Number(params.idTransaccion),
    searchParams.get("accessCode") || ""
  );
  
  const personByID: any = usePersonByIdCard(params.idCliente);

  if (ticket.isLoading) {
    return <Loading text="Cargando transacción..." />;
  }

  if (payment.isLoading) {
    return <Loading text="Cargando pago..." />;
  }

  if (ticket.isError || !ticket.data) {
    return <ErrorLoading text="Hubo un error al cargar la transacción" />;
  }

  if (payment.isError || !payment.data) {
    return <ErrorLoading text="Hubo un error al cargar el pago" />;
  }

  if (personByID.isError) {
    navigate("/pagar");
    return null;
  }

  return (
    <div className="w-full border-t">
      <div className="w-full">
        <PaymentInformation
          payment={payment.data}
          ticket={ticket.data}
          backURL={
            currentUser.isAuthenticated
              ? `/pagar/${params.idCliente}/transacciones`
              : `/${params.idCliente}/transacciones`
          }
        />
      </div>
    </div>
  );
}

export default PaymentDetails;
