// React imports
import { useEffect, useState } from "react";

//Custom components imports
import CustomLabel from "../../shared/fields/CustomLabel";
import CustomEmailField from "../../shared/fields/CustomEmailField";
import CustomPasswordField from "../../shared/fields/CustomPasswordField";
import PasswordValidator from "../../PasswordValidator";

// Custom types imports
import { SignUpFormData } from "../../../features/sign_up/type";
import { CARD_TYPES_INFO } from "../../../features/card_types/types";

// Custom helpers imports
import {
  validateMinLength,
  validateContainsUppercase,
  validateContainsNumber,
  validateContainsLowercase,
  validatePasswordConfirmation,
} from "../../../features/auth/helpers";
import { formDefaultValues } from "../../../features/sign_up/type";

// Custom (react-hook-form) imports
import { useFormContext } from "react-hook-form";

// Custom queries imports
import { usePersonByIdCardMutation } from "../../../features/persons/usePersonByIdCardMutation";

interface Props {
  existingPerson: boolean;
  setExistingPerson: React.Dispatch<React.SetStateAction<boolean>>;
  accessCode: string;
  setAccessCode: React.Dispatch<React.SetStateAction<string>>;
}

function SignUpForm({
  existingPerson,
  setExistingPerson,
  accessCode,
  setAccessCode,
}: Props) {
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const methods = useFormContext<SignUpFormData>();
  const password = methods.watch("user.password");
  const person = methods.watch("person");
  const cardTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const personByIdCardMutation = usePersonByIdCardMutation(
    (data: any, _variables) => {
      methods.setValue("person", data);
      setExistingPerson(true);
    },
    (_error, _variables) => {
      if (existingPerson === true) {
        methods.setValue("person", {
          ...formDefaultValues.person,
          idCard: methods.watch("person.idCard"),
          idCardType: methods.watch("person.idCardType"),
          idCardValue: methods.watch("person.idCardValue"),
        });
        setExistingPerson(false);
      }
    }
  );

  useEffect(() => {
    if (methods.formState.isDirty) {
      const idCard = `00${person.idCardType}${person.idCardValue}`;
      methods.setValue("person.idCard", idCard);

      personByIdCardMutation.mutate({
        idCard,
        notificationID: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.idCardType, person.idCardValue]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      const idCard = `00${person.idCardType}${person.idCardValue}`;
      methods.setValue("person.idCard", idCard);
      methods.setValue("user.idCard", idCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.idCardType, person.idCardValue]);

  useEffect(() => {
    methods.setValue("person.name", `${person.firstName} ${person.lastName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.firstName, person.lastName]);

  useEffect(() => {
    methods.setValue(
      "person.phoneNumber",
      `${person.phoneCountry}${person.phoneNumberValue}`
    );
    methods.setValue("person.phoneCountry", 170);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.phoneNumberValue, person.phoneCountry]);

  return (
    <>
      <div className="w-full px-4 pt-3 grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-2">
        <CustomLabel
          label="Tipo documento"
          id="idCardType"
          required={true}
          className="text-sm sm:col-span-2"
          name="person.idCardType"
        >
          <div className="w-full flex items-center relative">
            <select
              disabled={existingPerson}
              className={`input-base pr-[40px] overflow-hidden appearance-none ${
                methods.formState.errors.person?.idCardType ? "input-error" : ""
              } ${
                !methods.formState.errors.person?.idCardType &&
                methods.formState.isSubmitted
                  ? "input-valid"
                  : ""
              } `}
              {...methods.register("person.idCardType", {
                required: "Este campo es requerido",
                valueAsNumber: true,
              })}
            >
              {cardTypes.map((cardType) => (
                <option value={cardType} key={cardType}>
                  {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                  {CARD_TYPES_INFO[cardType]?.description}
                </option>
              ))}
            </select>
            <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
          </div>
        </CustomLabel>

        <CustomLabel
          label="No. De documento"
          id="idCardValue"
          required={true}
          className="text-sm sm:col-span-2"
          name="person.idCardValue"
        >
          <input
            type="number"
            placeholder="No. De documento"
            className={`input-base ${
              methods.formState.errors.person?.idCardValue ? "input-error" : ""
            } ${
              !methods.formState.errors.person?.idCardValue &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("person.idCardValue", {
              required: "Este campo es requerido",
              maxLength: {
                value: 12,
                message: "El número máximo de caracteres es 12",
              },
              minLength: {
                value: 6,
                message: "El número minimo de caracteres es 6",
              },
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Nombres"
          id="nombres"
          required={true}
          className="text-sm sm:col-span-2"
          name="person.firstName"
        >
          <input
            type="text"
            disabled={existingPerson}
            maxLength={20}
            placeholder="Nombres"
            className={`input-base ${
              methods.formState.errors.person?.firstName ? "input-error" : ""
            } ${
              !methods.formState.errors.person?.firstName &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("person.firstName", {
              required: "Este campo es requerido",
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Apellidos"
          id="apellidos"
          required={true}
          className="text-sm sm:col-span-2"
          name="person.lastName"
        >
          <input
            type="text"
            disabled={existingPerson}
            placeholder="Apellidos"
            maxLength={20}
            className={`input-base ${
              methods.formState.errors.person?.lastName ? "input-error" : ""
            } ${
              !methods.formState.errors.person?.lastName &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("person.lastName", {
              required: "Este campo es requerido",
            })}
          />
        </CustomLabel>

        <CustomLabel
          label="Telefono"
          id="phoneNumberValue"
          required={false}
          className="text-sm sm:col-span-2"
          name="person.phoneNumberValue"
        >
          <input
            type="number"
            disabled={existingPerson}
            placeholder="Telefono"
            className={`input-base ${
              methods.formState.errors.person?.phoneNumberValue
                ? "input-error"
                : ""
            } ${
              !methods.formState.errors.person?.phoneNumberValue &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("person.phoneNumberValue", {
              required: "Este campo es requerido",
              maxLength: {
                value: 10,
                message: "El número máximo de caracteres es 10",
              },
              minLength: {
                value: 10,
                message: "El número minimo de caracteres es 10",
              },
            })}
          />
        </CustomLabel>

        <CustomEmailField
          placeholder="nombre@correo.com"
          notRegisted={existingPerson}
          id="person.email"
          required={true}
          label="Correo electrónico"
          name="person.email"
          classNameLabel="text-sm sm:col-span-2"
        />

        <CustomLabel
          label="Nombre de usuario"
          id="username"
          required={true}
          className="text-sm sm:col-span-2"
          name="user.userName"
        >
          <input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoSave="off"
            placeholder="Nombre de usuario"
            className={`input-base ${
              methods.formState.errors.user?.userName ? "input-error" : ""
            } ${
              !methods.formState.errors.user?.userName &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("user.userName", {
              required: "Este campo es requerido",
              maxLength: {
                value: 20,
                message: "El número máximo de caracteres es 20",
              },
              minLength: {
                value: 8,
                message: "El número minimo de caracteres es 8",
              },
              validate: {
                hasNumber: (value: string) => validateContainsNumber(value),
                hasUppercase: (value: string) =>
                  validateContainsUppercase(value),
                hasLowercase: (value: string) =>
                  validateContainsLowercase(value),
              },
            })}
          />
        </CustomLabel>

        <CustomPasswordField
          placeholder="Contraseña"
          id="password"
          required={true}
          label="Contraseña"
          name="user.password"
          classNameLabel="text-sm sm:col-span-2"
          validate={{
            minLength: (value: string) => validateMinLength(value, 8),
            hasNumber: (value: string) => validateContainsNumber(value),
            hasUppercase: (value: string) => validateContainsUppercase(value),
            hasLowercase: (value: string) => validateContainsLowercase(value),
          }}
        />

        <CustomPasswordField
          placeholder="Confirmar contraseña"
          id="confirmPassword"
          required={true}
          label="Confirmar contraseña"
          name="user.confirmPassword"
          classNameLabel="text-sm sm:col-span-2"
          validate={{
            isConfirmed: (value: string) =>
              validatePasswordConfirmation(value, password),
          }}
        />

        {existingPerson && (
          <CustomLabel
            label="Codigo de acceso"
            id="accessCode"
            required={true}
            className="text-sm sm:col-span-2"
          >
            <input
              type="text"
              placeholder="Codigo de acceso"
              className="input-base"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
          </CustomLabel>
        )}

        <div className="sm:col-span-4">
          <PasswordValidator name="user.password" inDrawer={true} />
        </div>
      </div>
      <div
        onClick={() => {
          setAcceptTermsAndConditions(!acceptTermsAndConditions);
        }}
        className="w-full px-4 py-3 flex justify-between items-center border-y cursor-pointer hover:bg-slate-50"
      >
        <p className="text-[#444] mr-2">
          Al seleccionar, estas aceptando la{" "}
          <a
            className="z-20 underline text-black hover:text-red-primary"
            href="https://pagosmultired.com/politicas/"
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidad
          </a>{" "}
          y{" "}
          <a
            className="z-20 underline text-black hover:text-red-primary"
            href="https://pagosmultired.com/politicas/"
            target="_blank"
            rel="noreferrer"
          >
            Términos del Servicio
          </a>
        </p>
        {acceptTermsAndConditions ? (
          <i className="bi bi-check-circle-fill text-lg text-red-primary"></i>
        ) : (
          <i className="bi bi-circle text-lg"></i>
        )}
      </div>
      <div className="w-full flex flex-col items-center my-5">
        <button
          className={`${
            !acceptTermsAndConditions || (existingPerson && !accessCode) ? "opacity-50 pointer-events-none" : ""
          } w-[270px] button-primary`}
          type="submit"
        >
          Regístrate
        </button>
      </div>
    </>
  );
}
export default SignUpForm;
