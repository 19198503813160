// Configs imports
import api from "../../app/api";

// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Custom types imports
import { CustomerByModel, CustomerList } from "./types";
import { ROLES } from "../users/types";

// Hooks & actions imports
import { useAppSelector } from "../../app/hook";

export function useCustomers(model: CustomerByModel) {
  const currentUser = useAppSelector((state) => state.auth);

  async function getCustomers(model: CustomerByModel): Promise<CustomerList> {
    const response = await api.post<CustomerList>(
      "admin/customers/paginated",
      model
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["Customers", ...Object.values(model)],
    queryFn: () => getCustomers(model),
    enabled: currentUser.user?.roles !== ROLES.Customer,
  });
}
