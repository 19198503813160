// Custom components imports
import ContentWithToggle from "../../../shared/ContentWithToggle";

// Hooks (react-router-dom) imports
import { useNavigate } from "react-router-dom";

// Custom types imports
import { Ticket } from "../../../../features/payments/types";

interface Props {
  ticket: Ticket;
}

function InvalidPaymentMethod({ ticket }: Props) {
  const navigate = useNavigate();
  const backURL = `/pagar/${ticket.idCard}/c/${ticket.invoiceID}/metodos?accessCode=${ticket.accessCode}`;
  return (
    <div className="w-full border-t">
      <ContentWithToggle
        title="Método de pago no valido"
        onBackButton={() => navigate(backURL)}
      >
        <div className="w-full flex flex-col justify-center items-center h-[200px]">
          <h3 className="text-lg font-normal mb-4">Método de pago no valido</h3>
          <button
            onClick={() => navigate(backURL)}
            className="w-[270px] button-primary"
            type="submit"
          >
            Seleccionar otro método de pago
          </button>
        </div>
      </ContentWithToggle>
    </div>
  );
}
export default InvalidPaymentMethod;
