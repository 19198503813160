// (@tanstack/react-query) imports
import { useQuery } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

export function useConfirmEmail(email?: string, token?: string) {
  async function confirmEmail(email?: string, token?: string): Promise<any> {
    const response = await api.get<any>(`/account/confirmemail/${email}`, {
      token,
    });

    if (!response.ok) {
      throw response;
    }

    return response.data;
  }

  return useQuery({
    queryKey: ["ConfirmEmail", email, token],
    queryFn: () => confirmEmail(email, token),
    enabled: !!email && !!token,
  });
}
