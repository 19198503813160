// React imports
import { useState, useEffect } from "react";

// Custom components imports
import CustomLabel from "../../../shared/fields/CustomLabel";
import CustomEmailField from "../../../shared/fields/CustomEmailField";

// Custom types imports
import {
  CARD_TYPES_INFO,
  CardTypes as CardTypesEnum,
} from "../../../../features/card_types/types";
import { TAX_REGIME_INFO } from "../../../../features/stores/types";
import { taxRegimeMock } from "../../../../features/stores/mocks";
import { Store } from "../../../../features/stores/types";

// Custom queries imports
import { useCardTypes } from "../../../../features/card_types/useCardTypes";
import { useProvicesByCountry } from "../../../../features/provinces/useProvicesByCountry";
import { useCitiesByProvince } from "../../../../features/cities/useCitiesByProvince";
import { useCityMutation } from "../../../../features/cities/useCityMutation";

// (react-hook-form) imports
import { useFormContext } from "react-hook-form";

interface Props {
  store: Store;
}

function DataStoreForm({ store }: Props) {
  const methods = useFormContext<Store>();
  const [initializingCities, setInitializingCities] = useState<boolean>(false);
  const [provinceSelected, setProvinceSelected] = useState<number | undefined>(
    undefined
  );

  const cityMutation = useCityMutation((data, _variables) => {
    setProvinceSelected(data.parentID);
  });
  const provinces = useProvicesByCountry(170);
  const cities = useCitiesByProvince(provinceSelected, () => {
    if (!initializingCities) setInitializingCities(true);
  });
  const cardTypes = useCardTypes();

  useEffect(() => {
    cityMutation.mutate({ id: store.cityID, notificationID: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomLabel
        label="ID"
        id="id"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <input
          type="string"
          disabled
          placeholder="ID"
          className="input-base"
          value={methods.watch("id")}
        />
      </CustomLabel>

      <CustomLabel
        label="Estado"
        id="status"
        required={true}
        className="text-sm sm:col-span-2"
        name="status"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`border-none text-white ${
              methods.watch("status") ? "bg-green-500" : "bg-red-500"
            } input-base appearance-none ${
              methods.formState.errors.status ? "input-error" : ""
            } ${
              !methods.formState.errors.status && methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("status", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            {[
              { description: "Activo", value: 1 },
              { description: "Inactivo", value: 0 },
            ].map((status) => (
              <option value={status.value} key={status.value}>
                {status.description}
              </option>
            ))}
          </select>
          <i className="text-white text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      <CustomLabel
        label="Tipo documento"
        id="tipoDocumento"
        required={false}
        className="text-sm sm:col-span-2"
      >
        <div className="w-full flex items-center relative">
          <select
            value={CardTypesEnum.numeroIdentificacionTributaria}
            disabled
            className="input-base appearance-none"
          >
            {cardTypes.data?.map((cardType) => (
              <option value={cardType} key={cardType}>
                {CARD_TYPES_INFO[cardType]?.prefix}{" "}
                {CARD_TYPES_INFO[cardType]?.description}
              </option>
            ))}
          </select>
        </div>
      </CustomLabel>

      <CustomLabel
        label="No. de documento"
        id="taxIDNumber"
        required={true}
        className="text-sm sm:col-span-2"
        name="taxIDNumber"
      >
        <input
          type="number"
          min={0}
          placeholder="No. De documento"
          className={`input-base ${
            methods.formState.errors?.taxIDNumber ? "input-error" : ""
          } ${
            !methods.formState.errors?.taxIDNumber &&
            methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("taxIDNumber", {
            required: "Este campo es requerido",
            maxLength: {
              value: 12,
              message: "El número máximo de caracteres es 12",
            },
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Razon social"
        id="storeName"
        required={true}
        className="text-sm sm:col-span-4"
        name="storeName"
      >
        <input
          type="text"
          placeholder="Razon social"
          maxLength={60}
          className={`input-base ${
            methods.formState.errors.storeName ? "input-error" : ""
          } ${
            !methods.formState.errors.storeName && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("storeName", {
            required: "Este campo es requerido",
            maxLength: {
              value: 60,
              message: "El número máximo de caracteres es 60",
            },
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Régimen tributario"
        id="taxCondition"
        required={true}
        className="text-sm sm:col-span-2"
        name="taxCondition"
      >
        <div className="w-full flex items-center relative">
          <select
            className={`input-base appearance-none ${
              methods.formState.errors.taxCondition ? "input-error" : ""
            } ${
              !methods.formState.errors.taxCondition &&
              methods.formState.isSubmitted
                ? "input-valid"
                : ""
            } `}
            {...methods.register("taxCondition", {
              required: "Este campo es requerido",
              valueAsNumber: true,
            })}
          >
            <option value="">Seleccionar</option>
            {taxRegimeMock?.map((taxRegime) => (
              <option value={taxRegime} key={taxRegime}>
                {TAX_REGIME_INFO[taxRegime]?.prefix}{" "}
                {TAX_REGIME_INFO[taxRegime]?.description}
              </option>
            ))}
          </select>
          <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
        </div>
      </CustomLabel>

      {initializingCities && (
        <>
          <CustomLabel
            label="Departamento"
            id="province"
            required={false}
            className="text-sm sm:col-span-2"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  !provinceSelected || provinces.isLoading || !provinces.data
                }
                className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
                value={provinceSelected}
                onChange={(event) =>
                  setProvinceSelected(Number(event.currentTarget.value))
                }
              >
                <option value="0">Todas</option>
                {provinces.data?.map((provice) => (
                  <option value={provice.id} key={provice.id}>
                    {provice?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>

          <CustomLabel
            label="Ciudad"
            id="cityID"
            required={true}
            className="text-sm sm:col-span-2"
            name="cityID"
          >
            <div className="w-full flex items-center relative">
              <select
                disabled={
                  provinces.isLoading || cities.isLoading || !cities.data
                }
                className="input-base pr-[40px] overflow-hidden appearance-none"
                {...methods.register("cityID", {
                  required: "Este campo es requerido",
                  valueAsNumber: true,
                })}
              >
                <option value="">Todas</option>
                {cities.data?.map((city) => (
                  <option value={city.id} key={city.id}>
                    {city?.value}
                  </option>
                ))}
              </select>
              <i className="text-gray-primary text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
            </div>
          </CustomLabel>
        </>
      )}

      <CustomLabel
        label="Teléfono"
        id="phone"
        required={true}
        className="text-sm sm:col-span-2"
        name="phone"
      >
        <input
          type="number"
          placeholder="Télefono"
          className={`input-base ${
            methods.formState.errors.phone ? "input-error" : ""
          } ${
            !methods.formState.errors.phone && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("phone", {
            required: "Este campo es requerido",
            maxLength: {
              value: 10,
              message: "El número máximo de caracteres es 10",
            },
            minLength: {
              value: 10,
              message: "El número minimo de caracteres es 10",
            },
          })}
        />
      </CustomLabel>

      <CustomEmailField
        placeholder="nombre@correo.com"
        id="email"
        required={true}
        label="Correo electrónico"
        name="email"
        classNameLabel="text-sm sm:col-span-4"
        onChange={(e: any) => {
          methods.setValue("email", e.target.value);
        }}
      />

      <CustomLabel
        label="Dirección"
        id="address"
        required={true}
        className="text-sm sm:col-span-4"
        name="address"
      >
        <input
          type="text"
          placeholder="Dirección"
          className={`input-base ${
            methods.formState.errors.address ? "input-error" : ""
          } ${
            !methods.formState.errors.address && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("address", {
            required: "Este campo es requerido",
          })}
        />
      </CustomLabel>

      <CustomLabel
        label="Segunda dirección"
        id="address2"
        required={false}
        className="text-sm sm:col-span-4"
        name="address2"
      >
        <input
          type="text"
          placeholder="Dirección"
          className={`input-base ${
            methods.formState.errors.address ? "input-error" : ""
          } ${
            !methods.formState.errors.address && methods.formState.isSubmitted
              ? "input-valid"
              : ""
          } `}
          {...methods.register("address2")}
        />
      </CustomLabel>
    </>
  );
}
export default DataStoreForm;
