// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { Payment, PaymentReceipt } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  payment: Payment;
  notificationID: string | number;
}

export function useCreatePaymentMutation(
  onSuccessMutation?: (data: PaymentReceipt, variables: paramsType) => void,
  onErrorMutation?: (
    error: ApiErrorResponse<any>,
    variables: paramsType
  ) => void
) {
  async function createPayment(variables: paramsType): Promise<PaymentReceipt> {
    const response = await api.post<PaymentReceipt>(
      "invoices/payments",
      variables.payment
    );

    if (!response.ok || !response.data) {
      throw response;
    }

    return response.data;
  }

  return useMutation({
    mutationFn: createPayment,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
