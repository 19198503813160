// React imports
import { useState } from "react";

// Custom components
import ContentWithToggle from "../../components/shared/ContentWithToggle";
import SignUpForm from "../../components/forms/sign_up/SignUpForm";

// Custom types imports
import { formDefaultValues, SignUpFormData } from "../../features/sign_up/type";

// Third components & helpers imports
import { toast } from "react-toastify";

// Hooks & components (react-hook-form) imports
import { FormProvider, useForm } from "react-hook-form";

// Custom Hooks imports
import { useSignUpMutation } from "../../features/sign_up/useSignUpMutation";
import { useSignUpByAccessCodeMutation } from "../../features/sign_up/useSignUpByAccessCodeMutation";


import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const [existingPerson, setExistingPerson] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const navigate = useNavigate();

  const methods = useForm<SignUpFormData>({
    defaultValues: formDefaultValues,
  });

  const signUpByAccessCodeMutation = useSignUpByAccessCodeMutation({
    data: {
      person: methods.watch("person"),
      user: methods.watch("user"),
      accessCode,
    },
    onSuccessMutation: (_user) => {
      navigate("/ingresar");
    },
  });

  const signUpMutation = useSignUpMutation({
    data: {
      person: methods.watch("person"),
      user: methods.watch("user"),
    },
    onSuccessMutation: (_user) => {
      navigate("/ingresar");
    },
  });

  const onSubmit = methods.handleSubmit((data) => {
    if (!existingPerson) {
      const notificationID = toast.loading(
        "1/3 Validando email del usuario..."
      );
      signUpMutation.mutate({
        notificationID,
        email: data.person.email,
      });
    } else {
      const notificationID = toast.loading(
        "1/3 Validando nombre del usuario..."
      );
      signUpByAccessCodeMutation.mutate({
        notificationID,
        username: data.user.userName,
      });
    }
  });

  return (
    <div className="w-full sm:max-w-[680px] mx-auto sm:mt-[40px]  bg-white rounded-md overflow-hidden">
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} autoComplete="off" autoSave="off">
          <ContentWithToggle
            title="Regístrate"
            onBackButton={() => window.history.back()}
            isToogle={false}
          >
            {existingPerson && (
              <div className="sm:col-span-4 text-sm flex sm:flex-row flex-col flex-wrap sm:flex-nowrap sm:justify-center items-center bg-blue-500 text-white py-2 px-4">
                <i className="text-lg bi bi-info-circle-fill mr-4"></i>
                <p className="flex-1">
                  <b>
                    ¡Hola, {methods.watch("person.firstName")}{" "}
                    {methods.watch("person.lastName")}!
                  </b>{" "}
                  Ya has realizado transacciones con nosotros, puedes crearte un
                  usuario para acceder a tu cuenta ingresando el código de
                  acceso que vino en tu última factura.
                </p>
                <button
                  type="button"
                  tabIndex={-1}
                  className="mt-2 bg-white text-black rounded-md px-5 py-1 sm:ml-4 sm:mt-0 font-bold"
                  onClick={() => {}}
                >
                  Más información
                </button>
              </div>
            )}
            <SignUpForm
              existingPerson={existingPerson}
              setExistingPerson={setExistingPerson}
              accessCode={accessCode}
              setAccessCode={setAccessCode}
            />
          </ContentWithToggle>
        </form>
      </FormProvider>
    </div>
  );
}
