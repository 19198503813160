// React imports
import { useRef } from "react";

// Hooks & Components (react-hook-form) imports
import { useForm } from "react-hook-form";

// Third helpers imports
import { toast } from "react-toastify";

// Custom helpers imports
import { confirmChange } from "../../../../features/auth/helpers";

// Custom queries imports
import { useAddRoleUserMutation } from "../../../../features/users/useAddRoleUserMutation";

// Hooks (@tanstack/react-query) imports
import { useQueryClient } from "@tanstack/react-query";

// Custom types imports
import { Roster, RosterModel } from "../../../../features/roster/types";
import { ROLES } from "../../../../features/users/types";

interface Props {
  roster: Roster;
  paramsRostersInfinite: RosterModel;
}

function UpdateRoleRoster({ roster, paramsRostersInfinite }: Props) {
  const queryClient = useQueryClient();
  const inputSubmitRef = useRef<HTMLInputElement | null>(null);
  const methods = useForm<{ role: ROLES }>({
    defaultValues: {
      role: roster.roles,
    },
  });

  const addRoleUser = useAddRoleUserMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Rol asignado",
        autoClose: 5000,
      });
      queryClient.invalidateQueries([
        "RosterInfinite",
        ...Object.values(paramsRostersInfinite),
      ]);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salio mal, al intentar asignando rol",
        autoClose: 5000,
      });
    }
  );

  const onSubmit = methods.handleSubmit((data) => {
    const notificationID = toast.loading("Cambiando rol del usuario...");
    addRoleUser.mutate({
      email: roster.email,
      role: data.role,
      companyID: roster.companyID,
      isActive: true,
      notificationID,
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className=" border-y w-full px-4 py-3 flex justify-between items-center flex-wrap">
        <span className="text-gray-600">Rol de usuario</span>
        <div className="gap-x-2 sm:mt-0 mt-2 w-full sm:w-[60%] flex justify-end items-center">
          <div className="w-full flex items-center relative">
            <select
              className="input-base pr-[40px] overflow-hidden appearance-none cursor-pointer"
              placeholder="Correo electrónico"
              {...methods.register("role")}
            >
              <option value={ROLES.StoreEmployee}>Empleado</option>
              <option value={ROLES.StoreSupervisor}>Supervisor</option>
            </select>
            <i className="text-gray-500 text-[20px] absolute z-10 right-[13px] bi bi-chevron-down"></i>
          </div>
          <button
            type="button"
            onClick={() =>
              confirmChange(
                "¿Estas seguro de modificar el rol del usuario?",
                "",
                () => {
                  if (!!inputSubmitRef.current) {
                    inputSubmitRef.current.click();
                  }
                }
              )
            }
            disabled={!methods.formState.isDirty}
            className="button-third bg-blue-500 hover:bg-blue-600 text-white text-sm py-2 px-4"
          >
            Cambiar
          </button>
          <input className="hidden" ref={inputSubmitRef} type="submit" />
        </div>
      </div>
    </form>
  );
}
export default UpdateRoleRoster;
