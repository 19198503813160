// Hooks (@tanstack/react-query) imports
import { useMutation } from "@tanstack/react-query";

// Configs imports
import api from "../../app/api";

// Custom types imports
import { TicketModel } from "./types";
import { ApiErrorResponse } from "apisauce";

interface paramsType {
  model: TicketModel;
  fileFormat: number;
  notificationID: string | number;
}

export function useTicketReportMutation(
  onSuccessMutation?: (data: any, variables: paramsType) => void,
  onErrorMutation?: (err: ApiErrorResponse<any>, variables: paramsType) => void
) {
  async function createTicketReport(variables: paramsType): Promise<any> {
    const response = await api.post<any>(
      "invoices/tickets/report",
      variables.model,
      {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
        },
        params: {
          fileFormat: variables.fileFormat,
        },
      }
    );

    if (!response.ok) {
      throw response;
    }
    return response.data;
  }

  return useMutation({
    mutationFn: createTicketReport,
    onSuccess: onSuccessMutation,
    onError: onErrorMutation,
  });
}
