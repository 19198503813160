// React imports
import { useState } from "react";

// Customer components imports
import Tabs from "../../../shared/Tabs";
import InfiniteListInvoices from "../../../infinite_lists/InfiniteListInvoices";
import ContentWithToggle from "../../../shared/ContentWithToggle";
import InvoiceFilterForm from "../../../../components/forms/invoice/InvoiceFilterForm";

// Custom types imports
import { Tab } from "../../../shared/Tabs";
import { fetchingModelDefaults } from "../../../../features/tickets/types";

// Hooks (react-router-dom) imports
import { useParams } from "react-router-dom";

// Hooks & Components (react-hook-form) imports
import { useForm, FormProvider } from "react-hook-form";

// Hooks & actions imports
import { useAppSelector } from "../../../../app/hook";

// Custom queries imports
import { useTicketsInfinite } from "../../../../features/tickets/useTicketsInfinite";

// Custom types imports
import { PaginatedModelTicket } from "../../../../features/tickets/types";

function RemovedList() {
  const params = useParams();
  const currentUser = useAppSelector((state) => state.auth);
  const [cardTypeSelected, setCardTypeSelected] = useState<number | undefined>(
    undefined
  );
  const tabs: Tab[] = [
    { label: "Pendientes", url: `/pagar/${params.idCliente}/cobros` },
    { label: "Eiminados", url: `/pagar/${params.idCliente}/eliminados` },
    { label: "Rechazados", url: `/pagar/${params.idCliente}/rechazados` },
  ];

  const methods = useForm<PaginatedModelTicket>({
    defaultValues: {
      ...fetchingModelDefaults,
      customerIdCard: currentUser.user?.idCard,
      invoiceStatus: 1,
    },
  });

  const tickets = useTicketsInfinite({
    ...methods.watch(),
    customerIdCard:
      cardTypeSelected && methods.watch("customerIdCard")
        ? `00${cardTypeSelected}${methods.watch("customerIdCard")}`
        : "",
  });

  const data = tickets.data?.pages?.flatMap((page) => page.invoices) || [];

  return (
    <div className="border-t w-full sm:max-w-[576px] mx-auto sm:mt-[40px] bg-white rounded-md">
      <ContentWithToggle
        title="Filtros"
        isToogle={true}
        defaultOpenValue={false}
      >
        <FormProvider {...methods}>
          <InvoiceFilterForm
            cardTypeSelected={cardTypeSelected}
            setCardTypeSelected={setCardTypeSelected}
            disabledReportMutation={data.length === 0}
          />
        </FormProvider>
      </ContentWithToggle>
      <ContentWithToggle title="Cobros Eiminados" isToogle={false}>
        <Tabs tabs={tabs} />
        <InfiniteListInvoices
          tickets={tickets}
          link={(ticket) =>
            `/pagar/${ticket.idCard}/t/${ticket.invoiceID}/resumen?accessCode=${ticket.accessCode}`
          }
        />
      </ContentWithToggle>
    </div>
  );
}

export default RemovedList;
