// Custom queries imports
import { usePersonByUsernameMutation } from "../persons/usePersonByUsernameMutation";
import { usePasswordTokenByAccessCodeMutation } from "./usePasswordTokenByAccessCodeMutation";
import { useCreateUserMutation } from "../users/useCreateUserMutation";

// Third components & helpers imports
import { toast } from "react-toastify";

// Custom types imports
import { UserRegister, NewlyRegisteredUser } from "../users/types";
import { PersonRegistration } from "../persons/types";

interface paramsType {
  data: {
    person: PersonRegistration;
    user: UserRegister;
    accessCode: string
  };
  onSuccessMutation?: (user?: NewlyRegisteredUser) => void;
}

export function useSignUpByAccessCodeMutation(params: paramsType) {

  const createUserMutation = useCreateUserMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "success",
        render: "Usuario creado.",
        autoClose: 5000,
      });
      params.onSuccessMutation?.(data);
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salió mal al intentar registrar al usuario.",
        autoClose: 5000,
      });
    }
  );

  const passwordTokenByAccessCodeMutation = usePasswordTokenByAccessCodeMutation(
    (data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "3/3 Creando usuario...",
        autoClose: 5000,
      });
      createUserMutation.mutate({
        user: {
          ...params.data.user,
          modifiedAfter: new Date().toISOString(),
          expiredBy: new Date().toISOString(),
          token: data.code,
        },
        notificationID: variables.notificationID,
      });
    },
    (error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          error.data?.description ||
          "Algo salió mal al intentar validar el código de acceso.",
        autoClose: 5000,
      });
    }
  );

  const validUsernameMutation = usePersonByUsernameMutation(
    (_data, variables) => {
      toast.update(variables.notificationID, {
        isLoading: false,
        type: "error",
        render:
          "El nombre de usuario ya está en uso, intenta de nuevo con otro nombre de usuario.",
        autoClose: 5000,
      });
    },
    (_error, variables) => {
      toast.update(variables.notificationID, {
        isLoading: true,
        render: "2/3 Validando código de acceso...",
        autoClose: 5000,
      });
      passwordTokenByAccessCodeMutation.mutate({
        user: params.data.person.email,
        accessCode: params.data.accessCode,
        notificationID: variables.notificationID,
      });
    }
  );

  return validUsernameMutation;
}
